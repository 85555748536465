export default class GeneralDialog
{
  constructor()
  {

  }

  Display(content, okContent, okCallback, cancelContent = null, cancelCallback = null)
  {
      this.ShowOverlay();

      let dialog = this;

      var popup = document.createElement("div");
      popup.style.position = "absolute";
      popup.classList.add("ThemeElement");
      popup.id = "popup";
      popup.setAttribute("Darkmode", "DM_PopupBackground");

      document.getElementById("popupoverlay").append(popup);

      var popupMessageContainer = document.createElement("div");
      popupMessageContainer.id = "PopupMessage";
      var popupButtonContainer = document.createElement("div");
      popupButtonContainer.id = "PopupButtonContainer";

      popup.append(popupMessageContainer);
      popup.append(popupButtonContainer);

      var messageText = document.createElement("div");
      messageText.innerHTML = content;
      popupMessageContainer.append(messageText);

      if (cancelContent != null)
      {
          var cancelBtn = document.createElement("div");
          cancelBtn.classList.add("PopupButton", "Button", "ButtonGreen");
          cancelBtn.innerHTML = cancelContent;
          
          cancelBtn.onclick = function()
          {
              if (cancelCallback!=null)
              {
                  cancelCallback();
              }
              dialog.HideOverlay();
          };

          popupButtonContainer.append(cancelBtn);
      }

      var okBtn = document.createElement("div");
      okBtn.classList.add("PopupButton", "Button", "ButtonGreen");
      okBtn.innerHTML = okContent;
      okBtn.onclick = function()
      {
          if (okCallback != null)
          {
              okCallback();
          }
      };

      popupButtonContainer.append(okBtn);
  }

  ShowOverlay()
  {
    console.log("(ShowOverlay)");

    let scrollY = 0;
    
    if (document.documentElement.scrollTop != 0 ||
        document.body.scrollTop != 0)
    {
        scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    }
    
    document.body.style.overflowY = "hidden";

    // <div id="overlay" style="display: none;" @click="ToggleMenu"></div>
    var overlay = document.getElementById("popupoverlay");
    if (overlay == undefined)
    {
        console.log("Create new overlay");
        overlay = document.createElement("div");
        overlay.id = "popupoverlay";
        overlay.style.position = "absolute";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.left = "0px";
        overlay.style.backgroundColor = "rgba(0.3,0.3,0.3,0.5)";
        document.getElementById("app").append(overlay);
        console.log("Overlay created", overlay);
        let dialog = this;
        overlay.onclick = function(event)
        {
            console.log("ev",event);
            dialog.HideOverlay();
        };
    }
    else
    {
        console.log("here?");
    }

    overlay.style.top = scrollY + "px";
    overlay.style.display = "block";
    console.log("Show overlay", overlay);
  }
  
  HideOverlay()
  {
      var overlay = document.getElementById("popupoverlay");
      var popup = document.getElementById("popup");
  
      if (overlay != null)
      {
          overlay.style.display = "none";
      }
      
      if (popup != null)
      {
          popup.remove();
      }
  }
}

export const General_Dialog = new GeneralDialog();