<template>
    <div id="MainContent">
        <div class="BrightColorText localize" data-key="text.maintitle" style="font-size: 2rem;">仕様書登録</div>
        <button v-if="IsInitialized === true" class="Button ButtonGreen NextPrevBtn localize" data-key="text.back" @click="GoBackStep">戻る</button>
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <p style="margin-top: 0px; font-size: 80%;" class="localize" data-key="text.step4.p1">仕様書準備は出来ました。<br/>後「作成者」選んで、エクスポートボタンを押して下さい。</p>
            <div class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.author.title">作成者:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown disabled="true" class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'AuthorSelection'" :options="AuthorOptions" v-model="selAuthor" @change="onChangeAuthor"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.incharge.title">担当者:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'InChargeSelection'" :options="InChargeOptions" v-model="selInCharge" @change="onChangeInCharge"></ManufacturerDropdown>
            </div>
            <div style="display: flex; margin-top: 50px;">
                <div style="height: 64px;">
                    <button id="ExportSpecButton" class="Button ButtonGreen NextPrevBtn localize" style="font-size: 75%;" data-key="text.export" @click="GenerateExcel">エクスポート</button>
                </div>
                <div style="margin-left: 50px; height: 64px;">
                    <button id="SaveSpecButton" class="Button ButtonGreen NextPrevBtn localize" style="font-size: 75%;" data-key="text.saveindb" @click="UploadSpecificationSheet">仕様書を保存する</button>
                    <div id="DBstoredhint" class="localize" data-key="text.db.storedhint" style="margin-top:-15px; padding-left: 10px; font-size:0.8rem;">Uploaded to database.</div>
                </div>
            </div>
        </div>
        <div class="ThemeElement" Darkmode="DM_HelpContainer" style="margin-top: 30px;">
            <div id="PrintProblemHint">
                <p class="localize" data-key="text.printproblem.hint" style="font-style: italic;">
                    If you encounter problems with the size of the printed version, try following these steps:
                </p>
                <p class="localize" data-key="text.printproblem.description">
                    
                </p>
            </div>
            <div>
                <div class="localize helpstep" data-key="text.step1">Step-1</div>
                <div><img src="/assets/PrintProblem/Hint1.png" /></div>
            </div>
            <div>
                <div class="localize helpstep" data-key="text.step2">Step-2</div>
                <div><img src="/assets/PrintProblem/Hint2.png" /></div>
            </div>
            <div>
                <div class="localize helpstep" data-key="text.step3">Step-3</div>
                <div><img src="/assets/PrintProblem/Hint3.png" /></div>
            </div>
            <div>
                <div class="localize helpstep" data-key="text.step4">Step-4</div>
                <div><img src="/assets/PrintProblem/Hint4.png" /></div>
            </div>
        </div>
    </div>
</template>
<script>
import ManufacturerDropdown from './components/ManufacturerDropdown.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { ApplyDarkmode } from './Darkmode';
import axios from 'axios';
import { LocalizationManager } from './Localization.js';
import { GenerateAndExportExcel } from './ExportAsExcel.js';
import { ShowLoading } from './FullScreenLoading.js';

export default
{
    components:{
        ManufacturerDropdown,
    },
    computed:{
        ...mapGetters(['getStep1Data','getStep2Data','getStep3Data','getStep4Data']),
        Step1Data()
        {
            return this.getStep1Data;
        },
        Step2Data()
        {
            return this.getStep2Data;
        },
        Step3Data()
        {
            return this.getStep3Data;
        },
        Step4Data()
        {
            return this.getStep4Data;
        }
    },
    mounted(){
        document.title = "EZ-SPEC-Step 4";
        if (this.Step1Data == null)
        {
            this.$router.push('/');
            return;
        }

        if (this.Step2Data==null)
        {
            this.$router.push('/step2');
            return;
        }

        if (this.Step3Data==null)
        {
            this.$router.push('/step3');
            return;
        }

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }

        var dbSaveBtn = document.getElementById('SaveSpecButton');
        if (dbSaveBtn != null)
        {
            dbSaveBtn.disabled = true;
            dbSaveBtn.classList.add("DisabledButton");
        }

        this.LoadStepData();

        // ToDo: Block export and save button until
        //       user names have been received
        this.RetrieveUsers((success)=>
        {
            if (success==true)
            {
                this.IsInitialized = true;

                this.RetrievePersonInCharge((success)=>
                {
                    setTimeout(()=>
                    {
                        if (success == true)
                        {
                            dbSaveBtn.disabled = false;
                            dbSaveBtn.classList.remove("DisabledButton");

                            const event = new Event('change', { bubbles: true });
                            var el = document.getElementById("InChargeSelection");        

                            for(let c = 0; c < this.InChargeOptions.length; c++)
                            {
                                if (this.InChargeOptions[c].value == this.selInChargeID)
                                {
                                    el.selectedIndex = c;
                                    break;
                                }
                            }

                            el.dispatchEvent(event);
                        }
                    }, 50);
                    
                });

                setTimeout(()=>
                {
                    const event = new Event('change', { bubbles: true });
                    if (this.Step4Data == null ||
                        this.Step4Data.AuthorID == undefined)
                    {
                        this.selAuthorID = parseInt(localStorage.getItem('user_id'));
                    }
                    else
                    {
                        this.selAuthorID = this.Step4Data.AuthorID;    
                    }

                    var el = document.getElementById("AuthorSelection");
                    
                    // It should not happen that we don't find the
                    // author of this spec sheet since a registered
                    // account is necessary
                    let found = false;
                    for(let p = 0; p < this.AuthorOptions.length; p++)
                    {
                        if (this.AuthorOptions[p].value == this.selAuthorID)
                        {
                            el.selectedIndex = p;
                            found = true;
                            break;
                        }
                    }

                    if (found == false)
                    {
                        el.disabled = false;
                    }

                    el.dispatchEvent(event);

                    this.$nextTick(()=>
                    {
                        ApplyDarkmode();
                        LocalizationManager.TranslatePage();
                    });
                }, 50);

                this.$nextTick(()=>
                {
                    ApplyDarkmode();
                    LocalizationManager.TranslatePage();
                });
            }
        });
    },
    methods:{
        LoadStepData()
        {
            if (this.Step4Data != null)
            {
                this.selAuthor = this.Step4Data.Author;
                this.selAuthorID = this.Step4Data.AuthorID;
                this.selInCharge = this.Step4Data.InCharge;
                this.selInChargeID = this.Step4Data.InChargeID;
            }
        },
        GetForExportStep1Data()
        {
            return this.Step1Data;
        },
        onChangeAuthor(event)
        {
            if (event == null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            console.log("Chose ", this.AuthorOptions[event.target.selectedIndex]);
            this.selAuthorID = this.AuthorOptions[event.target.selectedIndex].value;
            this.selAuthor = this.AuthorOptions[event.target.selectedIndex].label;

            this.$nextTick(()=>
            {
                this.SaveStepData();    
            });
        },
        onChangeInCharge(event)
        {
            if (event == null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            this.selInChargeID = this.InChargeOptions[event.target.selectedIndex].value;
            this.selInCharge = this.InChargeOptions[event.target.selectedIndex].label;
        },
        SaveStepData()
        {
            var d = {
                Author: this.selAuthor,
                AuthorID: this.selAuthorID,
                InCharge: this.selInCharge,
                InChargeID: this.selInChargeID,
            };
            
            this.saveStep4Data(d);
        },
        ...mapActions(['saveStep4Data','saveToken']),
        GoBackStep()
        {
            this.SaveStepData();
            this.$router.push('/step3');
        },
        RetrieveUsers(callback)
        {
            axios.get(process.env.VUE_APP_PROJ_SPEC_API_URL + "users")
            .then(response => {
                console.log("Received response.", response);
                this.AuthorOptions = [];
                for(var i = 0; i < response.data.length; i++)
                {
                    this.AuthorOptions.push({label: response.data[i].Name, value: response.data[i].ID});
                }

                callback(true);
            })
            .catch(error =>{
                console.log("Received error.", error);
                if (Object.hasOwn(error.response) == true)
                {
                    if (error.response.status >= 400 &&
                        error.response.status <= 499)
                    {
                        this.$HandleBadRequest(error.response.status, error.response.data);
                    }
                }
                callback(false);
            });
        },
        RetrievePersonInCharge(callback)
        {
            axios.get(process.env.VUE_APP_PROJ_SPEC_API_URL + "incharge")
            .then(response =>{
                console.log("Received list of person in charge", response);
                this.InChargeOptions = [];
                for(var i = 0; i < response.data.length; i++)
                {
                    this.InChargeOptions.push({label: response.data[i].Name, value: response.data[i].ID});
                }

                callback(true);
            })
            .catch(error =>{
                console.log("Received error.", error);
                if (Object.hasOwn(error.response) == true)
                {
                    if (error.response.status >= 400 &&
                        error.response.status <= 499)
                    {
                        this.$HandleBadRequest(error.response.status, error.response.data);
                    }
                }
                callback(false);
            });
        },
        GenerateExcel()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));
            var btn = document.getElementById("ExportSpecButton");
            btn.classList.add("DisabledButton");

            var data = {
                SessionToken: localStorage.getItem('session_token')
            };

            this.SaveStepData();

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'termtranslations/excel', data)
            .then(response =>
            {
                console.log(response);
                GenerateAndExportExcel(this.Step1Data, this.Step2Data, this.Step3Data, this.Step4Data, response.data);
                btn.classList.remove("DisabledButton");
                ShowLoading(false);
            })
            .catch(error=>
            {
                console.log("Received error", error);
                btn.classList.remove("DisabledButton");
                ShowLoading(false);
                if (error.response.status != 500)
                {
                    this.$HandleBadRequest(error.response.status, error.response.data);
                }
            });
        },
        UploadSpecificationSheet()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

            this.SaveStepData();

            var data = 
            {
                SessionToken: localStorage.getItem('session_token'),
                Username: this.Step4Data.Author,
                SpecificationSheet:
                {
                    Step1: this.Step1Data,
                    Step2: this.Step2Data,
                    Step3: this.Step3Data,
                    Step4: this.Step4Data,
                }
            };

            console.log("Save spec sheet", data);
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "save", data,
            {
                headers:
                {
                    'Content-Type':  'application/json'
                }
            })
            .then(response =>
            {
                console.log("Received response.", response);
                if (response.status == 200)
                {
                    var dbSaveBtn = document.getElementById('SaveSpecButton');
                    if (dbSaveBtn != null)
                    {
                        document.getElementById('DBstoredhint').style.opacity = "1";
                        dbSaveBtn.disabled = true;
                        dbSaveBtn.classList.add("DisabledButton");
                    }
                }
                console.log("Data sent successfully", response.data);
                ShowLoading(false);
            })
            .catch(error =>{
                console.log("Received error.", error);
                ShowLoading(false);
            });
        },
    },
    data(){
        return{
            IsInitialized: false,
            selAuthor: '',
            selAuthorID: 0,
            selInCharge: '',
            selInChargeID: 0,
            AuthorOptions: [],
            InChargeOptions:[],
        }
    }
}
</script>
<style>
.helpstep
{
    margin-top: 20px;
    margin-bottom: 8px;
    font-weight: bold;
}

#DBstoredhint
{
    opacity: 0;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
}
</style>