export let IsDarkmode = false;

export function InitDarkmode()
{
    IsDarkmode = localStorage.getItem('IsDarkmode') === "true";
    ApplyDarkmode();
}

export function DarkmodeSwitch()
{
    IsDarkmode = !IsDarkmode;
    localStorage.setItem('IsDarkmode', IsDarkmode);

    ApplyDarkmode();
}

export function ApplyDarkmode()
{
    var themeElements = document.getElementsByClassName("ThemeElement");
    for(let i = 0; i < themeElements.length; i++)
    {
        var darkClass = themeElements[i].getAttribute("Darkmode");
        if (IsDarkmode === true)
        {
            themeElements[i].classList.add(darkClass);
        }
        else
        {
            themeElements[i].classList.remove(darkClass);
        }
    }
}