<template>
    <div class="MultiDropDown">
        <select multiple :id="elementID" v-model="selectOption" :disabled="disabled">
            <option class="localize" v-for="option in options" :data-key="option.locakey" :key="option.key" :value="option.value" :disabled="option.disabled"> {{ option.label }} </option>
        </select>
    </div>
</template>

<script>
export default{
    props:
    {
        options: Array,
        value: String,
        elementID: String,
        disabled: Boolean
    },
    computed:
    {
        selectedOption:
        {
            get()
            {
                return this.value
            },
            set(newValue)
            {
                this.$emit('input', newValue);
            }
        }
    },
    methods:{
        emitChange(event){
            this.$emit('change', event);
        }
    },
    data()
    {
        return{
            selectOption: '',
        }
    }
};
</script>

<style>

</style>