<template>
    <div id="Wrapper" style="overflow: visible;" class="ThemeElement" Darkmode="DM_Text">
        <div class="localize" data-key="text.factoriestable.explanation"></div>
        <div id="SaveTranslationButtonContainer" style="margin-bottom: 10px; visibility: hidden;">
            <button id="SaveTranslationButton" class="Button ButtonGreen localize" data-key="text.save" style="float: right;" @click="UpdateRelationships">Save</button>
        </div>
        <div id="DataList"></div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { ApplyDarkmode, InitDarkmode } from '../Darkmode.js';
import { LocalizationManager } from '../Localization.js';
import { ShowLoading } from '../FullScreenLoading.js';
import { General_Dialog } from '@/components/GeneralDialog.js';

export default
{
    mounted(){
        let table = document.getElementById('DataList');
        table.replaceChildren();

        document.title = "EZ-SPEC-Factories relationship table";

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }

        this.LanguageIndex = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        LocalizationManager.RegisterOnLanguageChangedListener(this.UpdateDataTranslation);

        var saveBtnContainer = document.getElementById('SaveTranslationButtonContainer');
        this.SaveBtnContainer = saveBtnContainer;
        let stickyObserver = new IntersectionObserver(([e]) => e.target.classList.toggle('IsSaveBtnPinned', e.intersectionRatio < 1), {threshold: [1]});
        stickyObserver.observe(saveBtnContainer);

        this.RetrieveData();

        this.$nextTick(()=>
        {
            LocalizationManager.Debug = true;
            LocalizationManager.TranslatePage();
            InitDarkmode();
        });
    },
    beforeUnmount(){
        LocalizationManager.UnregisterOnLanguageChangedListener(this.UpdateDataTranslation);
    },
    methods:{
        ...mapActions(['saveToken']),
        RetrieveData()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

            let data = 
            {
                SessionToken: localStorage.getItem('session_token')
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "master/factoryrelationships", data)
            .then(response => 
            {
                console.log("Received product type data.", response);
                this.ProductTypes = response.data.ProductTypes;
                this.Factories = response.data.Factories;
                this.Relationships = response.data.Relationships;
                this.Local_Relationships = JSON.parse(JSON.stringify(this.Relationships));

                var dataContainer = document.getElementById('DataList');

                // Check if we're still able to append new
                // elements since this request is async
                if (dataContainer != null)
                {
                    for(let i = 0; i < this.Factories.length; i++)
                    {
                        let entry = this.CreateNewDataEntry(this.Factories[i].ID);
                        dataContainer.append(entry.FoldOut);
                        dataContainer.append(entry.Entry);
                    }
                }
                else
                {
                    console.log('Could not find element with ID DataContainer');
                }
                
                ApplyDarkmode();
                ShowLoading(false);

            })
            .catch(error =>{
                console.log("Received error.", error);
                ShowLoading(false);
                
                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        UpdateRelationships()
        {
            ShowLoading(true, LocalizationManager.GetTranslation('text.loading'));
            console.log('update relationships');
            
            let data =
            {
                SessionToken: localStorage.getItem('session_token'),
                Relationships: this.Local_Relationships,
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'master/factoryrelationships/update', data)
            .then(response =>
            {
                console.log('Update relationship response', response);
                ShowLoading(false);
                General_Dialog.Display(
                    LocalizationManager.GetTranslation('text.masterdata.updated'),
                    LocalizationManager.GetTranslation('text.ok'),
                    function(){ location.reload(); }
                );

                ApplyDarkmode();
            })
            .catch(error =>
            {
                console.log('Received error.', error);
                ShowLoading(false);

                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        CreateNewDataEntry(dataID)
        {
            let data = this.GetRelationships(dataID);
            console.log('create data entry', data);

            // Create foldout first
            let dataFoldout = document.createElement('div');
            dataFoldout.setAttribute('control', `Data_${dataID}`);
            dataFoldout.classList.add('DataFoldout');

            let triangleIcon = document.createElement('img');
            triangleIcon.src = '/assets/Icon_Triangle.svg';
            triangleIcon.classList.add('FoldoutIcon', 'NoSelect');
            dataFoldout.append(triangleIcon);
            triangleIcon.onclick = function(event)
            {
                let elID = event.target.parentElement.getAttribute('control');
                let target = document.getElementById(elID);
                if (target.getAttribute('isunfold') == 'true')
                {
                    triangleIcon.classList.remove('FoldoutIconOpen');
                    target.classList.remove('DataTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'false');
                }
                else
                {
                    triangleIcon.classList.add('FoldoutIconOpen');
                    target.classList.add('DataTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'true');
                }
            };

            let dataName = document.createElement('div');
            dataName.classList.add('DataColumn', 'NoSelect');
            dataName.id = "Label_" + dataID;
            dataName.textContent = this.GetNameForDataID(dataID);
            dataFoldout.append(dataName);
            dataName.onclick = function(event)
            {
                let elID = event.target.parentElement.getAttribute('control');
                let target = document.getElementById(elID);
                if (target.getAttribute('isunfold') == 'true')
                {
                    triangleIcon.classList.remove('FoldoutIconOpen');
                    target.classList.remove('DataTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'false');
                }
                else
                {
                    triangleIcon.classList.add('FoldoutIconOpen');
                    target.classList.add('DataTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'true');
                }
            };

            let ezSpec = this;

            // Create data entry
            let newDataEntry = document.createElement('div');
            newDataEntry.id = `Data_${dataID}`;
            newDataEntry.classList.add('DataTableDataEntry');

            let productTypeRelationships = this.GetFactoryProductTypes(dataID);
            
            let productTypesContainer = document.createElement('div');
            productTypesContainer.id = `ProductTypes_${dataID}`;
            productTypesContainer.classList.add('ThemeElement', 'ProductTypeColumn');
            productTypesContainer.setAttribute('darkmode', 'DM_Select')
            newDataEntry.append(productTypesContainer);

            let headlineContainer = document.createElement('div');
            headlineContainer.style.display = 'flex';
            headlineContainer.style.alignItems = 'center';
            productTypesContainer.append(headlineContainer);

            let resetBtn = document.createElement('span');
            resetBtn.textContent = LocalizationManager.GetTranslation('text.reset');
            resetBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            resetBtn.setAttribute('data-key', 'text.reset');
            headlineContainer.append(resetBtn);
            resetBtn.onclick = function()
            {
                let box = document.getElementById(`Data_${dataID}_LicensorProductTypes`);
                console.log('reset', productTypeRelationships);
                for(let i = 0; i < box.options.length; i++)
                {
                    let hasRelationship = false;
                    
                    if (productTypeRelationships.length > 0)
                    {
                        for(let j = 0; j < productTypeRelationships.length; j++)
                        {
                            if (box.options[i].value == productTypeRelationships[j])
                            {
                                hasRelationship = true;
                                break;
                            }
                        }
                    }

                    ezSpec.SetLocalProductTypeRelationship(dataID, box.options[i].value, hasRelationship);
                    box.options[i].selected = hasRelationship;
                }

                ezSpec.CheckForChanges();
            };

            let clearBtn = document.createElement('span');
            clearBtn.textContent = LocalizationManager.GetTranslation('text.clear');
            clearBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            clearBtn.setAttribute('data-key', 'text.clear');
            headlineContainer.append(clearBtn);
            clearBtn.onclick = function()
            {
                let box = document.getElementById(`Data_${dataID}_LicensorProductTypes`);
                for(let i = 0; i < box.options.length; i++)
                {
                    box.options[i].selected = false;
                    ezSpec.SetLocalProductTypeRelationship(dataID, box.options[i].value, false);
                }
                
                ezSpec.CheckForChanges();
            };

            let productTypeSelect = document.createElement('select');
            productTypeSelect.multiple = true;
            productTypeSelect.id = `Data_${dataID}_LicensorProductTypes`;
            productTypesContainer.append(productTypeSelect);

            for(let i = 0; i < this.ProductTypes.length; i++)
            {
                let option = document.createElement('option');
                option.label = this.GetTranslationForData(this.LanguageIndex, this.ProductTypes[i]);
                option.value = this.ProductTypes[i].ID;
                option.classList.add('ProductTypeLabel');
                option.selected = this.IsProductTypeSelected(this.ProductTypes[i].ID, productTypeRelationships);
                productTypeSelect.options.add(option);
            }

            productTypeSelect.onchange = function (event)
            {
                let selection = event.target;
                
                for(let i = 0; i < selection.options.length; i++)
                {
                    ezSpec.SetLocalProductTypeRelationship(dataID,selection.options[i].value, selection.options[i].selected);
                }

                ezSpec.CheckForChanges();
            };



            return { FoldOut:dataFoldout, Entry:newDataEntry };
        },
        ShowProductTypeSelection(dataID)
        {
            console.log('showproducttypeselection -> licensorElement');
            let ezSpec = this;

            // let checkbox = document.getElementById(`licensor_${licensorElement.getAttribute('licensorid')}`);
            // let licensorID = checkbox.getAttribute('licensorid');

            let productTypeRelationships = this.GetFactoryProductTypes(dataID);

            let productTypesContainer = document.getElementById(`ProductTypes_${dataID}`);
            productTypesContainer.classList.add('ThemeElement', 'ProductTypeColumn');
            productTypesContainer.setAttribute('darkmode', 'DM_Select')
            productTypesContainer.replaceChildren();

            let headlineContainer = document.createElement('div');
            headlineContainer.style.display = 'flex';
            headlineContainer.style.alignItems = 'center';
            productTypesContainer.append(headlineContainer);

            // let headline = document.createElement('div');
            // headline.classList.add('Headline');
            // headline.setAttribute('licensorid', licensorID);
            // headline.textContent = LocalizationManager.GetTranslation('text.licensor.producttypes').replace('{0}', this.GetNameForLicensorID(licensorID));
            // headlineContainer.append(headline);

            let resetBtn = document.createElement('span');
            resetBtn.textContent = LocalizationManager.GetTranslation('text.reset');
            resetBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            resetBtn.setAttribute('data-key', 'text.reset');
            headlineContainer.append(resetBtn);
            resetBtn.onclick = function()
            {
                let box = document.getElementById(`Data_${dataID}_LicensorProductTypes`);
                console.log('reset', productTypeRelationships);
                for(let i = 0; i < box.options.length; i++)
                {
                    let hasRelationship = false;
                    
                    if (productTypeRelationships.length > 0)
                    {
                        for(let j = 0; j < productTypeRelationships.length; j++)
                        {
                            if (box.options[i].value == productTypeRelationships[j])
                            {
                                hasRelationship = true;
                                break;
                            }
                        }
                    }

                    ezSpec.SetLocalProductTypeRelationship(dataID, box.options[i].value, hasRelationship);
                    box.options[i].selected = hasRelationship;
                }

                ezSpec.CheckForChanges();
            };

            let clearBtn = document.createElement('span');
            clearBtn.textContent = LocalizationManager.GetTranslation('text.clear');
            clearBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            clearBtn.setAttribute('data-key', 'text.clear');
            headlineContainer.append(clearBtn);
            clearBtn.onclick = function()
            {
                let box = document.getElementById(`Data_${dataID}_LicensorProductTypes`);
                for(let i = 0; i < box.options.length; i++)
                {
                    box.options[i].selected = false;
                    ezSpec.SetLocalProductTypeRelationship(dataID, box.options[i].value, false);
                }
                
                ezSpec.CheckForChanges();
            };

            let productTypeSelect = document.createElement('select');
            productTypeSelect.multiple = true;
            productTypeSelect.id = `Data_${dataID}_LicensorProductTypes`;
            productTypesContainer.append(productTypeSelect);

            for(let i = 0; i < this.ProductTypes.length; i++)
            {
                let option = document.createElement('option');
                option.label = this.GetTranslationForData(this.LanguageIndex, this.ProductTypes[i]);
                option.value = this.ProductTypes[i].ID;
                option.classList.add('ProductTypeLabel');
                option.selected = this.IsProductTypeSelected(this.ProductTypes[i].ID, productTypeRelationships);
                productTypeSelect.options.add(option);
            }

            productTypeSelect.onchange = function (event)
            {
                let selection = event.target;
                
                for(let i = 0; i < selection.options.length; i++)
                {
                    ezSpec.SetLocalProductTypeRelationship(dataID,selection.options[i].value, selection.options[i].selected);
                }

                ezSpec.CheckForChanges();
            };

            ApplyDarkmode();
        },
        UpdateDataTranslation(oldLanguage, newLanguage)
        {
            this.LanguageIndex = LocalizationManager.GetLanguageIndex(newLanguage);
            
            
            for (let i = 0; i < this.Data.length; i++)
            {
                let label = document.getElementById(`Label_${this.Data[i].ID}`);
                if (label != null)
                {
                    label.textContent = this.GetTranslationForData(this.LanguageIndex, this.Data[i]);
                }
            }

            let licensors = document.getElementsByClassName('LicensorLabel');
            let productTypes = document.getElementsByClassName('ProductTypeLabel');
            console.log('update licensors', licensors);
            for(let i = 0; i < licensors.length; i++)
            {
                licensors[i].textContent = this.GetNameForLicensorID(licensors[i].getAttribute('licensorid'));
            }

            for(let i = 0; i < productTypes.length; i++)
            {
                productTypes[i].label = this.GetNameForProductType(productTypes[i].value);
            }

            let headlines = document.getElementsByClassName('Headline');
            for(let i = 0; i < headlines.length; i++)
            {
                let id = headlines[i].getAttribute('licensorid');
                headlines[i].textContent = LocalizationManager.GetTranslation('text.licensor.producttypes').replace('{0}', this.GetNameForLicensorID(id));
            }
        },
        GetTranslationForData(language, data)
        {
            for(let i = 0; i < data.Labels.length; i++)
            {
                if (data.Labels[i].Language == language)
                {
                    return data.Labels[i].Translation;
                }
            }

            console.log('[GetTranslationForData] Could not find translation for language ' + language, data);
            return null;
        },
        GetNameForDataID(id)
        {
            id = parseInt(id);

            for(let i = 0; i < this.Factories.length; i++)
            {
                if (this.Factories[i].ID == id)
                {
                    return this.GetTranslationForData(this.LanguageIndex, this.Factories[i]);
                }
            }

            return 'Factory ' + id + ' not found';
        },
        GetNameForProductType(id)
        {
            id = parseInt(id);
            
            for(let i = 0; i < this.ProductTypes.length; i++)
            {
                if (this.ProductTypes[i].ID == id)
                {
                    return this.GetTranslationForData(this.LanguageIndex, this.ProductTypes[i]);
                }
            }
            
            return 'ProductType ' + id + ' not found';
        },
        GetRelationships(factoryID)
        {
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Factory == factoryID)
                {
                    return this.Local_Relationships[i];
                }
            }

            return {
                Factory: factoryID,
                Licensors:[],
            };
        },
        IsProductTypeSelected(productTypeID, relationships)
        {
            productTypeID = parseInt(productTypeID);

            for(let i = 0; i < relationships.length; i++)
            {
                if (relationships[i] == productTypeID)
                {
                    return true;
                }
            }

            return false;
        },
        GetFactoryProductTypes(factoryID)
        {
            factoryID = parseInt(factoryID);
            
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Factory == factoryID)
                {
                    return this.Local_Relationships[i].ProductTypes;
                }
            }

            return [];
        },
        CheckForChanges()
        {
            let hasChanges = false;
            console.log('-- CHECK FOR CHANGES --');
            console.log('local', this.Local_Relationships);
            console.log('original', this.Relationships);
            if (hasChanges == false)
            {
                for(let i = 0; i < this.Local_Relationships.length; i++)
                {
                    for(let k = 0; k < this.Local_Relationships[i].ProductTypes.length; k++)
                    {
                        let hasProductType = this.HasOriginalDataProductType(this.Local_Relationships[i].Factory, this.Local_Relationships[i].ProductTypes[k]);
                        hasChanges = hasProductType == false;
                        
                        if (hasChanges == true)
                        {
                            console.log(`Change found. Data ${this.Local_Relationships[i].Factory} does not have product type ${this.Local_Relationships[i].ProductTypes[k]}`);
                            break;
                        }
                    }

                    if (hasChanges == true)
                    {
                        break;
                    }
                }
            }

            this.SaveBtnContainer.style.visibility = hasChanges ? 'visible' : 'hidden';
        },
        SetLocalProductTypeRelationship(factoryID, productTypeID, hasRelationship)
        {
            factoryID = parseInt(factoryID);
            productTypeID = parseInt(productTypeID);

            console.log('set factory ' + factoryID + ' ProductType ' + productTypeID + ' checked: ' + hasRelationship);
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Factory == factoryID)
                {
                    if (hasRelationship == true)
                    {
                        let alreadyHasProductType = false;

                        // Check first if we already have the entry
                        for (let k = 0; k < this.Local_Relationships[i].ProductTypes.length; k++)
                        {
                            if (this.Local_Relationships[i].ProductTypes[k] == productTypeID)
                            {
                                alreadyHasProductType = true;
                                break;
                            }
                        }

                        if (alreadyHasProductType == false)
                        {
                            this.Local_Relationships[i].ProductTypes.push(productTypeID);
                        }
                    }
                    else
                    {
                        for(let k = 0; k < this.Local_Relationships[i].ProductTypes.length; k++)
                        {
                            if (this.Local_Relationships[i].ProductTypes[k] == productTypeID)
                            {
                                this.Local_Relationships[i].ProductTypes.splice(k, 1);
                            }
                        }
                    }
                }
            }
        },
        HasOriginalDataProductType(factoryID, productTypeID)
        {
            factoryID = parseInt(factoryID);
            productTypeID = parseInt(productTypeID);

            for(let i = 0; i < this.Relationships.length; i++)
            {
                if (this.Relationships[i].ID == factoryID)
                {
                    for(let k = 0; k < this.Relationships[i].ProductTypes.length; k++)
                    {
                        if (this.Relationships[i].ProductTypes[k] == productTypeID)
                        {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
    },
  data() {
    return {
        SaveBtnContainer: null,
        LanguageIndex: 0,
        CurrentActiveLicensorLabels: [],
        // Original data
        Factories: [],
        ProductTypes: [],
        Relationships: [],
        // Local data used for udating
        Local_Relationships: [],
    }
  },
}
</script>

<style>
#Wrapper
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#DataTable
{
    padding-bottom: 50px;
}

#DataTableHeader
{
    display: flex;
    height: 64px;
    font-weight: bold;
    font-size: 1.2rem;
}

#DataTableBody
{

}

.DataColumn
{
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 250px;
    flex: 1;
}

.DataColumn select
{
    border: 1px solid #ccc;
}

.DataColumn select
{
    flex: 1;
    min-height: 150px;
    padding: 10px 0px 10px 5px;
    border-radius: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgb(0,169,157) #ccc;
    overflow-y: auto;
}

#DataList
{
    padding: 25px 0px 75px 0px;
    display: grid;
    width: 100%;
}

.DataColumn
{
    text-align: left;
    justify-content: unset;
}

.LicensorColumn
{
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0px 10px 0px;
}

.LicensorColumn .Licensor
{
    display: flex;
    align-items: center;
}

.Licensor label
{
    /* grid-column-start: 2;
    grid-column-end: span 2; */
    display: inline-block;
}

.Licensor input
{
    width: 16px;
    margin: 0px 10px 0px 20px;
}

.ProductTypeColumn
{
    margin-left: 20px;
}

.ProductTypeColumn .Headline
{
    font-weight: bold;
    margin: 10px 0px 10px 0px;
}

.ProductTypeColumn select
{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 8px 5px 8px 5px;
    font-size: 1.2rem;
}

.DataFoldout
{
    display: flex;
    border-bottom: 1px solid #ccc;
}

.DataFoldout .FoldoutIcon
{
    height: 18px;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.FoldoutIcon
{
    transform: rotateZ(0deg);
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
}

.FoldoutIconOpen
{
    transform: rotateZ(60deg);
}

.DataTableDataEntry
{
    display: grid;
    max-height: 0px;
    overflow: hidden;
    transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
}

.DataTableDataEntry input
{
    
}

.DataTableDataEntry + .DataFoldout
{
    margin-top: 50px;
}

.DataTableDataEntryUnfold
{
    max-height: 1000px;
}

.DataTableDataEntry + .DataTableDataEntry
{
    margin-top: 30px;
}

.LicensorLabel
{
    white-space: nowrap;
    cursor: pointer;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
}

.LicensorLabel:hover
{
    color: rgb(0,169,157);
    text-decoration: underline;
}

.LicensorLabelActive
{
    color: rgb(0,169,157);
    font-weight: bold;
}

.ClearButton
{
    margin-left: 25px;
    color: rgb(0,169,157);
    cursor: pointer;
}
</style>