<template>
  <div id="MainContent">
    <div style="display: flex;">
        <!-- <button @click="SACHIKO_Products">SACHIKO-商品</button>
        <button @click="SACHIKO_Projects">SACHIKO-プロジェクト</button>
        <button @click="SACHIKO_Factories">SACHIKO-工場</button>
        <button @click="SACHIKO_Series">SACHIKO-シリーズ</button> -->
    </div>
    <div class="BrightColorText localize" data-key="text.maintitle" style="font-size: 2rem;">仕様書登録</div>
    <div style="display: flex;">
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.basicinfo">基本情報</div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.productspec.label">商品仕様:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductSpecificationSelection'" :options="productSpecificationOptions" v-model="selProductSpecifications" @change="updateSelectedProductSpecification"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.licensor.label">版権元:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="true" :elementID="'CopyrightSourceSelection'" :options="copyrightSourceOptions" v-model="selCopyrightSource" @change="updateSelectedCopyrightSource"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.series">シリーズ:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="true" :elementID="'SeriesSelection'" :options="seriesOptions" v-model="selSeries" @change="updateSelectedSeries"></ManufacturerDropdown>
            </div>
            <div v-if="selProductSpecificationID===4" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.scale">スケール:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ScaleSelection'" :options="scaleOptions" v-model="selScaleID" @change="updateSelectedScale"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.productname">商品名:</span>
                <span class="spacer"></span>
                <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'ProductNameInput'" productNameInputLabel="商品名:" @input-change="updateInputValue"/>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.factoryshippingday">工場出荷日:</span>
                <span class="spacer"></span>
                <div class="ThemeElement" Darkmode="DM_Input">
                    <input id="ShippingDateSelection" type="date" @change="updateSelectedShippingDate"/>
                </div>
            </div>
            <div id="DateWarning" v-if="ShowDateWarning === true">
                <span id="DateWarningText" style="color: #888;" class="localize" data-key="text.warning.shipping">工場出荷日は発売日の後に設定しました！</span>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.factory">工場:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="true" :elementID="'ManufacturerSelection'" :options="factoryOptions" v-model="selMan" @change="updateSelectedManufacturer"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.releasedate">発売日:</span>
                <span class="spacer"></span>
                <div class="ThemeElement" Darkmode="DM_Input">
                    <input id="SalesDateSelection" type="date" @change="updateSelectedSalesDate"/>
                </div>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.salesdestination">販売先</span> 
                <span class="spacer"></span>
                <MultiDropdown class="ContentSubline ThemeElement" Darkmode="DM_Select" :elementID="'SalesDestinationsSelection'" :options="salesDestinationOptions" v-modle="selSalesDestinations" @change="updateSelectedSalesDestination"></MultiDropdown>
            </div>
            <div class="FormEntry" style="margin-top: 2px;">
                <span></span>
                <span class="spacer"></span>
                <div class="HintText localize" data-key="text.salesdestination.hint">To select multiple sales destinations, hold the mouse button or press the left shift or ctrl key.</div>
            </div>
        </div>
        <div id="SummaryContainer" class="ThemeElement" DarkMode="DM_SummaryContainer">
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.productspec">商品仕様</div><div class="SummaryEntryValue">{{ selProductSpecifications }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.licensor">版権元</div><div class="SummaryEntryValue">{{ selCopyrightSource }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.series">シリーズ:</div><div class="SummaryEntryValue">{{ selSeries }}</div></div>
            <div v-if="selProductSpecificationID === 4" class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.scale">スケール:</div><div class="SummaryEntryValue">{{  selScale }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.productname">商品名:</div><div class="SummaryEntryValue">{{ prodName }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.factoryshippingday">工場出荷日:</div><div class="SummaryEntryValue">{{ shippingDate }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.factory">工場:</div><div class="SummaryEntryValue">{{ selMan }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.releasedate">発売日:</div><div class="SummaryEntryValue">{{ salesDate }}</div></div>
            <div class="SummaryEntry"><div class="SummaryEntryLabel localize" data-key="text.salesdestination">販売先:</div><div class="SummaryEntryValue">{{ selSalesDestinations }}</div></div>
        </div>
    </div>
    <button class="Button ButtonGreen NextPrevBtn DisabledButton localize" data-key="text.next" id="NextBtn" :disabled="true" @click="GoToNextStep">次へ</button>
  </div>
</template>

<script>
import TextInput from './components/TextInput.vue'
import ManufacturerDropdown from './components/ManufacturerDropdown.vue';
import MultiDropdown from './components/MultiDropdown.vue';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import { InitDarkmode, ApplyDarkmode } from './Darkmode.js';
import axios from 'axios';
import { LocalizationManager } from './Localization.js';

export default
{
  name: 'App',
  components:
  {
    TextInput,
    ManufacturerDropdown,
    MultiDropdown,
},
  computed:{
    ...mapGetters(['getStep1Data','getStep3Data','getIsLoggedIn','getToken']),
    Step1Data(){
        return this.getStep1Data;
    },
    Step3Data(){
        return this.getStep3Data;
    },
    IsLoggedIn(){
        return this.getIsLoggedIn;
    },
    Token(){
        return this.getToken;
    }
  },
  beforeUnmount(){
    LocalizationManager.UnregisterOnLanguageChangedListener(this.TranslateProductTypes);
    LocalizationManager.UnregisterOnLanguageChangedListener(this.TranslateProductOptions);
    LocalizationManager.UnregisterOnLanguageChangedListener(this.TranslateSalesDestinations);
    LocalizationManager.UnregisterOnLanguageChangedListener(this.TranslateSeriesOptions);
  },
  mounted(){
    document.title = "EZ-SPEC";
    
    InitDarkmode();
    
    if (localStorage.getItem('session_token') == null)
    {
        this.$router.push('/');
        return;
    }
    else
    {
        this.saveToken(localStorage.getItem('session_token'));
    }

    console.log("init");

    if (LocalizationManager.IsLocaInitialized == false)
    {
        LocalizationManager.Initialize();
    }

    LocalizationManager.RegisterOnLanguageChangedListener(this.TranslateProductTypes);
    LocalizationManager.RegisterOnLanguageChangedListener(this.TranslateProductOptions);
    LocalizationManager.RegisterOnLanguageChangedListener(this.TranslateSalesDestinations);
    LocalizationManager.RegisterOnLanguageChangedListener(this.TranslateSeriesOptions);
    
    this.GetProductTypes((success) =>
    {
        if (success == false)
        {
            alert("Could not retrieve data from server!\nサーバーからデータを取得できませんでした。(1)")
            this.IsInitialized = true;
            ApplyDarkmode();
            this.ValidateNextButton();
            LocalizationManager.TranslatePage();
            return;
        }

        if (this.Step1Data)
        {
            this.LoadStepData();

            // ToDo: Show loading animation
            this.GetProductOptions((success) =>
            {
                if (success == false)
                {
                    alert("Could not retrieve data from server!\nサーバーからデータを取得できませんでした。(3)")
                    this.IsInitialized = true;
                    InitDarkmode();
                    this.ValidateNextButton();
                    LocalizationManager.TranslatePage();
                    return;
                }

                this.GetSeriesOptions((success) =>
                {
                    if (success == false)
                    {
                        alert("Could not retrieve data from server!\nサーバーからデータを取得できませんでした。(4)")
                        this.IsInitialized = true;
                        InitDarkmode();
                        this.ValidateNextButton();
                        LocalizationManager.TranslatePage();
                        return;
                    }

                    const event = new Event('change', { bubbles: true });
                    event.PreventUpdate = true;
                    
                    var target = document.getElementById("ProductSpecificationSelection");
                    if (target != null)
                    {
                        for(let i = 0; i < this.productSpecificationOptions.length; i++)
                        {
                            if (this.productSpecificationOptions[i].value == this.selProductSpecificationID)
                            {
                                target.selectedIndex = i;
                                break;
                            }
                        }
                        target.dispatchEvent(event);
                    }

                    target = document.getElementById("CopyrightSourceSelection");
                    if (target != null)
                    {
                        console.log("look for copyright id", this.selCopyrightSourceID);
                        for(let i = 0; i < this.copyrightSourceOptions.length; i++)
                        {
                            if (this.copyrightSourceOptions[i].value == this.selCopyrightSourceID)
                            {
                                target.selectedIndex = i;
                                break;
                            }
                        }
                        target.disabled = false;
                        target.dispatchEvent(event);
                    }

                    target = document.getElementById("ScaleSelection");
                    if (target != null)
                    {
                        console.log("load scale", this.selScaleID);
                        for(let i = 0; i < this.scaleOptions.length; i++)
                        {
                            if (this.scaleOptions[i].value == this.selScaleID)
                            {
                                target.selectedIndex = i;
                                break;
                            }
                        }
                        target.disabled = false;
                        target.dispatchEvent(event);
                    }

                    this.GetSalesDestinations((success)=>
                    {
                        if (success == false)
                        {
                            console.log("COULD NOT RECEIVE SALES DESTINATIONS!");
                            return;
                        }

                        setTimeout(()=>
                        {
                            target = document.getElementById("SalesDestinationsSelection");
                            if (target != null)
                            {
                                for (var i = 0; i < this.selSalesDestinationsIDs.length; i++)
                                {
                                    for(let o = 0; o < target.options.length; o++)
                                    {
                                        if (target.options[o].value == this.selSalesDestinationsIDs[i])
                                        {
                                            target.options[o].selected = true;
                                        }
                                    }
                                }
                                target.dispatchEvent(event);
                            }
                        }, 10);
                    });

                    // I don't know why and where but something keeps
                    // setting the selectedIndex of this element to -1
                    // so we override it with the proper info in the
                    // next tick
                    this.$nextTick(()=>
                    {
                        let starget = document.getElementById("SeriesSelection");
                        if (starget != null)
                        {
                            console.log("look for series id " + this.selSeriesID, this.seriesOptions);
                            for(let i = 0; i < this.seriesOptions.length; i++)
                            {
                                if (this.seriesOptions[i].value == this.selSeriesID)
                                {
                                    starget.selectedIndex = i;
                                    break;
                                }
                            }
                            starget.disabled = false;
                            starget.dispatchEvent(event);
                                
                            console.log(starget.selectedIndex);
                        }
                    });

                    target = document.getElementById("ManufacturerSelection");
                    if (target != null)
                    {
                        console.log("look for manufacturer id", this.selManufacturerID);
                        for(let i = 0; i < this.factoryOptions.length; i++)
                        {
                            if (this.factoryOptions[i].value == this.selManufacturerID)
                            {
                                target.selectedIndex = i;
                                break;
                            }
                        }
                        target.disabled = false;
                        target.dispatchEvent(event);
                    }

                    target = document.getElementById("ShippingDateSelection");
                    if (target!=null){
                        target.value = this.Step1Data.ShippingDate;
                        target.dispatchEvent(event);
                    }
                    target = document.getElementById("SalesDateSelection");
                    if (target!=null){
                        target.value = this.Step1Data.SalesDate;
                        target.dispatchEvent(event);
                    }
                    target = document.getElementById("ProductNameInput");
                    if (target != null){
                        const inpEvent = new Event('input');
                        target.value = this.Step1Data.ProductName;
                        target.dispatchEvent(inpEvent);
                    }
                    target = document.getElementById("NeedBoxSelection");
                    if (target != null){
                        target.selectedIndex = this.Step1Data.NeedBoxIndex;
                        target.dispatchEvent(event);
                    }
                    this.IsInitialized = true;
                    this.$nextTick(()=>
                    {
                        InitDarkmode();
                        this.ValidateNextButton();
                        LocalizationManager.TranslatePage();
                    });
                });
            });
        }
        else{
            this.IsInitialized = true;
            this.$nextTick(()=>
            {
                InitDarkmode();
                this.ValidateNextButton();
                LocalizationManager.TranslatePage();
            });
        }
    });

    
  },
  data()
  {
    return{
      IsInitialized: false,
      ShowDateWarning: false,
      prodName: '',
      shippingDate: '',
      salesDate: '',
      selMan: '',
      selManufacturerID: 0,
      selSeriesID: 0,
      selSeries: '',
      key_series: '',
      selScaleID: 0,
      selScale: '',
      key_scale: '',
      selProductSpecificationID: 0,
      selProductSpecifications: '',
      key_selProductSpecifications: '',
      selCopyrightSourceID: 0,
      selCopyrightSource: '',
      selSalesDestinations: [],
      key_salesDestinations: [],
      selSalesDestinationsIDs: [],
      manufacturerOptions: [],
      seriesOptions: [],
      scaleOptions: [],
      salesDestinationOptions:[],
      needBoxOptions:[
        {label: "必要", value: 0},
        {label: "無", value: 1}
      ],
      productSpecificationOptions:[],
      copyrightSourceOptions:[],
    };
  },
  methods:
  {
    GET_SACHIKO_HEADER()
    {
        return {
            headers:{
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${process.env.VUE_APP_SACHIKO_AUTH}`
            }
        };
    },
    SACHIKO_Products()
    {
        axios.get(process.env.VUE_APP_PROJ_SACHIKO_API_URL + "/api/product/",
        {
            headers:{
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${process.env.VUE_APP_SACHIKO_AUTH}`
            }
        })
        .then(async response =>
        {
            console.log("(SACHIKO-Products) Received response", response);
            console.log("(SACHIKO-Products) ", response.data.response);
        })
        .catch(error =>
        {
            console.log("(SACHIKO-Products) Received error", error);
        });
    },
    SACHIKO_Projects()
    {
        axios.get(process.env.VUE_APP_PROJ_SACHIKO_API_URL + "/api/project/",
                  this.GET_SACHIKO_HEADER()
        )
        .then(async response =>
        {
            console.log("(SACHIKO-Projects) Received response", response);
            console.log("(SACHIKO-Projects) ", response.data.response);
        })
        .catch(error =>
        {
            console.log("(SACHIKO-Projects) Received error", error);
        });
    },
    SACHIKO_Factories()
    {
        axios.get(process.env.VUE_APP_PROJ_SACHIKO_API_URL + '/api/factory/',
                  this.GET_SACHIKO_HEADER()
        )
        .then(async response =>
        {
            console.log("(SACHIKO-Factories) Received response", response);
            console.log("(SACHIKO-Factories) ", response.data.response);
        })
        .catch(error =>
        {
            console.log("(SACHIKO-Factories) Received error", error);
        });
    },
    SACHIKO_Series()
    {
        axios.get(process.env.VUE_APP_PROJ_SACHIKO_API_URL + '/api/series/',
                  this.GET_SACHIKO_HEADER()
        )
        .then(async response =>
        {
            console.log("(SACHIKO-Series) Received response", response);
            console.log("(SACHIKO-Series) ", response.data.response);
        })
        .catch(error =>
        {
            console.log("(SACHIKO-Series) Received error", error);
        });
    },
    ValidateNextButton()
    {
        if (this.shippingDate != "" &&
            this.salesDate != "" &&
            this.selSalesDestinations.length > 0)
        {
            var nxtBtn = document.getElementById("NextBtn");
            if (nxtBtn != null){
                nxtBtn.classList.remove("DisabledButton");
                nxtBtn.disabled = false;
            }
        }
    },
    updateInputValue(value)
    {
      this.prodName = value;
    },
    updateSelectedManufacturer(value)
    {
        if (value==null){
            return;
        }
        if (value.target.selectedIndex == -1)
        {
            return;
        }
      this.selMan = value.target.options[value.target.selectedIndex].label;
      this.selManufacturerID = parseInt(value.target.options[value.target.selectedIndex].value);
    },
    updateSelectedProductSpecification(value)
    {
        if (value==null)
        {
            return;
        }
        
        if (value.target.selectedIndex == -1)
        {
            return;
        }
        
        if (value.PreventUpdate != undefined)
        {
            return;
        }

        this.selProductSpecifications = value.target.options[value.target.selectedIndex].label;
        this.key_selProductSpecifications = value.target.options[value.target.selectedIndex].getAttribute("data-key");
        this.selProductSpecificationID = parseInt(value.target.options[value.target.selectedIndex].value);

        // If a new selection appears, like for plastic model kits,
        // we have to re-apply the darkmode theme.
        this.$nextTick(()=>
        {
            ApplyDarkmode();
        });

        this.selCopyrightSource = '';
        this.selCopyrightSourceIndex = -1;
        this.selSeries = '';
        this.key_series = '';
        this.selSeriesIndex = -1;

        this.seriesOptions = [];
        let seriesSel = document.getElementById("SeriesSelection");
        seriesSel.disabled = true;

        this.GetSalesDestinations((success) =>
        {
            console.log("Got sales destinations " + success);
        });

        this.GetProductOptions((success) =>
        {
            setTimeout(()=>{
                const event = new Event('change', { bubbles: true });
                let copyRightSel = document.getElementById("CopyrightSourceSelection");
                let manufacturerSel = document.getElementById("ManufacturerSelection");

                copyRightSel.disabled = !success;
                copyRightSel.selectedIndex = -1;
                copyRightSel.dispatchEvent(event);
                this.selCopyrightSource = '';
                this.selCopyrightSourceIndex = -1;

                manufacturerSel.disabled = !success;
                manufacturerSel.selectedIndex = -1;
                manufacturerSel.dispatchEvent(event);
                this.selMan = '';
                this.selManIndex = -1;
            }, 50);
            

            if (success == false)
            {
                alert("Could not retrieve product options!");
            }
        });
    },
    updateSelectedCopyrightSource(value)
    {
        if (value==null)
        {
            return;
        }
        if (value.target.selectedIndex == -1)
        {
            return;
        }
        if (value.PreventUpdate != undefined)
        {
            return;
        }

        this.selCopyrightSource = value.target.options[value.target.selectedIndex].label;
        this.selCopyrightSourceID = parseInt(value.target.options[value.target.selectedIndex].value);

        if (value.PreventUpdate == undefined)
        {
            console.log("remove step3data",value);
            this.saveStep3Data(undefined);
        }

        const event = new Event('change', { bubbles: true });
        let seriesSel = document.getElementById("SeriesSelection");
        seriesSel.disabled = false;
        seriesSel.selectedIndex = -1;
        seriesSel.dispatchEvent(event);
        this.selSeries = '';
        this.key_series = '';
        this.selSeriesIndex = -1;

        console.log("Selected copyright source", this.selCopyrightSource);

        this.GetSeriesOptions((success) =>
        {
            // Have to use setTimeout instead of this.$next
            // because we don't have access to this (app) in
            // this callback
            setTimeout(()=>
            {
                const event = new Event('change', { bubbles: true });
                let seriesSel = document.getElementById("SeriesSelection");
                seriesSel.disabled = !success;
                console.log(this.seriesOptions);
                
                this.selSeries = '';
                this.key_series = '';

                console.log("Series el options: " + seriesSel.options.length);
                for(let o = 0; o < seriesSel.options.length; o++)
                {
                    console.log(seriesSel.options[o]);
                }

                if (this.seriesOptions.length == 1)
                {
                    console.log("set 0 as selected", seriesSel);
                    seriesSel.selectedIndex = 0;
                    this.selSeriesIndex = 0;
                }
                else
                {
                    console.log("set -1 as selected");
                    seriesSel.selectedIndex = -1;
                    this.selSeriesIndex = -1;
                }
                seriesSel.dispatchEvent(event);
            }, 50);
            


            if (success == false)
            {
                alert("Could not retrieve series options!");
            }
        });
    },
    updateSelectedSeries(value)
    {
        if (value==null){
            return;
        }
        if (value.target.selectedIndex == -1)
        {
            return;
        }
        this.selSeries = value.target.options[value.target.selectedIndex].label;
        this.key_series = value.target.options[value.target.selectedIndex].getAttribute('data-key');
        this.selSeriesID = parseInt(value.target.options[value.target.selectedIndex].value);
    },
    updateSelectedScale(value)
    {
        if (value==null)
        {
            return;
        }
        if (value.target.selectedIndex == -1)
        {
            return;
        }
        this.selScale = value.target.options[value.target.selectedIndex].label;
        this.key_scale = value.target.options[value.target.selectedIndex].getAttribute('data-key');
        this.selScaleID = parseInt(value.target.options[value.target.selectedIndex].value);
        console.log('[Step1](updateSelectedScale) key: ', this.key_scale);
    },
    updateSelectedShippingDate(value)
    {
        if (value==null){
            return;
        }

        this.shippingDate = value.target.value;

        if (this.salesDate != null &&
            this.salesDate != "")
        {
            var d = new Date(value.target.value);
            var sD = new Date(this.salesDate);

            if (d > sD)
            {
                this.ShowDateWarning = true;
                this.$nextTick (() =>
                {
                    LocalizationManager.TranslateElement(document.getElementById("DateWarningText"));
                });
            }
            else
            {
                this.ShowDateWarning = false;
            }
        }
        else{
            console.log("Sales date: " + this.salesDate + " Shipping date: " + this.shippingDate);
        }

        this.ValidateNextButton();
    },
    updateSelectedSalesDate(value)
    {
        if (value==null)
        {
            return;
        }

        this.salesDate = value.target.value;
        
        if (this.shippingDate != null &&
            this.shippingDate != "")
        {
            var d = new Date(value.target.value);
            var sD = new Date(this.shippingDate);

            if (d < sD)
            {
                this.ShowDateWarning = true;
                this.$nextTick (() =>
                {
                    LocalizationManager.TranslateElement(document.getElementById("DateWarningText"));
                });
            }
            else
            {
                this.ShowDateWarning = false;
            }
        }

        this.ValidateNextButton();
    },
    updateSelectedSalesDestination(event)
    {
        this.selSalesDestinations = [];
        this.key_salesDestinations = [];
        this.selSalesDestinationsIDs = [];
        for (let i = 0; i < event.target.options.length; i++)
        {
            var opt = event.target.options[i];
            if (opt.selected)
            {
                console.log("Selected opt", opt);
                this.selSalesDestinations.push(opt.label);
                this.key_salesDestinations.push(opt.getAttribute('data-key'));
                this.selSalesDestinationsIDs.push(opt.value);
            }
        }

        this.ValidateNextButton();
    },
    ...mapActions(['saveStep1Data','saveStep3Data','saveToken']),
    async WaitForLocalizationInit()
    {
        while (LocalizationManager.IsLocaInitialized == false){
            await this.delay(100);
        }

        console.log("Localization initialized");
    },
    delay(ms)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    GetProductTypes(callback)
    {
        console.log("sessionToken",localStorage.getItem('session_token'));
        let data = 
        {
            SessionToken: localStorage.getItem('session_token')
        };

        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "producttypes", data)
        .then(async response =>
        {
            console.log("Received product types", response);
            this.productSpecificationOptions = [];
            try
            {
                this.productSpecificationOptions = response.data;
                if (LocalizationManager.IsLocaInitialized==false)
                {
                    await this.WaitForLocalizationInit();
                    this.TranslateProductTypes();
                    callback(true);
                }
                else
                {
                    this.TranslateProductTypes();
                    callback(true);
                }
            }
            catch(error)
            {
                console.error(error);
                callback(false);
            }
            
        })
        .catch(error =>
        {
            console.log("Received error", error);
            if (error.response.status != 500)
            {
                this.$HandleBadRequest(error.response.status, error.response.data);
            }
            else
            {
                callback(false);
            }
        });
    },
    GetSalesDestinations(callback)
    {
        let data = 
        {
            SessionToken: localStorage.getItem('session_token'),
            SelectedProductTypeID: this.selProductSpecificationID,
        };

        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "salesdestinations", data)
        .then(async response =>
        {
            console.log("Received sales destinations", response);
            this.salesDestinationOptions = [];
            try
            {
                this.salesDestinationOptions = response.data;
                if (LocalizationManager.IsLocaInitialized == false)
                {
                    await this.WaitForLocalizationInit();
                    this.TranslateSalesDestinations();
                    callback(true);
                }
                else
                {
                    this.TranslateSalesDestinations();
                    callback(true);
                }
            }
            catch(error)
            {
                console.error(error);
                callback(false);
            }
            
        })
        .catch(error =>
        {
            console.log("Received error", error);
            callback(false);
        });
    },
    GetTranslationForLanguage(languageIndex, translations)
    {
        for (let i = 0; i < translations.length; i++)
        {
            if (translations[i].Language == languageIndex)
            {
                return translations[i].Translation;
            }
        }

        return null;
    },
    TranslateProductTypes()
    {
        let currentLanguage = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        for (var i = 0; i < this.productSpecificationOptions.length; i++)
        {
            this.productSpecificationOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.productSpecificationOptions[i].Translations);// = ({ locakey: this.productSpecificationOptions[i].label, label: LocalizationManager.GetTranslation(this.productSpecificationOptions[i].label), value: this.productSpecificationOptions[i].value });
            this.productSpecificationOptions[i].locakey = this.productSpecificationOptions[i].Key;
        }
    },
    TranslateSalesDestinations()
    {
        let currentLanguage = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        for (var i = 0; i < this.salesDestinationOptions.length; i++)
        {
            this.salesDestinationOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.salesDestinationOptions[i].Translations);// = ({ locakey: this.salesDestinationOptions[i].label, label: LocalizationManager.GetTranslation(this.salesDestinationOptions[i].label), value: this.salesDestinationOptions[i].value });
            this.salesDestinationOptions[i].locakey = this.salesDestinationOptions[i].Key;
        }
    },
    TranslateProductOptions()
    {
        let currentLanguage = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        for (let i = 0; i < this.copyrightSourceOptions.length; i++)
        {
            this.copyrightSourceOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.copyrightSourceOptions[i].Translations);// = ({ locakey: this.copyrightSourceOptions[i].label, label: LocalizationManager.GetTranslation(this.copyrightSourceOptions[i].label), value: this.copyrightSourceOptions[i].value });
            this.copyrightSourceOptions[i].locakey = this.copyrightSourceOptions[i].Key;
        }

        for (let i = 0; i < this.factoryOptions.length; i++)
        {
            this.factoryOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.factoryOptions[i].Translations);// = ({ locakey: this.factoryOptions[i].label, label: LocalizationManager.GetTranslation(this.factoryOptions[i].label), value: this.factoryOptions[i].value });
            this.factoryOptions[i].locakey = this.factoryOptions[i].Key;
        }

        this.scaleOptions.sort((a, b) =>{
            return a.Order - b.Order;
        });
        for (let i = 0; i < this.scaleOptions.length; i++)
        {
            this.scaleOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.scaleOptions[i].Translations);// = ({ locakey: this.scaleOptions[i].label, label: LocalizationManager.GetTranslation(this.scaleOptions[i].label), value: this.scaleOptions[i].value });
            this.scaleOptions[i].locakey = this.scaleOptions[i].Key;
        }
    },
    TranslateSeriesOptions()
    {
        let currentLanguage = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        for (let i = 0; i < this.seriesOptions.length; i++)
        {
            this.seriesOptions[i].label = this.GetTranslationForLanguage(currentLanguage, this.seriesOptions[i].Translations);// = ({ locakey: this.seriesOptions[i].label, label: LocalizationManager.GetTranslation(this.seriesOptions[i].label), value: this.seriesOptions[i].value });
            this.seriesOptions[i].locakey = this.seriesOptions[i].Key;
        }
    },
    // After selecting the product type,
    // we need to get the valid copyright holder options
    // and manufacturer options
    GetProductOptions(callback)
    {
        var data = {
            SelectedProductTypeID: this.selProductSpecificationID,
            SessionToken: localStorage.getItem('session_token')
        };

        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "productoptions", data,
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        })
        .then(async response =>
        {
            console.log("Received product options", response);
            this.copyrightSourceOptions = response.data.CopyrightHolders;
            this.factoryOptions = response.data.Factories;
            this.scaleOptions = response.data.ScaleOptions;

            if (LocalizationManager.IsLocaInitialized==false)
            {
                await this.WaitForLocalizationInit();
                this.TranslateProductOptions();
                callback(true);
            }
            else
            {
                this.TranslateProductOptions();
                callback(true);
            }
        })
        .catch(error =>{
            console.log("Received error.", error);
            callback(false);
        });
    },
    GetSeriesOptions(callback)
    {
        this.seriesOptions = [];

        var data = 
        {
            SelectedLicenserID: this.selCopyrightSourceID,
            SelectedProductTypeID: this.selProductSpecificationID,
            SessionToken: localStorage.getItem('session_token')
        };

        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "seriesoptions", data,
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        })
        .then(async response => {
            console.log("Received series options", response);
            this.seriesOptions = response.data;

            if (LocalizationManager.IsLocaInitialized==false)
            {
                await this.WaitForLocalizationInit();
                this.TranslateSeriesOptions();
                callback(true);
            }
            else
            {
                this.TranslateSeriesOptions();
                callback(true);
            }
        })
        .catch(error =>{
            console.log("Received error.", error);
            callback(false);
        });
    },
    GoToNextStep()
    {
        var d = {
            ProductName: this.prodName,
            ShippingDate: this.shippingDate,
            SalesDate: this.salesDate,
            Manufacturer: this.selMan,
            ManufacturerID: this.selManufacturerID,
            Series: this.selSeries,
            Key_Series: this.key_series,
            SeriesID: this.selSeriesID,
            Scale: this.selScale,
            Key_Scale: this.key_scale,
            ScaleID: this.selScaleID,
            SalesDestinations: this.selSalesDestinations,
            Key_SalesDestinations: this.key_salesDestinations,
            SalesDestinationsIDs: this.selSalesDestinationsIDs,
            ProductSpecification: this.selProductSpecifications,
            Key_ProductSpecification: this.key_selProductSpecifications,
            ProductSpecificationID: this.selProductSpecificationID,
            CopyrightSourceID: this.selCopyrightSourceID,
            CopyrightSource: this.selCopyrightSource,
        };
        this.saveStep1Data(d);
        console.log('Save step 1', d);
        this.$router.push('/step2');
    },
    LoadStepData()
    {
        this.prodName = this.Step1Data.ProductName;
        this.shippingDate = this.Step1Data.ShippingDate;
        this.salesDate = this.Step1Data.SalesDate;
        this.selMan = this.Step1Data.Manufacturer;
        this.selManufacturerID = this.Step1Data.ManufacturerID;
        this.selSeries = this.Step1Data.Series;
        this.key_series = this.Step1Data.Key_Series;
        this.selSeriesID = this.Step1Data.SeriesID;
        this.selScale = this.Step1Data.Scale;
        this.key_scale = this.Step1Data.Key_Scale;
        this.selScaleID = this.Step1Data.ScaleID;
        this.selSalesDestinations = this.Step1Data.SalesDestinations;
        this.key_salesDestinations = this.Step1Data.Key_SalesDestinations;
        this.selSalesDestinationsIDs = this.Step1Data.SalesDestinationsIDs;
        this.selProductSpecificationID = this.Step1Data.ProductSpecificationID;
        this.selProductSpecifications = this.Step1Data.ProductSpecification;
        this.key_selProductSpecifications = this.Step1Data.Key_ProductSpecification;
        this.selCopyrightSource = this.Step1Data.CopyrightSource;
        this.selCopyrightSourceID = this.Step1Data.CopyrightSourceID;
        console.log("Loaded step 1 data", this.Step1Data);
    },
  }
}
</script>
<style>
#DateWarning
{
    font-size: 55%;
    background-color: yellow;
    padding-left: 15px;
    border-radius: 100px;
    width: 220px;
    margin-bottom: 3px;
}

@media only screen and (max-width: 768px)
{
    #SummaryContainer
    {
        display: none;
    }
}
</style>