<template>
    <div id="MainContent">
        <div class="BrightColorText localize" data-key="text.maintitle" style="font-size: 2rem;">仕様書登録</div>
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.copyrightnotice">版権表記</div>
            <div v-if="ShowIsPlexItem === true" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.plexitem.title">PLEXアイテム:</span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'IsPlexSelection'" :disabled="isPlexSelectionDisabled" v-model="isPlexItem" @togglechange="onUpdateSelectedIsPlexItem"></CustomCheckbox>
            </div>
            <div class="FormEntry" v-if="showProductCharacterOptions === true">
                <span class="ContentSubline localize" data-key="text.publishernotation">版元表記:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductCharacterTypeSelection'" :options="productCharacterTypeOptions" v-model="selProductCharacterType" @change="onUpdateSelectedProductCharacterType"></ManufacturerDropdown>
            </div>
            <div class="FormEntry" v-if="showShinUltramanCheckbox === true">
                <span class="ContentSubline localize" data-key="text.shinultraman">シンウルトラマン:</span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'IsShinUltramanSelection'" v-model="isShinUltraman" @togglechange="onUpdateSelectedIsShinUltraman"></CustomCheckbox>
            </div>
            <div v-if="shorthandWritingOptions.length > 1" style="margin-top: 8px;">
                <div class="FormEntry" v-for="(item, index) in shorthandWritingOptions" :key="index">
                    <span style="margin-left: 10px; margin-right: -10px;" class="localize" data-key="text.shortened">短縮形 {{index}}</span>
                    <span class="spacer"></span>
                    <CustomCheckbox :elementClasses="'ShorthandWritingToggle'" :elementID="'ShorthandWriting_'+ index" :disabled="false" @togglechange="onShorthandWritingToggle"></CustomCheckbox>
                </div>
            </div>
            <div class="FormEntry" v-else-if="shorthandWritingOptions.length == 1">
                <span style="margin-left: 10px; margin-right: -10px;" class="localize" data-key="text.shortened">短縮形 </span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'ShorthandWriting_0'" :disabled="false" @togglechange="onShorthandWritingToggle"></CustomCheckbox>
            </div>
            <div id="PublisherPackageNotationResult" class="FormEntry" v-if="ShowCopyrightNotation">
                <div style="width:220px;">
                    <span style="display: block;" class="ContentSubline localize" data-key="text.copyrightnotice.pkg">版権表記（PKG）:</span>
                </div>
                <span class="spacer"></span>
                <div class="PublisherPreview ThemeElement" Darkmode="DM_Publisher" id="PublisherPackageNotationResultText"></div>
            </div>
            <div id="PublisherProductNotationResult" class="FormEntry" v-if="ShowCopyrightNotation">
                <div style="width:220px;">
                    <span style="display: block;" class="ContentSubline localize" data-key="text.copyrightnotice.body">版権表記（本体）:</span>
                </div>
                <span class="spacer"></span>
                <div class="PublisherPreview ThemeElement" Darkmode="DM_Publisher" id="PublisherProductNotationResultText"></div>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.notation.position">表記位置:</span>
                <span class="spacer"></span>
                <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'NotationPositionInput'" v-model="NotationPosition" productNameInputLabel="表記位置:" @input-change="updateNotationPosition"/>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.notation.method">表記方法:</span>
                <span class="spacer"></span>
                <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'NotationMethodInput'" v-model="NotationMethod" productNameInputLabel="表記方法:" @input-change="updateNotationMethod"/>
            </div>
            <div class="FormEntry" v-if="ShowEngravingOption">
                <span :class="{'DisabledTextLabel': disableEngraveOption}" class="ContentSubline localize" data-key="text.engraving.title">刻印:</span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'HasEngraving'" :disabled="disableEngraveOption" v-model="hasEngraving" @togglechange="onUpdateSelectedHasEngraving"></CustomCheckbox>
            </div>  
            <div class="FormEntry" v-if="ShowSaleAbroadOption">
                <span :class="{'DisabledTextLabel': disableSalesAbroadOption}" class="ContentSubline localize" data-key="text.salesabroad.title">海外販売:</span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'IsSalesAbroad'" :disabled="disableSalesAbroadOption" v-model="isSaleAbroad" @togglechange="onUpdateSelectedIsSalesAbroad"></CustomCheckbox>
            </div>
        </div>

        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.setcontent">セット内容</div>
            <div v-if="ShowPlasticModelSetContent === true">
                <div class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.setcontent.label">セット内容:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'PM_SetContentSelection'" :options="pm_SetContentOptions" v-model="selPMSetContent" @change="onUpdateSelectedPMSetContent"></ManufacturerDropdown>
                </div>
            </div>
            <div v-if="ShowPlasticModelSetContent === false">
                <div class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.re.mainbody">本体に関して:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductBodySelection'" :options="productBodyOptions" v-model="selProductBody" @change="onUpdateSelectedProductBody"></ManufacturerDropdown>
                </div>
                <div class="FormEntry" v-if="ShowProductBodyInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'ProductBodyInput'" @input-change="updateProductBodyInput"/>
                </div>
                <div id="FoamingOptionContainer" class="FormEntry" v-if="ShowFoamingOption">
                    <span class="ContentSubline localize" data-key="text.foaming">発泡剤</span>
                    <span class="spacer"></span>
                    <CustomCheckbox :elementID="'HasFoamingSelection'" v-model="hasFoaming" @togglechange="onUpdateHasFoaming"></CustomCheckbox>
                </div>
                <div id="MainBodyFoamingContainer" class="FormEntry" v-if="hasFoaming">
                    <span class="ContentSubline localize" data-key="text.mainbody.foaming">本体発泡剤</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductBodyMaterialSelection'" :options="materialSelectionOptionsBody" v-model="selMaterialBodyID" @change="onUpdateSelectedBodyMaterial"></ManufacturerDropdown>
                </div>
                <div class="FormEntry" v-if="ShowProductBodyFoamingInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'ProductBodyFoamingInput'" @input-change="updateProductBodyFoamingInput"/>
                </div>
                <div id="TailAFoamingContainer" class="FormEntry" v-if="hasFoaming && selProductBodyID >= 1">
                    <span class="ContentSubline localize" data-key="text.tailA.foaming">尻尾A発泡剤</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductTailAMaterialSelection'" :options="materialSelectionOptionsTailA" v-model="selMaterialTailAID" @change="onUpdateSelectedTailAMaterial"></ManufacturerDropdown>
                </div>
                <div class="FormEntry" v-if="ShowProductTailAInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'ProductTailAInput'" @input-change="updateProductTailAInput"/>
                </div>
                <div id="TailBFoamingContainer" class="FormEntry" v-if="hasFoaming && selProductBodyID >= 2">
                    <span class="ContentSubline localize" data-key="text.tailB.foaming">尻尾B発泡剤</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductTailBMaterialSelection'" :options="materialSelectionOptionsTailB" v-model="selMaterialTailBID" @change="onUpdateSelectedTailBMaterial"></ManufacturerDropdown>
                </div>
                <div class="FormEntry" v-if="ShowProductTailBInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'ProductTailBInput'" @input-change="updateProductTailBInput"/>
                </div>
                <div class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.accessory">付属品:</span>
                    <span class="spacer"></span>
                    <CustomCheckbox :elementID="'HasAccessoriesSelection'" v-model="hasAccessories" @togglechange="onUpdateHasAccessories"></CustomCheckbox>
                </div>
                <div id="AccessoryContainer" v-if="hasAccessories === true" class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.re.accessory">付属品について:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'ProductAccessorySelection'" :options="productAccessoryOptions" v-model="selProductAccessory" @change="onUpdateSelectedProductAccessory"></ManufacturerDropdown>
                </div>
                <div class="FormEntry" v-if="ShowAccessorieInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :elementID="'AccessoriesInput'" @input-change="updateAccessoriesInput"/>
                </div>
                <div id="InstructionsContainer" v-if="hasAccessories === true" class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.instructions.title">取扱説明書:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="false" :elementID="'ProductInstructionSelection'" :defaultSelect="0" :options="instructionSheetOptions" v-model="selInstructionSheetOption" @change="onUpdateSelectedInstructionSheetOption"></ManufacturerDropdown>
                </div>
                <div id="PowerUnitContainer" v-if="hasAccessories === true" class="FormEntry">
                    <span class="ContentSubline localize" data-key="text.powerunit.spec.title">電源ユニット仕様:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="false" :elementID="'PowerSpecSelection'" :options="powerSpecOptions" v-model="selPowerSpec" @change="onUpdateSelectedPowerSpec"></ManufacturerDropdown>
                </div>
                <div id="CustomPowerUnitContainer" class="FormEntry" v-if="ShowPowerSpecInput">
                    <span class=""></span>
                    <span class="spacer"></span>
                    <span style="display: contents;" class="localize" data-key="text.kinds.title">種類:</span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :parentElementID="'PowerSpecInputContainer'" :elementID="'PowerSpecInput'" @input-change="updatePowerSpecInput"/>
                    <span style="display: contents;" class="localize" data-key="text.quantity.title">数量:</span>
                    <TextInput class="ContentSubline ThemeElement" Darkmode="DM_Input" :parentElementID="'PowerSpecAmountInputContainer'" :elementID="'PowerSpecAmountInput'" @input-change="updatePowerSpecAmountInput"/>
                </div>
                <div id="PedestalMaterialSelectionContainer" class="FormEntry" v-if="ShowPodestMaterialSelection">
                    <span class="ContentSubline localize" data-key="text.pedestal.title">台座:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="false" :elementID="'PodestMaterialSelection'" :options="podestMaterialOptions" v-model="selPodestMaterialID" @change="onUpdateSelectedPodestMaterial"></ManufacturerDropdown>
                </div>
                <div id="SupportPartsMaterialSelectionContainer" class="FormEntry" v-if="ShowSupportPartsMaterialSelection">
                    <span class="ContentSubline localize" data-key="text.supportparts.title">支えパーツ:</span>
                    <span class="spacer"></span>
                    <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="false" :elementID="'SupportPartsMaterialSelection'" :options="supportPartsMaterialOptions" v-model="selSupportPartsMaterialID" @change="onUpdateSelectedSupportPartsMaterial"></ManufacturerDropdown>
                </div>
            </div>
            
        </div>
        <button class="Button DisabledButton ButtonGreen NextPrevBtn localize" :disabled="true" data-key="text.back" id="BackBtn" @click="GoBackStep">戻る</button>
        <button class="Button DisabledButton ButtonGreen NextPrevBtn localize" :disabled="true" data-key="text.next" id="NextBtn" @click="GoToNextStep">次へ</button>
    </div>
</template>
<script>
import TextInput from './components/TextInput.vue'
import ManufacturerDropdown from './components/ManufacturerDropdown.vue';
import CustomCheckbox from './components/CustomCheckbox.vue';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import {LicensingNotationsData} from './LicensingNotations.js';
import { InitDarkmode, ApplyDarkmode } from './Darkmode';
import { LocalizationManager } from './Localization.js';
import axios from 'axios';

export default
{
    components:{
        TextInput,
        ManufacturerDropdown,
        CustomCheckbox,
    },
    computed:{
        ...mapGetters(['getStep1Data','getStep2Data','getStep3Data']),
        Step1Data(){
            return this.getStep1Data;
        },
        Step2Data(){
            return this.getStep2Data;
        },
        Step3Data(){
            return this.getStep3Data;
        }
    },
    mounted()
    {
        document.title = "EZ-SPEC-Step 3";

        InitDarkmode();

        if (this.Step1Data == null)
        {
            this.$router.push('/');
            return;
        }

        if (this.Step2Data==null)
        {
            this.$router.push('/step2');
            return;
        }

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }

        LocalizationManager.RegisterOnLanguageChangedListener(this.UpdateDataTranslation);

        ApplyDarkmode();
        LocalizationManager.TranslatePage();

        if (this.Step1Data.ProductSpecificationID === 4)
        {
            this.ShowPlasticModelSetContent = true;
            this.selInstructionSheetOptionID = 0;
            this.selInstructionSheetOption = "text.with";

            this.rightSideOutput = [];
            this.rightSideOutput.push('text.polystyrene');
            this.rightSideOutputMaterial = undefined;

            console.log("SET INSTRUCTION SHEET DEFAULT FOR PLASTIC MODEL KITS");

            this.$nextTick(()=>{this.LoadSetContent();});
        }
        else
        {
            this.ShowPlasticModelSetContent = false;
        }

        this.GetProductAccessories((success) =>
        {
            if (success == false)
            {
                alert("Could not retrieve product accessories data from server!\nサーバーからデータを取得できませんでした。(1)")
                this.IsInitialized = true;
                ApplyDarkmode();
                this.EnablePageButtons();
                LocalizationManager.TranslatePage();
                return;
            }

            this.GetCharacterTypeOptions((success) =>
            {
                if (success == false)
                {
                    alert("Could not retrieve character type data from server!\nサーバーからデータを取得できませんでした。(2)")
                }
            });

            const event = new Event('change', { bubbles: true });
            event.PreventUpdate = true;
            const inpEvent = new Event('input', {bubbles: true });
            inpEvent.PreventUpdate = true;

            console.log("Start loading step 1 data");

            if (this.Step1Data.ProductSpecificationID === 4)
            {
                if (this.Step1Data.CopyrightSourceID === 1)
                {
                    this.isPlexItem = true;
                    this.isPlexSelectionDisabled = true;
                }
                else
                {
                    this.isPlexItem = false;
                    this.ShowIsPlexItem = false;
                }
                
                this.showProductCharacterOptions = false;
                this.showShinUltramanCheckbox = false;

                setTimeout(()=>{
                    var inp = document.getElementById("NotationPositionInput");
                    inp.disabled = true;
                    if (inp.classList.contains('localize') == false)
                    {
                        inp.classList.add('localize');
                        inp.setAttribute('data-key', 'text.runner.each');
                    }
                    inp.value = LocalizationManager.GetTranslation('text.runner.each');
                    inp.dispatchEvent(inpEvent);
                    this.Key_NotationPosition = 'text.runner.each';

                    inp = document.getElementById("NotationMethodInput");
                    inp.disabled = true;
                    if (inp.classList.contains('localize') == false)
                    {
                        inp.classList.add('localize');
                        inp.setAttribute('data-key', 'text.engraving');
                    }
                    inp.value = LocalizationManager.GetTranslation('text.engraving');
                    inp.dispatchEvent(inpEvent);
                    this.Key_NotationMethod = 'text.engraving';
                }, 50);
            }
            else
            {
                switch(this.Step1Data.CopyrightSourceID)
                {
                    case 1:
                        this.isPlexItem = true;
                        this.isPlexSelectionDisabled = true;
                        this.showProductCharacterOptions = true;
                        this.ShowEngravingOption = false;
                        this.disableEngraveOption = true;
                        this.hasEngraving = false;
                        this.ShowSaleAbroadOption = false;
                        this.disableSalesAbroadOption = true;
                        this.isSaleAbroad = false;
                    break;

                    case 4:
                        this.isPlexItem = true;
                        this.isPlexSelectionDisabled = true;
                        this.showProductCharacterOptions = true;
                        this.ShowEngravingOption = false;
                        this.disableEngraveOption = true;
                        this.hasEngraving = false;
                        this.ShowSaleAbroadOption = false;
                        this.disableSalesAbroadOption = true;
                        this.isSaleAbroad = false;
                    break;

                    case 2:
                        this.showShinUltramanCheckbox = true;
                        this.showProductCharacterOptions = false;
                        this.ShowEngravingOption = true;
                        this.disableEngraveOption = false;
                        this.ShowSaleAbroadOption = true;
                        this.disableSalesAbroadOption = false;
                    break;

                    case 3:
                        this.isPlexItem = false;
                        this.isPlexSelectionDisabled = true;
                        this.showProductCharacterOptions = true;
                        this.ShowEngravingOption = false;
                        this.disableEngraveOption = true;
                        this.hasEngraving = false;
                        this.ShowSaleAbroadOption = false;
                        this.disableSalesAbroadOption = true;
                        this.isSaleAbroad = false;
                        console.log("Should disable isPlexSelection (KADOKAWA)");
                    break;

                    default:
                        this.disableEngraveOption = false;
                        this.disableSalesAbroadOption = false;
                        console.log("Copyright Source: " + this.Step1Data.CopyrightSource);
                    break;
                }
            }
            
            var target = document.getElementById("IsPlexSelection");
            if (target != null)
            {
                target.checked = this.isPlexItem;
                target.dispatchEvent(event);
            }

            if (this.Step3Data == null)
            {
                // Wait for the next render tick because
                // we may have new elements after loading
                // some previous step data
                this.$nextTick(() =>
                {
                    this.IsInitialized = true;
                    ApplyDarkmode();
                    this.LicensingData = this.GetProductLicensingData();
                    this.UpdatePublisherLicensing();
                    this.EnablePageButtons();
                    LocalizationManager.TranslatePage();
                    console.log("We did not have step 3 data yet.");
                });

                return;
            }

            // Continue here if we have step 3 data available

            this.LoadStep3Data();

            // For now we just set the default plastic model values again
            if (this.Step1Data.ProductSpecificationID === 4)
            {
                this.ShowPlasticModelSetContent = true;
                this.selInstructionSheetOptionID = 0;
                this.selInstructionSheetOption = "text.with";

                this.rightSideOutput = [];
                this.rightSideOutput.push('text.paper');
                this.rightSideOutputMaterial = undefined;
            }

            this.GetPowerSpecOptions((success) => 
            {
                if (success == false)
                {
                    alert("Could not retrieve power spec data from server!\nサーバーからデータを取得できませんでした。(3)")
                    this.IsInitialized = true;
                    ApplyDarkmode();
                    this.LicensingData = this.GetProductLicensingData();
                    this.UpdatePublisherLicensing();
                    this.EnablePageButtons();
                    LocalizationManager.TranslatePage();
                    return;
                }

                this.UpdateProductBodyMaterialSelection();
                console.log("[start of mounted] selProductAccessoryID: " + this.Step3Data.ProductAccessoryID);

                target = document.getElementById("IsPlexSelection");
                if (target != null)
                {
                    target.checked = this.Step3Data.IsPlexItem;
                    this.isPlexItem = target.checked;
                    if (this.isPlexItem == false &&
                        this.isSaleAbroad == false &&
                        this.hasEngraving == false)
                    {
                        this.showShinUltramanCheckbox = false;
                    }
                    target.dispatchEvent(event);
                }

                if (this.Step1Data.ProductSpecificationID !== 4)
                {
                    target = document.getElementById("NotationPositionInput");
                    if (target != null)
                    {
                        target.value = this.NotationPosition;
                    }
                    
                    target = document.getElementById("NotationMethodInput");
                    if (target != null)
                    {
                        target.value = this.NotationMethod;
                    }
                }

                target = document.getElementById("ProductBodySelection");
                if (target != null)
                {
                    let index = this.GetOptionIndexOfID(this.productBodyOptions, this.selProductBodyID);
                    target.selectedIndex = index;
                    target.dispatchEvent(event);
                }

                target = document.getElementById("HasAccessoriesSelection");
                if (target != null)
                {
                    target.checked = this.Step3Data.HasProductAccessory;
                    target.dispatchEvent(event);
                }

                this.$nextTick(()=>
                {
                    try
                    {
                        var bodyInput = document.getElementById("ProductBodyInput");
                        if (bodyInput != null)
                        {
                            bodyInput.value = this.inpProductBody;
                        }

                        this.UpdateLeftSideOutput();
                        this.UpdateProductInstructionSelection();

                        target = document.getElementById("IsShinUltramanSelection");
                        if (target != null)
                        {
                            target.checked = this.isShinUltraman;
                            target.dispatchEvent(event);
                        }

                        if (this.ShowEngravingOption == true)
                        {
                            target = document.getElementById("HasEngraving");
                            if (target != null)
                            {
                                target.checked = this.hasEngraving;
                                target.dispatchEvent(event);
                            }
                        }

                        if (this.ShowSaleAbroadOption == true)
                        {
                            target = document.getElementById("IsSalesAbroad");
                            if (target != null)
                            {
                                target.checked = this.isSaleAbroad;
                                target.dispatchEvent(event);
                            }
                        }

                        target = document.getElementById("HasFoamingSelection");
                        if (target != null)
                        {
                            target.checked = this.hasFoaming;
                            target.dispatchEvent(event);
                        }

                        target = document.getElementById("ProductAccessorySelection");
                        if (target != null)
                        {
                            console.log("Sel ProductAccessoryID ind: " + this.selProductAccessoryID);
                            
                            console.log("NOT DONE HERE!");
                            console.log('Check product accessory options', this.productAccessoryOptions);
                            for (var i = 0; i < this.productAccessoryOptions.length; i++)
                            {
                                if (this.productAccessoryOptions[i].value == this.selProductAccessoryID)
                                {
                                    target.selectedIndex = i;
                                    break;
                                }
                            }

                            target.dispatchEvent(event);
                        }

                        var accessoriesInput = document.getElementById("AccessoriesInput");
                        if (accessoriesInput != null)
                        {
                            accessoriesInput.value = this.inpAccessories;
                        }

                        target = document.getElementById("ProductBodyMaterialSelection");
                        if (target != null)
                        {
                            let index = this.GetOptionIndexOfID(this.materialSelectionOptionsBody, this.selMaterialBodyID);
                            target.selectedIndex = index;
                            target.dispatchEvent(event);
                        }

                        target = document.getElementById("ProductTailAMaterialSelection");
                        if (target != null)
                        {
                            let index = this.GetOptionIndexOfID(this.materialSelectionOptionsTailA, this.selMaterialTailAID);
                            target.selectedIndex = index;
                            target.dispatchEvent(event);
                        }

                        target = document.getElementById("ProductTailBMaterialSelection");
                        if (target != null)
                        {
                            let index = this.GetOptionIndexOfID(this.materialSelectionOptionsTailB, this.selMaterialTailBID);
                            target.selectedIndex = index;
                            target.dispatchEvent(event);
                        }

                        this.$nextTick(()=>
                        {
                            try 
                            {
                                target = document.getElementById("ProductBodyFoamingInput");
                                if (target != null)
                                {
                                    target.value = this.inpMaterialBody;
                                }

                                target = document.getElementById("ProductTailAInput");
                                if (target != null)
                                {
                                    target.value = this.inpMaterialTailA;
                                }

                                target = document.getElementById("ProductTailBInput");
                                if (target != null)
                                {
                                    target.value = this.inpMaterialTailB;
                                }

                                target = document.getElementById("PodestMaterialSelection");
                                if (target != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.podestMaterialOptions, this.selPodestMaterialID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("SupportPartsMaterialSelection");
                                if (target != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.supportPartsMaterialOptions, this.selSupportPartsMaterialID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("ProductInstructionSelection");
                                if (target != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.instructionSheetOptions, this.selInstructionSheetOptionID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("PowerSpecSelection");
                                if (target != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.powerSpecOptions, this.selPowerSpecID);
                                    target.selectedIndex = index;
                                    console.log('(ON LOAD STEP) SEL POWER SPEC', this.selPowerSpecID);
                                    if (this.selPowerSpecID > -1)
                                    {
                                        this.SetPowerSpecInformation();
                                    }
                                    else{
                                        this.ShowPowerSpecInput = true;
                                    }
                                    target.dispatchEvent(event);

                                    this.$nextTick(()=>
                                    {
                                        target = document.getElementById("PowerSpecInput");
                                        if (target != null)
                                        {
                                            target.value = this.Step3Data.InpBattery;
                                            target.dispatchEvent(event);
                                        }

                                        target = document.getElementById("PowerSpecAmountInput");
                                        if (target != null)
                                        {
                                            target.value = this.Step3Data.InpBatteryAmount;
                                            target.dispatchEvent(event);
                                        }
                                    });
                                }

                                target = document.getElementById("ProductCharacterTypeSelection");
                                if (target != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.productCharacterTypeOptions, this.selProductCharacterTypeID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                if (this.selShorthandWritingID >= 0)
                                {
                                    console.log("Load toggle "+ this.Step3Data.SelShorthandWritingID);
                                    this.$nextTick(()=>
                                    {
                                        let tID = "ShorthandWriting_" + this.selShorthandWritingID;
                                        let shorthandWritingToggles = document.getElementsByClassName("ShorthandWritingToggle");
                                        for (var i = 0; i < shorthandWritingToggles.length; i++)
                                        {
                                            if (shorthandWritingToggles[i].id != tID){
                                                shorthandWritingToggles[i].checked = false;
                                            }
                                            else{
                                                shorthandWritingToggles[i].checked = true;
                                            }
                                        }

                                        this.LicensingData = this.GetProductLicensingData();
                                        this.UpdatePublisherLicensing();

                                        this.IsInitialized = true;
                                        this.EnablePageButtons();
                                        LocalizationManager.TranslatePage();
                                        ApplyDarkmode();
                                    });
                                }
                                else
                                {
                                    console.log("No toggle checked " + this.Step3Data.SelShorthandWritingID);
                                    this.LicensingData = this.GetProductLicensingData();
                                    this.UpdatePublisherLicensing();

                                    this.IsInitialized = true;
                                    this.EnablePageButtons();
                                    LocalizationManager.TranslatePage();
                                    ApplyDarkmode();
                                }

                                console.log("[end of mounted] selProductAccessoryID: " + this.selProductAccessoryID);

                                this.CheckShowPodestAndSupportMaterial();

                                this.$nextTick(()=>
                                {
                                    console.log('Pedestal material options', this.podestMaterialOptions);
                                    console.log('Selected pedestal material', this.selPodestMaterialID);
                                    console.log('Support material options', this.supportPartsMaterialOptions);
                                    console.log('Selected support material', this.selSupportPartsMaterialID);

                                    target = document.getElementById('PodestMaterialSelection');
                                    if (target != null)
                                    {
                                        for(let m = 0; m < this.podestMaterialOptions.length; m++)
                                        {
                                            if (this.podestMaterialOptions[m].value == this.selPodestMaterialID)
                                            {
                                                target.selectedIndex = m;
                                                break;
                                            }
                                        }
                                        target.dispatchEvent(event);
                                    }

                                    this.$nextTick(()=>
                                    {
                                        target = document.getElementById('SupportPartsMaterialSelection');
                                        if (target != null)
                                        {
                                            for(let m = 0; m < this.supportPartsMaterialOptions.length; m++)
                                            {
                                                if (this.supportPartsMaterialOptions[m].value == this.selSupportPartsMaterialID)
                                                {
                                                    console.log("Found selected support parts mat");
                                                    target.selectedIndex = m;
                                                    break;
                                                }
                                            }
                                            target.dispatchEvent(event);
                                        }
                                        else{
                                            console.log("Support parts mat sel not found");
                                        } 
                                    });
                                });
                            }
                            catch (error)
                            {
                                console.error(error);
                            }
                        });
                    }
                    catch (error)
                    {
                        console.error(error);
                    }
                });
            });
        });
    },
    beforeUnmount(){
        LocalizationManager.UnregisterOnLanguageChangedListener(this.UpdateDataTranslation);
    },
    methods:
    {
        UpdateDataTranslation()
        {
            const inpEvent = new Event('input', { bubbles: true });

            var inp = document.getElementById("NotationPositionInput");
            inp.value = LocalizationManager.GetTranslation(inp.getAttribute('data-key'));
            inp.dispatchEvent(inpEvent);

            inp = document.getElementById("NotationMethodInput");
            inp.value = LocalizationManager.GetTranslation(inp.getAttribute('data-key'));
            inp.dispatchEvent(inpEvent);
        },
        EnablePageButtons()
        {
            var btn = document.getElementById("BackBtn");
            btn.disabled= false;
            btn.classList.remove("DisabledButton");

            btn = document.getElementById("NextBtn");
            btn.disabled= false;
            btn.classList.remove("DisabledButton");
        },
        GetProductLicensingData()
        {
            console.log("Available data", this.LicensingNotations);

            var debInfo = 
            {
                IsPlex: this.isPlexItem,
                HasEngraving: this.hasEngraving,
                IsSalesAbroad: this.isSaleAbroad,
                IsShinUltraman: this.isShinUltraman,
            }

            console.log("Look for settings",debInfo);
            console.log("Look for character type: " + this.selProductCharacterType + " ID: " + this.selProductCharacterTypeID);

            for (var i = 0; i < this.LicensingNotations.length; i++)
            {
                let copyrightHolder = this.LicensingNotations[i];
                if (copyrightHolder.CopyrightHolderID == this.Step1Data.CopyrightSourceID)
                {
                    let options = copyrightHolder.Options;
                    for(var j = 0; j < options.length; j++)
                    {
                        let character = options[j];
                        // Tsuburaya
                        if (copyrightHolder.CopyrightHolderID == 2)
                        {
                            //console.log("Tsuburaya character", character);
                            let plexResult = character.IsPlex == this.isPlexItem ? 1 : 0;
                            let engraveResult = -1;
                            let saleAbroadResult = -1;
                            let shinUltramanResult = -1;

                            if (Object.prototype.hasOwnProperty.call(character, "HasEngraving") == true)
                            {
                                if (character.HasEngraving == this.hasEngraving)
                                {
                                    engraveResult = 1;
                                }
                                else
                                {
                                    engraveResult = 0;
                                }
                            }

                            if (Object.prototype.hasOwnProperty.call(character, "IsSaleAbroad") == true)
                            {
                                if (character.IsSaleAbroad == this.isSaleAbroad)
                                {
                                    saleAbroadResult = 1;
                                }
                                else
                                {
                                    saleAbroadResult = 0;
                                }
                            }

                            if (Object.prototype.hasOwnProperty.call(character, "IsShinUltraman") == true)
                            {
                                if (character.IsShinUltraman == this.isShinUltraman)
                                {
                                    shinUltramanResult = 1
                                }
                                else
                                {
                                    shinUltramanResult = 0;
                                }
                            }

                            if ((engraveResult == 1 || engraveResult == -1) &&
                                (saleAbroadResult == 1 || saleAbroadResult == -1) &&
                                (shinUltramanResult == 1 || shinUltramanResult == -1) &&
                                plexResult == 1)
                            {
                                //console.log("[GetProductLicensingData] Found character", character);
                                return character;
                            }
                        }
                        else if (character.CharacterTypeID == this.selProductCharacterTypeID)
                        {
                            //console.log("[GetProductLicensingData] Found character", character);
                            return character;
                        }
                    }
                }
            }
        },
        UpdatePublisherLicensing()
        {
            //console.trace("[UpdatePublisherLicensing]");
            console.log("[UpdatePublisherLicensing]");
            
            if (this.Step1Data.CopyrightSourceID != 2 &&
                (this.selProductCharacterTypeID < 0 ||
                this.LicensingData == null))
            {
                    console.log("No licensing data " + this.Step1Data.CopyrightSource);
                    return;
            }

            this.ShowCopyrightNotation = true

            this.$nextTick(()=>
            {
                const event = new Event('change', { bubbles: true });
                let pubPackageLicensingContainer = document.getElementById("PublisherPackageNotationResult");
                let pubProductLicensingContainer = document.getElementById("PublisherProductNotationResult");

                let year = this.GetYear();
                let dateCode = this.GetDateCode();
                let madeIn = this.GetMadeIn(false);
                let madeInShort = this.GetMadeIn(true);

                /*
                    PACKAGE LICENSING NOTATION
                */
                if (pubPackageLicensingContainer != null){
                    pubPackageLicensingContainer.style.visibility = "visible";

                    let pkgLicensing = this.LicensingData.Package.Licensing;
                    // pkgLicensing = pkgLicensing.replaceAll("{madeIn}", madeIn);
                    pkgLicensing = this.ReplaceAll(pkgLicensing, "{madeIn}", madeIn);
                    // pkgLicensing = pkgLicensing.replaceAll("{getYear}", year);
                    pkgLicensing = this.ReplaceAll(pkgLicensing, "{getYear}", year);
                    // let htmlPkg = pkgLicensing.replaceAll("\n", "<br/>");
                    let htmlPkg = this.ReplaceAll(pkgLicensing, "\n", "<br/>");

                    let packageNotationElement = document.getElementById("PublisherPackageNotationResultText");
                    packageNotationElement.classList.add("PublisherPreviewVisible");
                    //packageNotationElement.style.backgroundColor = "#eee";

                    let publisherNotationElement = document.getElementById("PublisherNotation");
                    if (publisherNotationElement != null){
                        publisherNotationElement.remove();
                    }
                    // packageNotationElement.replaceChildren();

                    let row = document.createElement("div");
                    row.id = "PublisherNotation";
                    this.PublisherPackageNotation = pkgLicensing;
                    row.innerHTML = htmlPkg;
                    packageNotationElement.append(row);

                    pubPackageLicensingContainer.dispatchEvent(event);
                }

                /*
                    PRODUCT LICENSING NOTATION
                */
                if (pubProductLicensingContainer != null){
                    pubProductLicensingContainer.style.visibility = "visible";

                    console.log("[Update licensing] Shorthand writing:", this.selShorthandWritingID);

                    let prodLicensing = "";
                    if (this.selShorthandWritingID < 0){
                        prodLicensing = this.LicensingData.Product.Licensing;
                        // prodLicensing = prodLicensing.replaceAll("{madeIn}", madeIn);
                        prodLicensing = this.ReplaceAll(prodLicensing, "{madeIn}", madeIn);
                    }
                    else{
                        prodLicensing = this.shorthandWritingOptions[this.selShorthandWritingID];
                        // prodLicensing = prodLicensing.replaceAll("{madeInShort}", madeInShort);
                        prodLicensing = this.ReplaceAll(prodLicensing, "{madeInShort}", madeInShort);
                    }

                    // prodLicensing = prodLicensing.replaceAll("{dateCode}", dateCode);
                    prodLicensing = this.ReplaceAll(prodLicensing, "{dateCode}", dateCode);
                    // prodLicensing = prodLicensing.replaceAll("{getYear}", year);
                    prodLicensing = this.ReplaceAll(prodLicensing, "{getYear}", year);
                    // let htmlProd = prodLicensing.replaceAll("\n", "<br/>");
                    let htmlProd = this.ReplaceAll(prodLicensing, "\n", "<br/>");

                    let productNotationElement = document.getElementById("PublisherProductNotationResultText");
                    //productNotationElement.style.backgroundColor = "#eee";
                    productNotationElement.classList.add("PublisherPreviewVisible");

                    let publisherProductNotationElement = document.getElementById("ProductNotation");
                    if (publisherProductNotationElement != null){
                        publisherProductNotationElement.remove();
                    }
                    //productNotationElement.replaceChildren();
                    
                    let row = document.createElement("div");
                    row.id = "ProductNotation";
                    this.PublisherProductNotation = prodLicensing;
                    row.innerHTML = htmlProd;
                    productNotationElement.append(row);

                    pubProductLicensingContainer.dispatchEvent(event);

                    ApplyDarkmode();
                    LocalizationManager.TranslatePage();
                }
            });
        },
        EscapeRegExp(string){
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        },
        ReplaceAll(string, match, replacement){
            return string.replace(new RegExp(this.EscapeRegExp(match), 'g'), ()=>replacement);
        },
        onUpdateHasFoaming(event)
        {
            if (event.PreventUpdate != undefined)
            {
                return;
            }

            this.hasFoaming = event.target.checked;

            if (this.hasFoaming == false)
            {
                this.selFoamingID = -1;
            }

            this.UpdateProductBodyMaterialSelection();

            this.$nextTick(()=>
            {
                ApplyDarkmode();
                LocalizationManager.TranslateElement(document.getElementById("MainBodyFoamingContainer"));
                LocalizationManager.TranslateElement(document.getElementById("TailAFoamingContainer"));
                LocalizationManager.TranslateElement(document.getElementById("TailBFoamingContainer"));
            });
        },
        onUpdateSelectedBodyMaterial(event){
            if (event == null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            console.log("selected " + event.target.selectedIndex);
            this.selMaterialBodyID = event.target.selectedIndex;
            console.log("check1 " + this.selMaterialBodyIndex);
            if (this.selMaterialBodyID != this.materialSelectionOptionsBody.length - 1)
            {
                if (event.PreventUpdate == undefined)
                {
                    this.selMaterialBody = this.materialSelectionOptionsBody[this.selMaterialBodyID].label;    
                }
                
                console.log("check333: " + this.selMaterialBody);
                this.ShowProductBodyFoamingInput = false;
            }
            // It's その他
            else
            {
                this.ShowProductBodyFoamingInput = true;
                this.selMaterialBody = '';
            }

            this.$nextTick(()=>{
                ApplyDarkmode();
            });
        },
        onUpdateSelectedTailAMaterial(event){
            if (event == null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selMaterialTailAID = event.target.selectedIndex;
            if (this.selMaterialTailAID != this.materialSelectionOptionsTailA.length - 1)
            {
                if (event.PreventUpdate == undefined)
                {
                    this.selMaterialTailA = this.materialSelectionOptionsTailA[this.selMaterialTailAID].label;
                }
                this.ShowProductTailAInput = false;
            }
            // It's その他
            else
            {
                this.ShowProductTailAInput = true;
            }

            this.UpdateRightSideOutput();

            this.$nextTick(()=>{
                ApplyDarkmode();
            });
        },
        onUpdateSelectedTailBMaterial(event){
            if (event == null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selMaterialTailBID = event.target.selectedIndex;
            if (this.selMaterialTailBID != this.materialSelectionOptionsTailB.length - 1)
            {
                if (event.PreventUpdate == undefined)
                {
                    this.selMaterialTailB = this.materialSelectionOptionsTailB[this.selMaterialTailBID].label;
                }
                this.ShowProductTailBInput = false;
            }
            // It's その他
            else
            {
                this.ShowProductTailBInput = true;
            }

            this.UpdateRightSideOutput();

            this.$nextTick(()=>{
                ApplyDarkmode();
            });
        },
        onUpdateHasAccessories(event)
        {
            if (event.PreventUpdate != undefined)
            {
                return;
            }

            this.hasAccessories = event.target.checked;

            if (this.hasAccessories == false)
            {
                console.log("RESET ACCESSORY ID");
                this.selProductAccessoryID = -1;
                this.selInstructionSheetOptionID = -1;
                this.selPowerSpecID = -1;
                this.ShowPowerSpecInput = false;
            }

            this.GetProductAccessories((success) =>
            {
                if (success == false)
                {
                    alert("Could not receive product accessories from server.\nサーバーからデータを取得できませんでした。(3)");
                }
            });

            this.$nextTick(()=>
            {
                var target = document.getElementById("ProductAccessorySelection");
                if (target != null)
                {
                    target.disabled = false;
                }

                this.UpdateProductInstructionSelection();
                LocalizationManager.TranslateElement(document.getElementById("AccessoryContainer"));
                LocalizationManager.TranslateElement(document.getElementById("InstructionsContainer"));
                LocalizationManager.TranslateElement(document.getElementById("PowerUnitContainer"));
                ApplyDarkmode();
            });
        },
        onUpdateSelectedPMSetContent(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }

            var op = event.target.options[event.target.selectedIndex];
            this.selPMSetContent = op.label;
            this.key_PMSetContent = op.locakey;
            this.selPMSetContentID = parseInt(op.value);

            this.leftSideOutput = [];

            if (this.selPMSetContentID === 1)
            {
                this.leftSideOutput.push('spec.mainbody.set.complete');
                this.leftSideOutput.push('spec.assemblyinstructions');
            }
            else if (this.selPMSetContentID === 2)
            {
                this.leftSideOutput.push('spec.mainbody.set.complete');
                this.leftSideOutput.push('spec.pedestal');
                this.leftSideOutput.push('spec.assemblyinstructions');
            }
        },
        onUpdateSelectedProductBody(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selProductBody = event.target.options[event.target.selectedIndex].label;
            this.selProductBodyID = event.target.selectedIndex;
            this.ShowProductBodyInput = event.target.selectedIndex == this.productBodyOptions.length - 1;
            this.ShowFoamingOption = event.target.selectedIndex >= 1;

            if (this.selProductBodyID == 0)
            {
                this.selMaterialTailA = '';
                this.selMaterialTailAID = -1;
                this.selMaterialTailB = '';
                this.selMaterialTailBID = -1;
            }
            else if (this.selProductBodyID == 1)
            {
                this.selMaterialTailB = '';
                this.selMaterialTailBID = -1;
            }
            
            if (event.PreventUpdate != undefined)
            {
                return;
            }

            var target = document.getElementById("ProductAccessorySelection");
            if (target != null)
            {
                target.disabled = false;
            }

            this.UpdateLeftSideOutput();
            this.UpdateProductInstructionSelection();
            this.UpdateProductBodyMaterialSelection();
            
            this.$nextTick(()=>
            {
                ApplyDarkmode();
                LocalizationManager.TranslateElement(document.getElementById("FoamingOptionContainer"));
            });
        },
        updateProductBodyInput(value){
            this.inpProductBody = value;
        },
        updateProductBodyFoamingInput(value){
            this.inpMaterialBody = value;
        },
        updateProductTailAInput(value){
            this.inpMaterialTailA = value;
        },
        updateProductTailBInput(value){
            this.inpMaterialTailB = value;
        },
        updatePowerSpecInput(value){
            this.inpBattery = value;
            this.SetPowerSpecInformation();
        },
        updatePowerSpecAmountInput(value)
        {
            this.inpBatteryAmount = value;
            this.SetPowerSpecInformation();
        },
        updateAccessoriesInput(value){
            if (value.PreventUpdate != undefined)
            {
                return;
            }
            this.inpAccessories = value;
        },
        UpdateProductInstructionSelection()
        {
            var target = document.getElementById("ProductInstructionSelection");
            if (target != null)
            {
                console.log("UPDATEPRODUCTIONINSTRUCTIONSELECTION");
                console.log("SET DEFAULT VALUE FOR ISTRUCTION SHEET OPTION SELECTION");
                // Set 有り because in most cases the
                // instruction sheet will be available
                this.selInstructionSheetOptionID = 0;
                target.selectedIndex = this.selInstructionSheetOptionID;

                console.log("instruction index: ", target.selectedIndex);
                console.log("Check instruction element", target);
                this.selInstructionSheetOption = target.selectedIndex >= 0 ? target.options[target.selectedIndex].locakey : "";
                this.selInstructionSheetOptionID = target.selectedIndex;

                const triggerEvent = new Event('change', { bubbles: true });
                target.dispatchEvent(triggerEvent);
            }
        },
        UpdateProductBodyMaterialSelection()
        {
            console.log("[UpdateProductBodyMaterialSelection] Start");
            console.log("[UpdateProductBodyMaterialSelection] Body iD: ", this.selProductBodyID);
            console.log("[UpdateProductBodyMaterialSelection] Has Foaming: ", this.hasFoaming);
            if (this.selProductBodyID === 0)
            {
                this.materialSelectionOptionsBody = 
                [
                    {label: "PVC", value: 0},
                    {label: "PVC, ABS", value: 1},
                    {label: "PVC, MBS", value: 2},
                    {label: "PVC, ABS, MBS", value: 3},
                    { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 4}
                ];
            }
            else if (this.selProductBodyID === 1)
            {
                if (this.hasFoaming === true)
                {
                    this.materialSelectionOptionsBody = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        {label: "PVC, MBS, PET", value: 3},
                        {label: "PVC, ABS, MBS, PET", value: 4},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 5}
                    ];

                    this.materialSelectionOptionsTailA = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                    ];
                }
                else
                {
                    this.materialSelectionOptionsBody = [
                        {label: "PVC", value: 0},
                        {label: "PVC, ABS", value: 1},
                        {label: "PVC, MBS", value: 2},
                        {label: "PVC, ABS, MBS", value: 3},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 4}
                    ];

                    this.materialSelectionOptionsTailA = [
                        {label: "PVC", value: 0},
                        {label: "PVC, ABS", value: 1},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 2}
                    ];
                }
            }
            else if (this.selProductBodyID === 2)
            {
                if (this.hasFoaming === true)
                {
                    this.materialSelectionOptionsBody = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        {label: "PVC, MBS, PET", value: 3},
                        {label: "PVC, ABS, MBS, PET", value: 4},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 5}
                    ];

                    this.materialSelectionOptionsTailA = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                    ];

                    this.materialSelectionOptionsTailB = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                    ];
                }
                else
                {
                    this.materialSelectionOptionsBody = [
                        {label: "PVC", value: 0},
                        {label: "PVC, ABS", value: 1},
                        {label: "PVC, MBS", value: 2},
                        {label: "PVC, ABS, MBS", value: 3},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 4}
                    ];

                    this.materialSelectionOptionsTailA = [
                        {label: "PVC", value: 0},
                        {label: "PVC, PET", value: 1},
                        {label: "PVC, ABS, PET", value: 2},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                    ];

                    this.materialSelectionOptionsTailB = [
                        {label: "PVC", value: 0},
                        {label: "PVC, ABS", value: 1},
                        { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 2}
                    ];
                }
            }
            // その他 was selected
            else if (this.selProductBodyID === this.productBodyOptions.length - 1)
            {
                this.materialSelectionOptionsBody = [
                    {label: "PVC", value: 0},
                    {label: "PVC, PET", value: 1},
                    {label: "PVC, ABS, PET", value: 2},
                    {label: "PVC, MBS, PET", value: 3},
                    {label: "PVC, ABS, MBS, PET", value: 4},
                    { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 5}
                ];

                this.materialSelectionOptionsTailA = [
                    {label: "PVC", value: 0},
                    {label: "PVC, PET", value: 1},
                    {label: "PVC, ABS, PET", value: 2},
                    { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                ];

                this.materialSelectionOptionsTailB = [
                    {label: "PVC", value: 0},
                    {label: "PVC, PET", value: 1},
                    {label: "PVC, ABS, PET", value: 2},
                    { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
                ];
            }

            console.log("New product body material selection",this.materialSelectionOptionsBody);
        },
        onUpdateSelectedProductAccessory(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            if (event.PreventUpdate != undefined)
            {
                return;
            }

            this.ShowAccessorieInput = event.target.selectedIndex == this.productAccessoryOptions.length - 1;
            
            console.log("[onUpdateSelectedProductAccessory] Set index: " + event.target.selectedIndex);
            this.selProductAccessory = event.target.options[event.target.selectedIndex].label;
            this.selProductAccessoryID = parseInt(event.target.options[event.target.selectedIndex].value);
            
            const triggerEvent = new Event('change', { bubbles: true });

            this.UpdateProductInstructionSelection();
            
            this.UpdateLeftSideOutput();
            this.UpdateRightSideOutput();

            var powerUnitSel = document.getElementById("PowerSpecSelection");
            powerUnitSel.selectedIndex = -1;
            powerUnitSel.dispatchEvent(triggerEvent);

            this.CheckShowPodestAndSupportMaterial();

            this.GetPowerSpecOptions((success) => 
            {
                if (success == false)
                {
                    alert("Could not retrieve power spec options from server!\nサーバーから電源ユニットオプションを取得できませんでした。(1)");
                    return;
                }

                setTimeout(()=>
                {
                    console.log("Automatically set USB-C");
                    // USB Cable
                    if (this.selProductAccessoryID == 13)
                    {
                        powerUnitSel.selectedIndex = 0;
                        powerUnitSel.dispatchEvent(triggerEvent);
                    }
                }, 10);
            });

            this.$nextTick(()=>
            {
                LocalizationManager.TranslateElement(document.getElementById('PedestalMaterialSelectionContainer'));
                LocalizationManager.TranslateElement(document.getElementById('SupportPartsMaterialSelectionContainer'));
                ApplyDarkmode();
            });
        },
        CheckShowPodestAndSupportMaterial()
        {
            console.log('(CheckShowPodestAndSupportMaterial) product accessory ID', this.selProductAccessoryID);

            if (this.selProductAccessoryID === 8)
            {
                this.ShowPodestMaterialSelection = true;
                this.podestMaterialOptions =
                [
                    { locakey: "text.pedestal.material.mbs", label: LocalizationManager.GetTranslation("text.pedestal.material.mbs"), value: 1},
                    { locakey: "text.pedestal.material.abs", label: LocalizationManager.GetTranslation("text.pedestal.material.abs"), value: 2},
                ];
                this.supportPartsMaterialOptions = [];
            }
            else if (this.selProductAccessoryID === 10 ||
                this.selProductAccessoryID === 11 ||
                this.selProductAccessoryID === 12)
            {
                this.ShowPodestMaterialSelection = true;
                this.ShowSupportPartsMaterialSelection = true;
                this.podestMaterialOptions =
                [
                    { locakey: "text.pedestal.material.mbs", label: LocalizationManager.GetTranslation("text.pedestal.material.mbs"), value: 1},
                    { locakey: "text.pedestal.material.abs", label: LocalizationManager.GetTranslation("text.pedestal.material.abs"), value: 2},
                ];
                this.supportPartsMaterialOptions =
                [
                    { locakey: "text.supportparts.material.mbs", label: LocalizationManager.GetTranslation("text.supportparts.material.mbs"), value: 3},
                    { locakey: "text.supportparts.material.abs", label: LocalizationManager.GetTranslation("text.supportparts.material.abs"), value: 4},
                    { locakey: "text.supportparts.material.pvc", label: LocalizationManager.GetTranslation("text.supportparts.material.pvc"), value: 5},
                ];
            }
            else
            {
                this.ShowPodestMaterialSelection = false;
                this.ShowSupportPartsMaterialSelection = false;
                this.supportPartsMaterialOptions = [];
                this.podestMaterialOptions = [];
            }
        },
        onUpdateSelectedIsPlexItem(event)
        {
            if (event==null)
            {
                return;
            }
            
            this.isPlexItem = event.target.checked;

            switch(this.Step1Data.CopyrightSourceID)
            {
                case 2:
                    if (this.isPlexItem == false &&
                        this.hasEngraving == false &&
                        this.isSaleAbroad == false &&
                        this.Step1Data.ProductSpecificationID !== 4)
                    {
                        this.showShinUltramanCheckbox = true;
                    }
                    else
                    {
                        this.showShinUltramanCheckbox = false;
                    }
                    break;
            }

            if (event.PreventUpdate == undefined)
            {
                this.SetLicensingData(this.GetProductLicensingData());
                this.UpdatePublisherLicensing();
            }
            
            this.$nextTick(()=>
            {
                ApplyDarkmode();
            });
        },
        SetLicensingData(data)
        {
            this.LicensingData = data;
            console.log("Updated licensing data", this.LicensingData);
        },
        onUpdateSelectedProductCharacterType(event)
        {
            if (event == null)
            {
                return;
            }

            if (event.target.selectedIndex < 0)
            {
                return;
            }

            this.selProductCharacterType = event.target.options[event.target.selectedIndex].label;
            this.selProductCharacterTypeID = event.target.options[event.target.selectedIndex].value;

            // Check font size
            // default font size 1.3rem
            let fontSize = 1.3; // rem
            let selectEl = document.getElementById("ProductCharacterTypeSelection");
            selectEl.style.fontSize = fontSize + "rem";
            let selectWidth = selectEl.offsetWidth;
            let tmpWidth = this.GetLabelWidth(this.selProductCharacterType, 1.3, "rem");
            
            while (tmpWidth > selectWidth && fontSize > 0.7)
            {
                fontSize -= 0.1;
                selectEl.style.fontSize = fontSize + "rem";

                tmpWidth = this.GetLabelWidth(this.selProductCharacterType, fontSize, "rem");
            }
            
            this.SetLicensingData(this.GetProductLicensingData());

            if (event.PreventUpdate == undefined)
            {
                console.log("RESET SEL SHORTHANDWRITING");
                this.selShorthandWritingID = -1;
            }

            this.shorthandWritingOptions = this.LicensingData.Product.Shorthand;

            if (event.PreventUpdate == undefined)
            {
                this.UpdatePublisherLicensing();
            }
        },
        GetLabelWidth(label, fontSize, fontSizeType)
        {
            var tmp = document.createElement("span");
            tmp.style.fontSize = fontSize + fontSizeType;
            tmp.style.visibility = "hidden";
            tmp.style.whiteSpace = "nowrap";
            tmp.textContent = label;
            document.body.appendChild(tmp);
            let tmpWidth = tmp.offsetWidth;
            document.body.removeChild(tmp);
            return tmpWidth;
        },
        updateNotationPosition(value)
        {
            this.NotationPosition = value;
        },
        updateNotationMethod(value)
        {
            this.NotationMethod = value;
        },
        onUpdateSelectedIsShinUltraman(event)
        {
            if (event==null)
            {
                return;
            }

            this.isShinUltraman = event.target.checked;

            if (event.PreventUpdate == undefined)
            {
                this.SetLicensingData(this.GetProductLicensingData());
                this.UpdatePublisherLicensing();
            }
        },
        onShorthandWritingToggle(event)
        {
            if (event == null)
            {
                return;
            }
            let newShorthandWriting = event.target.id.substring(event.target.id.lastIndexOf('_') + 1);
            console.log("Checked shorthand toggle: " + newShorthandWriting);
            if (newShorthandWriting == this.selShorthandWritingID)
            {
                this.selShorthandWritingID = -1;
            }
            else
            {
                this.selShorthandWritingID = parseInt(newShorthandWriting, 10);
            }
            
            let shorthandWritingToggles = document.getElementsByClassName("ShorthandWritingToggle");
            for (var i = 0; i < shorthandWritingToggles.length; i++)
            {
                if (shorthandWritingToggles[i].id != event.target.id)
                {
                    shorthandWritingToggles[i].checked = false;
                }
            }

            console.log("Update licensing " + this.selShorthandWritingID);

            if (event.PreventUpdate == undefined)
            {
                this.SetLicensingData(this.GetProductLicensingData());
                this.UpdatePublisherLicensing();
            }
        },
        onUpdateSelectedHasEngraving(event)
        {
            if (event==null)
            {
                return;
            }

            this.hasEngraving = event.target.checked;

            switch(this.Step1Data.CopyrightSourceID)
            {
                case 2:
                    if (this.isPlexItem == false &&
                        this.hasEngraving == false &&
                        this.isSaleAbroad == false)
                    {
                        this.showShinUltramanCheckbox = true;
                    }
                    else
                    {
                        this.showShinUltramanCheckbox = false;
                    }
                    break;
            }

            if (event.PreventUpdate == undefined)
            {
                this.SetLicensingData(this.GetProductLicensingData());
                this.UpdatePublisherLicensing();
            }
        },
        onUpdateSelectedIsSalesAbroad(event)
        {
            if (event==null)
            {
                return;
            }
            
            this.isSaleAbroad = event.target.checked;

            switch(this.Step1Data.CopyrightSourceID)
            {
                case 2:
                    if (this.isPlexItem == false &&
                        this.hasEngraving == false &&
                        this.isSaleAbroad == false)
                    {
                        this.showShinUltramanCheckbox = true;
                    }
                    else
                    {
                        this.showShinUltramanCheckbox = false;
                    }
                    break;
            }

            if (event.PreventUpdate == undefined)
            {
                this.SetLicensingData(this.GetProductLicensingData());
                this.UpdatePublisherLicensing();
            }
        },
        onUpdateSelectedInstructionSheetOption(event)
        {
            if (event.target.selectedIndex >= 0)
            {
                console.log("CHANGED SELECTED INSTRUCTION SHEET OPTION");
                this.selInstructionSheetOption = event.target.options[event.target.selectedIndex].locakey;
                this.selInstructionSheetOptionID = event.target.selectedIndex;
            }
        },
        onUpdateSelectedPowerSpec(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selPowerSpecID = parseInt(event.target.options[event.target.selectedIndex].value);
            console.log("SELECTED POWER SPEC", this.selPowerSpecID);
            if (this.selPowerSpecID === -1)
            {
                console.log("その他 - Show battery input");
                this.ShowPowerSpecInput = true;
                this.selPowerSpec = '';
                this.selPowerSpecAmount = '';
            }
            else
            {
                console.log("Hide battery input");
                this.ShowPowerSpecInput = false;
                this.SetPowerSpecInformation();
            }

            this.$nextTick(()=>
            {
                ApplyDarkmode();
                LocalizationManager.TranslateElement(document.getElementById("CustomPowerUnitContainer"));
            });
        },
        SetPowerSpecInformation()
        {
            switch (this.selPowerSpecID) 
            {
                case 1:
                    this.selPowerSpec = "/";
                    this.selPowerSpecAmount = "/"
                break;

                case 2:
                    this.selPowerSpec = "単3電池";
                    this.selPowerSpecAmount = "2";
                break;

                case 3:
                    this.selPowerSpec = "単4電池";
                    this.selPowerSpecAmount = "2";
                break;

                case 4:
                    this.selPowerSpec = "単4電池";
                    this.selPowerSpecAmount = "4";
                break;

                case 5:
                    this.selPowerSpec = "LR44";
                    this.selPowerSpecAmount = "2";
                break;

                case 6:
                    this.selPowerSpec = "USB-C";
                    this.selPowerSpecAmount = "1";
                break;

                case -1:
                    var batteryNameInput = document.getElementById("PowerSpecInput");
                    var batteryAmountInput = document.getElementById("PowerSpecAmountInput");
                    this.selPowerSpec = batteryNameInput.value;
                    this.selPowerSpecAmount = batteryAmountInput.value;
                break;

                default:
                    console.log("[SetPowerSpecInformation] Power Spec ID not implemented: " + this.selPowerSpecID);
                    this.selPowerSpec = "/";
                    this.selPowerSpecAmount = "/";
                break;
            }
        },
        NeedsDateCode()
        {
            switch (this.Step1Data.CopyrightSourceID)
            {
                case 1:
                    return true;

                case 2:
                    if (this.isPlexItem == true)
                    {
                        return true;
                    }
                    break;

                case 3:
                    return false;

                case 4:
                    if (this.isPlexItem == true)
                    {
                        return true;
                    }
                break;
            }

            return false;
        },
        GetMadeIn(shorthand)
        {
            switch(this.Step1Data.CopyrightSourceID)
            {
                case 2:
                    if (this.isPlexItem == false){
                        return "";
                    }
                    break;

                case 3:
                    return "";

                case 4:
                    if (this.isPlexItem == false)
                    {
                        return "";
                    }
            }

            let plex = this.isPlexItem ? shorthand ? "P." : "PLEX " : "";

            switch (this.Step1Data.ManufacturerID)
            {
                case 3:
                    return plex + (shorthand ? "BANGLADESH" : "MADE IN BANGLADESH");
            }

            return plex + (shorthand ? "CHINA" : "MADE IN CHINA");
        },
        GetMadeInOnly()
        {
            switch (this.Step1Data.ManufacturerID)
            {
                case 3:
                    return "MADE IN BANGLADESH";

                default:
                    return "MADE IN CHINA";
            }
        },
        GetYear()
        {
            let shippingDate = new Date(this.Step1Data.ShippingDate);
            return shippingDate.getFullYear().toString().slice(2);
        },
        GetDateCode()
        {
            let shippingDate = new Date(this.Step1Data.ShippingDate);
            let day = shippingDate.getDate();
            let month = shippingDate.getMonth()+1;
            let year = shippingDate.getFullYear();
            day = day.toString().padStart(2, '0');
            month = month.toString().padStart(2, '0');
            year = year.toString();
            year = '0' + year.slice(year.length - 1);

            let n1 = day.toString()[1];
            let n2 = day.toString()[0];
            let n3 = month.toString()[1];
            let n4 = month.toString()[0];
            let n5 = year.toString()[1];

            //return month+""+day+""+year;
            return n1 + n2 + n3 + n4 + n5;
        },
        UpdateLeftSideOutput()
        {
            this.leftSideOutput = [];
            
            if (this.selProductBodyID == -1)
            {
                // dont do anything here yet
                console.log("(UpdateLeftSideOutput) Selected その他");
            }
            else
            {
                if (this.selProductBodyID >= 0)
                {
                    // this.leftSideOutput.push("本体");
                    this.leftSideOutput.push('spec.mainbody');
                }
                if (this.selProductBodyID >= 1){
                    // this.leftSideOutput.push("尻尾A");
                    this.leftSideOutput.push('spec.tailA');
                }
                if (this.selProductBodyID >= 2){
                    // this.leftSideOutput.push("尻尾B");
                    this.leftSideOutput.push('spec.tailB');
                }
            }

            switch (this.selProductAccessoryID)
            {
                case 1:
                    // this.leftSideOutput.push("電源ユニット");
                    // this.leftSideOutput.push("取扱説明書");
                    this.leftSideOutput.push('spec.batteryunit');
                    this.leftSideOutput.push('spec.instructionssheet');
                    break;

                case 2:
                    // this.leftSideOutput.push("支えアーム（A,Bパーツ）");
                    // this.leftSideOutput.push("発光用台座");
                    // this.leftSideOutput.push("取扱説明書");
                    this.leftSideOutput.push("spec.supportarm");
                    this.leftSideOutput.push("spec.luminouspedestal");
                    this.leftSideOutput.push("spec.instructionssheet");
                    break;

                case 3:
                case 4:
                    // this.leftSideOutput.push("支えアーム（A,Bパーツ）");
                    // this.leftSideOutput.push("台座");
                    // this.leftSideOutput.push("取扱説明書");
                    this.leftSideOutput.push("spec.supportarm");
                    this.leftSideOutput.push("spec.pedestal");
                    this.leftSideOutput.push("spec.instructionssheet");
                    break;

                case 5:
                    // this.leftSideOutput.push("台座");
                    this.leftSideOutput.push("spec.pedestal");
                    break;

                case 6:
                    // this.leftSideOutput.push("支えアーム（A,Bパーツ）");
                    // this.leftSideOutput.push("支柱（短、長）");
                    // this.leftSideOutput.push("発光用台座");
                    // this.leftSideOutput.push("取扱説明書");
                    this.leftSideOutput.push("spec.supportarm");
                    this.leftSideOutput.push("spec.pillar.shortlong");
                    this.leftSideOutput.push("spec.luminouspedestal");
                    this.leftSideOutput.push("spec.instructionssheet");
                break;

                case 7:
                    // this.leftSideOutput.push("支えアーム（A,Bパーツ）");
                    // this.leftSideOutput.push("支柱（短、長）");
                    // this.leftSideOutput.push("台座");
                    // this.leftSideOutput.push("取扱説明書");
                    this.leftSideOutput.push("spec.supportarm");
                    this.leftSideOutput.push("spec.pillar.shortlong");
                    this.leftSideOutput.push("spec.pedestal");
                    this.leftSideOutput.push("spec.instructionssheet");
                break;

                case 8:
                    // this.leftSideOutput.push("支えアーム（A,Bパーツ）");
                    // this.leftSideOutput.push("支柱（短、長）");
                    // this.leftSideOutput.push("台座");
                    this.leftSideOutput.push("spec.supportarm");
                    this.leftSideOutput.push("spec.pillar.shortlong");
                    this.leftSideOutput.push("spec.pedestal");
                break;

                case 10:
                case 11:
                case 12:
                    // this.leftSideOutput.push("台座");
                    // this.leftSideOutput.push("支えパーツ");
                    // this.leftSideOutput.push("支柱");
                    this.leftSideOutput.push("spec.pedestal");
                    this.leftSideOutput.push("spec.supportparts");
                    this.leftSideOutput.push("spec.pillar");
                break;
            }

            if (this.selInstructionSheetOptionID == 0 &&
                //this.leftSideOutput.includes("取扱説明書") == false)
                this.leftSideOutput.includes('spec.instructionssheet') == false)
            {
                // this.leftSideOutput.push("取扱説明書");
                this.leftSideOutput.push("spec.instructionssheet");
            }

            console.log("left side content", this.leftSideOutput);
        },
        UpdateRightSideOutput()
        {
            console.log("(UpdateRightSideOutput)");
            this.rightSideOutput = [];
            this.rightSideOutputMaterial = [];
            // get body material
            // this.rightSideOutput.push("本体：" + this.selMaterialBody);
            this.rightSideOutput.push('text.mainbody');
            this.rightSideOutputMaterial.push(this.selMaterialBody);
            

            // get tail A material if available
            if (this.selMaterialTailAID >= 0)
            {
                // this.rightSideOutput.push("尻尾A：" + this.selMaterialTailA);
                this.rightSideOutput.push('text.tailA');
                this.rightSideOutputMaterial.push(this.selMaterialTailA);
            }
            // get tail B material if available
            if (this.selMaterialTailBID >= 0)
            {
                // this.rightSideOutput.push("尻尾B：" + this.selMaterialTailB);
                this.rightSideOutput.push('text.tailB');
                this.rightSideOutputMaterial.push(this.selMaterialTailB);
            }
            console.log("Right side output", this.rightSideOutput);
            console.log("Right side output material", this.rightSideOutputMaterial);
        },
        UpdateRightSideOutput2()
        {
            console.log("(UpdateRightSideOutput2)");
            switch (this.selProductAccessoryID)
            {
                case 1:
                    this.rightSideOutput2 = [
                        //"電源ユニット：ABS、PVC",
                        'text.battery.abspvc',
                    ];
                    break;

                case 2:
                    this.rightSideOutput2 = [
                        // "支えアーム（A,B）：MBS",
                        // "発光用台座：MBS",
                        // "滑り止め：EVA",
                        // "ピンカバー：TPU"
                        'text.supportarm.mbs',
                        'text.pedestel.lightemitting.mbs',
                        'text.nonslip.eva',
                        'text.pincover.tpu'

                    ];
                    break;

                case 3:
                case 4:
                    this.rightSideOutput2 = [
                        // "支えアーム（A,B）：MBS",
                        // "台座：MBS",
                        'text.supportarm.mbs',
                        'text.pedestal.mbs'
                    ];
                break;

                case 5:
                    this.rightSideOutput2 = [
                        // "台座：PVC"
                        'text.pedestal.pvc'
                    ];
                break;

                case 6:
                    this.rightSideOutput2 = [
                        // "支えアーム（A,Bパーツ）：MBS",
                        // "支柱（短、長）：鉄",
                        // "発光用台座：MBS",
                        // "滑り止め：EVA",
                        // "ピンカバー：TPU"
                        'text.supportarm.mbs',
                        'text.strut.iron',
                        'text.pedestel.lightemitting.mbs',
                        'text.nonslip.eva',
                        'text.pincover.tpu',
                    ];
                break;

                case 7:
                    this.rightSideOutput2 = [
                            // "支えアーム（A,Bパーツ）：MBS",
                            // "支柱（短、長）：鉄",
                            // "台座：MBS",
                            // "滑り止め：EVA"
                            'text.supportarm.mbs',
                            'text.strut.iron',
                            'text.pedestal.mbs',
                            'text.nonslip.eva'
                        ];
                break;

                case 8:
                    this.rightSideOutput2 = [];
                    this.rightSideOutput2.push(this.key_pedestalMaterial);
                    this.rightSideOutput2.push('text.strut.iron');
                break;

                case 10:
                case 11:
                case 12:
                    this.rightSideOutput2 = [];
                    this.rightSideOutput2.push(this.key_pedestalMaterial);
                    this.rightSideOutput2.push(this.key_supportPartsMaterial);
                    this.rightSideOutput2.push('text.strut.iron');
                break;
            }

            console.log("right side content material", this.rightSideOutput2);
        },
        onUpdateSelectedPodestMaterial(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            
            // value -> ID
            this.selPodestMaterialID = event.target.options[event.target.selectedIndex].value;
            this.key_pedestalMaterial = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPodestMaterial = event.target.options[event.target.selectedIndex].label;
            console.log('(onUpdateSelectedPodestMaterial) options', this.podestMaterialOptions);
            console.log('(onUpdateSelectedPodestMaterial) Pedestal material ID', this.selPodestMaterialID);
            this.UpdateRightSideOutput2();
        },
        onUpdateSelectedSupportPartsMaterial(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selSupportPartsMaterialID = event.target.options[event.target.selectedIndex].value;
            this.key_supportPartsMaterial = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selSupportPartsMaterial = event.target.options[event.target.selectedIndex].label;
            this.UpdateRightSideOutput2();
        },
        SaveStepData()
        {
            var d = {
                PublisherPackageNotation: this.PublisherPackageNotation,
                PublisherProductNotation: this.PublisherProductNotation,
                NotationPosition: this.NotationPosition,
                Key_NotationPosition: this.Key_NotationPosition,
                NotationMethod: this.NotationMethod,
                Key_NotationMethod: this.Key_NotationMethod,
                IsPlexItem: this.isPlexItem,
                IsSaleAbroad: this.isSaleAbroad,
                IsShinUltraman: this.isShinUltraman,
                ProductBody: this.selProductBody,
                ProductBodyID: this.selProductBodyID,
                ProductAccessoryID: this.selProductAccessoryID,
                InstructionSheetID: this.selInstructionSheetOptionID,
                InstructionSheet: this.selInstructionSheetOption,
                PowerSpecID: this.selPowerSpecID,
                ProductCharacterTypeID: this.selProductCharacterTypeID,
                PMSetContentID: this.selPMSetContentID,
                Key_PMSetContent: this.key_PMSetContent,
                SelShorthandWritingID: this.selShorthandWritingID,
                SelMaterialBodyID: this.selMaterialBodyID,
                SelMaterialTailAID: this.selMaterialTailAID,
                SelMaterialTailBID: this.selMaterialTailBID,
                SelPodestMaterialID: this.selPodestMaterialID,
                Key_PedestalMaterial: this.key_pedestalMaterial,
                SelSupportPartsMaterialID: this.selSupportPartsMaterialID,
                Key_SupportPartsMaterial: this.key_supportPartsMaterial,
                ProductAccessory: this.selProductAccessory,
                PowerSpec: this.selPowerSpec,
                PowerSpecAmount: this.selPowerSpecAmount,
                SelMaterialBody: this.selMaterialBody,
                SelMaterialTailA: this.selMaterialTailA,
                SelMaterialTailB: this.selMaterialTailB,
                SelPodestMaterial: this.selPodestMaterial,
                SelSupportPartsMaterial: this.selSupportPartsMaterial,
                HasEngraving: this.hasEngraving,
                HasFoaming: this.hasFoaming,
                HasProductAccessory: this.hasAccessories,
                ProductCharacterType: this.selProductCharacterType,
                Key_ProductCharacterType: this.key_productCharacterType,
                InpProductBody: this.inpProductBody,
                InpAccessories: this.inpAccessories,
                InpMaterialBody: this.inpMaterialBody,
                InpMaterialTailA: this.inpMaterialTailA,
                InpMaterialTailB: this.inpMaterialTailB,
                InpBattery: this.inpBattery,
                InpBatteryAmount: this.inpBatteryAmount,
                LeftSideOutput: this.leftSideOutput,
                RightSideOutput: this.rightSideOutput,
                RightSideOutputMaterial: this.rightSideOutputMaterial,
                RightSideOutput2: this.rightSideOutput2,

                MadeIn: this.GetMadeInOnly(),
            };
            console.log("Save step 3 data", d);
            this.saveStep3Data(d);
        },
        ...mapActions(['saveStep3Data','saveToken']),
        GoToNextStep()
        {
            this.SaveStepData();
            this.$router.push('/step4');
        },
        GoBackStep()
        {
            this.SaveStepData();
            this.$router.push('/step2');
        },
        LoadStep3Data()
        {
            this.NotationPosition = this.Step3Data.NotationPosition;
            this.NotationMethod = this.Step3Data.NotationMethod;
            this.isPlexItem = this.Step3Data.IsPlexItem;
            this.isSaleAbroad = this.Step3Data.IsSaleAbroad;
            this.isShinUltraman = this.Step3Data.IsShinUltraman;
            this.selProductBody = this.Step3Data.ProductBody;
            this.selProductBodyID = this.Step3Data.ProductBodyID;
            this.selProductAccessoryID = this.Step3Data.ProductAccessoryID;
            this.selInstructionSheetOptionID = this.Step3Data.InstructionSheetID;
            this.selPowerSpecID = this.Step3Data.PowerSpecID;
            this.selProductCharacterTypeID = this.Step3Data.ProductCharacterTypeID;
            this.key_productCharacterType = this.Step3Data.Key_ProductCharacterType;
            this.selPMSetContentID = this.Step3Data.PMSetContentID;
            this.key_PMSetContent = this.Step3Data.Key_PMSetContent;
            this.selShorthandWritingID = this.Step3Data.SelShorthandWritingID;
            this.selMaterialBodyID = this.Step3Data.SelMaterialBodyID;
            this.selMaterialTailAID = this.Step3Data.SelMaterialTailAID;
            this.selMaterialTailBID = this.Step3Data.SelMaterialTailBID;
            this.selPodestMaterialID = this.Step3Data.SelPodestMaterialID;
            this.key_pedestalMaterial = this.Step3Data.Key_PedestalMaterial;
            this.selSupportPartsMaterialID = this.Step3Data.SelSupportPartsMaterialID;
            this.key_supportPartsMaterial = this.Step3Data.Key_SupportPartsMaterial;
            this.selProductAccessory = this.Step3Data.ProductAccessory;
            this.selInstructionSheetOption = this.Step3Data.InstructionSheet;
            this.selPowerSpec = this.Step3Data.PowerSpec;
            this.selPowerSpecAmount = this.Step3Data.PowerSpecAmount;
            this.selMaterialBody = this.Step3Data.SelMaterialBody;
            this.selMaterialTailA = this.Step3Data.SelMaterialTailA;
            this.selMaterialTailB = this.Step3Data.SelMaterialTailB;
            this.selPodestMaterial = this.Step3Data.SelPodestMaterial;
            this.selSupportPartsMaterial = this.Step3Data.SelSupportPartsMaterial;
            this.hasEngraving = this.Step3Data.HasEngraving;
            this.hasFoaming = this.Step3Data.HasFoaming;
            this.hasAccessories = this.Step3Data.HasProductAccessory;
            this.selProductCharacterType = this.Step3Data.ProductCharacterType;
            this.inpProductBody = this.Step3Data.InpProductBody;
            this.inpAccessories = this.Step3Data.InpAccessories;
            this.inpMaterialBody = this.Step3Data.InpMaterialBody;
            this.inpMaterialTailA = this.Step3Data.InpMaterialTailA;
            this.inpMaterialTailB = this.Step3Data.InpMaterialTailB;
            this.inpBattery = this.Step3Data.InpBattery;
            this.inpBatteryAmount = this.Step3Data.InpBatteryAmount;
            this.leftSideOutput = this.Step3Data.LeftSideOutput;
            this.rightSideOutput = this.Step3Data.RightSideOutput;
            this.rightSideOutputMaterial = this.Step3Data.RightSideOutputMaterial;
            this.rightSideOutput2 = this.Step3Data.RightSideOutput2;
        },
        GetProductAccessories(callback)
        {
            this.productAccessoryOptions = [];

            var data = 
            {
                SeriesID: this.Step1Data.SeriesID,
                SessionToken: localStorage.getItem('session_token')
            };

            console.log("[GetProductAccessories] Get product accessories for", data);
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "productaccessories", data,
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            })
            .then(async response =>
            {
                console.log("Received product accessory options", response);
                this.productAccessoryOptions = response.data.Accessories;
                this.productAccessoryOptions.push({ label: "text.others", value: -1 });

                if (LocalizationManager.IsLocaInitialized==false)
                {
                    await this.WaitForLocalizationInit();
                    this.TranslateProductAccessoryOptions();
                    callback(true);
                }
                else
                {
                    this.TranslateProductAccessoryOptions();
                    callback(true);
                }
            })
            .catch(error =>
            {
                console.log("Received error.", error);
                if (error.response.status != 500)
                {
                    this.$HandleBadRequest(error.response.status, error.response.data);
                }
                else
                {
                    callback(false);
                }
            });
        },
        GetPowerSpecOptions(callback)
        {
            this.powerSpecOptions = [];

            var data = 
            {
                Accessory: this.selProductAccessoryID,
                SessionToken: localStorage.getItem('session_token')
            };

            console.log("[GetPowerSpecOptions] Get power spec options for", data);
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "powerunits", data,
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            })
            .then(async response =>
            {
                console.log("Received power spec options", response);
                this.powerSpecOptions = response.data.PowerUnits;
                this.powerSpecOptions.push({ label: "text.others", value: -1 });

                if (LocalizationManager.IsLocaInitialized==false)
                {
                    await this.WaitForLocalizationInit();
                    this.TranslatePowerSpecOptions();
                    callback(true);
                }
                else
                {
                    this.TranslatePowerSpecOptions();
                    callback(true);
                }
            })
            .catch(error =>
            {
                console.log("Received error.", error);
                callback(false);
            });
        },
        GetCharacterTypeOptions(callback)
        {
            this.productCharacterTypeOptions = [];

            var data = 
            {
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
                CopyrightSourceID: this.Step1Data.CopyrightSourceID,
                SessionToken: localStorage.getItem('session_token')
            };

            console.log("[GetCharacterTypeOptions] Get character type options for ", data);
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "charactertypes", data,
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            })
            .then(async response =>
            {
                console.log("Received product character type options", response);
                this.productCharacterTypeOptions = response.data;

                // ToDo if just one code
                if (this.productCharacterTypeOptions.length === 1)
                {
                    this.selProductCharacterType = this.productCharacterTypeOptions[0].label;
                    this.key_productCharacterType = this.productCharacterTypeOptions[0].locakey;
                    this.selProductCharacterTypeID = this.productCharacterTypeOptions[0].value;

                    setTimeout(() =>
                    {
                        const event = new Event('change', { bubbles: true });
                        event.PreventUpdate = true;

                        let sel = document.getElementById("ProductCharacterTypeSelection");
                        sel.selectedIndex = 0;
                        sel.dispatchEvent(event);    
                    }, 10);
                }

                if (LocalizationManager.IsLocaInitialized==false)
                {
                    await this.WaitForLocalizationInit();
                    this.TranslateProductCharacterTypeOptions();
                    callback(true);
                }
                else
                {
                    this.TranslateProductCharacterTypeOptions();
                    callback(true);
                }
            })
            .catch(error =>
            {
                console.log("Received error.", error);
                callback(false);
            });
        },
        async LoadSetContent()
        {
            let success = await this.GetSetContentOptions();
            
            if (success == false)
            {
                return;
            }

            if (this.Step3Data)
            {
                setTimeout(() =>
                {
                    this.selPMSetContentID = this.Step3Data.PMSetContentID;
                    console.log("loaded set content ID", this.selPMSetContentID);
                    var sel = document.getElementById("PM_SetContentSelection");
                    if (sel != null)
                    {
                        const event = new Event('change', { bubbles: true });
                        event.PreventUpdate = true;
                        sel.selectedIndex = this.GetOptionIndexOfID(this.pm_SetContentOptions, this.selPMSetContentID);
                        sel.dispatchEvent(event);
                    }
                    else
                    {
                        console.log("set content selector not available");
                    }
                }, 50);
            }
            else
            {
                console.log("Don't have set content yet");
            }
        },
        async GetSetContentOptions()
        {
            var data = 
            {
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
                SessionToken: localStorage.getItem('session_token')
            };

            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "setcontent", data)
            .then(async response =>
            {
                console.log("Received set content", response);
                this.pm_SetContentOptions = [];
                try
                {
                    this.pm_SetContentOptions = response.data;

                    if (this.pm_SetContentOptions.length === 1)
                    {
                        this.selPMSetContent = this.pm_SetContentOptions[0].label;
                        this.key_PMSetContent = this.pm_SetContentOptions[0].locakey;
                        this.selPMSetContentID = parseInt(this.pm_SetContentOptions[0].value);

                        setTimeout(() =>
                        {
                            const event = new Event('change', { bubbles: true });
                            event.PreventUpdate = true;

                            let sel = document.getElementById("PM_SetContentSelection");
                            sel.selectedIndex = 0;
                            sel.dispatchEvent(event);    
                        }, 10);
                    }

                    if (LocalizationManager.IsLocaInitialized == false)
                    {
                        await this.WaitForLocalizationInit();
                        this.TranslateSetContent();
                        return true;
                    }
                    else
                    {
                        this.TranslateSetContent();
                        return true;
                    }
                }
                catch(error)
                {
                    console.error(error);
                    return false;
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                return false;
            });
        },
        TranslateSetContent()
        {
            for (let i = 0; i < this.pm_SetContentOptions.length; i++)
            {
                this.pm_SetContentOptions[i] = ({ locakey: this.pm_SetContentOptions[i].label, label: LocalizationManager.GetTranslation(this.pm_SetContentOptions[i].label), value: this.pm_SetContentOptions[i].value });
            }
        },
        TranslatePowerSpecOptions()
        {
            for (let i = 0; i < this.powerSpecOptions.length; i++)
            {
                this.powerSpecOptions[i] = ({ locakey: this.powerSpecOptions[i].label, label: LocalizationManager.GetTranslation(this.powerSpecOptions[i].label), value: this.powerSpecOptions[i].value });
            }
        },
        TranslateProductAccessoryOptions()
        {
            for (let i = 0; i < this.productAccessoryOptions.length; i++)
            {
                this.productAccessoryOptions[i] = ({ locakey: this.productAccessoryOptions[i].label, label: LocalizationManager.GetTranslation(this.productAccessoryOptions[i].label), value: this.productAccessoryOptions[i].value });
            }
        },
        TranslateProductCharacterTypeOptions()
        {
            for (let i = 0; i < this.productCharacterTypeOptions.length; i++)
            {
                this.productCharacterTypeOptions[i] = ({ locakey: this.productCharacterTypeOptions[i].label, label: LocalizationManager.GetTranslation(this.productCharacterTypeOptions[i].label), value: this.productCharacterTypeOptions[i].value });
            }
        },
        GetOptionIndexOfID(optionArray, id)
        {
            for(let i = 0; i < optionArray.length; i++)
            {
                if (optionArray[i].value == id)
                {
                    return i;
                }
            }

            return -1;
        },
    },
    data(){
        return{
            IsInitialized: false,
            LicensingData: null,
            ShowPlasticModelSetContent: false,
            LicensingNotations: LicensingNotationsData,
            isPlexSelectionDisabled: false,
            ShowProductBodyInput: false,
            ShowAccessorieInput: false,
            ShowFoamingOption: false,
            ShowProductBodyFoamingInput: false,
            ShowProductTailAInput: false,
            ShowProductTailBInput: false,
            ShowPowerSpecInput: false,
            ShowSupportPartsMaterialSelection: false,
            ShowPodestMaterialSelection: false,
            // selPublisher: '',
            // selPublisherIndex: -1,
            PublisherPackageNotation: '',
            PublisherProductNotation: '',
            NotationPosition: '',
            NotationMethod: '',
            Key_NotationPosition: undefined,
            Key_NotationMethod: undefined,
            ShowIsPlexItem: true,
            isPlexItem: false,
            selProductBody: '',
            selProductBodyID: -1,
            inpProductBody: '',
            hasAccessories: false,
            hasFoaming: false,
            inpAccessories: '',
            inpMaterialBody: '',
            inpMaterialTailA: '',
            inpMaterialTailB: '',
            inpBattery: '',
            inpBatteryAmount: '',
            selProductAccessory: '',
            selProductAccessoryID: -1,
            selInstructionSheetOption: '',
            selInstructionSheetOptionID: 0,
            selPowerSpec: '',
            selPowerSpecID: -1,
            selPowerSpecAmount: '',
            hasEngraving: false,
            isSaleAbroad: false,
            selPMSetContent: '',
            key_PMSetContent: '',
            selPMSetContentID: -1,
            selProductCharacterType: '',
            key_productCharacterType: '',
            selProductCharacterTypeID: -1,
            showProductCharacterOptions: true,
            showShinUltramanCheckbox: false,
            ShowEngravingOption: false,
            disableEngraveOption: false,
            ShowSaleAbroadOption: false,
            disableSalesAbroadOption: false,
            selShorthandWritingID: -1,
            isShinUltraman: false,
            ShowCopyrightNotation: false,
            selMaterialBodyID: -1,
            selMaterialBody: '',
            selMaterialTailAID: -1,
            selMaterialTailA: '',
            selMaterialTailBID: -1,
            selMaterialTailB: '',
            selPodestMaterialID: -1,
            selPodestMaterial: '',
            key_pedestalMaterial: '',
            selSupportPartsMaterialID: -1,
            selSupportPartsMaterial: '',
            key_supportPartsMaterial: '',
            leftSideOutput: [],
            rightSideOutput: [],
            rightSideOutputMaterial: [],
            rightSideOutput2: [],
            podestMaterialOptions: [],
            supportPartsMaterialOptions: [],
            materialSelectionOptionsBody:[],
            materialSelectionOptionsTailA:[],
            materialSelectionOptionsTailB:[],
            pm_SetContentOptions:[],
            productBodyOptions:
            [
                { locakey: "text.productbody.options.main", label: LocalizationManager.GetTranslation("text.productbody.options.main"), value: 0},
                { locakey: "text.productbody.options.main_tail", label: LocalizationManager.GetTranslation("text.productbody.options.main_tail"), value: 1},
                { locakey: "text.productbody.options.main_tailA_tailB", label: LocalizationManager.GetTranslation("text.productbody.options.main_tailA_tailB"), value: 2},
                { locakey: "text.others", label: LocalizationManager.GetTranslation("text.others"), value: 3}
            ],
            productAccessoryOptions:[],
            instructionSheetOptions:
            [
                { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0},
                { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 1}
            ],
            powerSpecOptions:[],
            productCharacterTypeOptions:[],
            shorthandWritingOptions:[],
        }
    }
}
</script>
<style>
#PublisherNotationResult
{
    visibility: hidden;
}

#PowerSpecInputContainer
{
    width: 140px;
}

#PowerSpecAmountInputContainer
{
    width: 70px;
}

#PowerSpecAmountInputContainer input
{
    text-align: center;
}

.PublisherPreview
{
    text-align: center;
    font-size: 70%;
    line-height: 1.2;
}

.PublisherPreviewVisible
{
    background-color: #eee;
}

/* DARKMODE */
.DM_Publisher
{
    background-color: #444 !important;
    color:#ccc !important;
}
.DM_Publisher div
{
    background-color: #444 !important;
    color:#ccc !important;
}
</style>