<template>
    <div id="Wrapper">
        <div id="LoginContainer">
            <div class="LoginContentContainer">
                <div class="InputLabel localize" data-key="text.email">Email</div>
                <input id="Email" type="text" />
            </div>
            <div class="LoginContentContainer">
                <div class="InputLabel localize" data-key="text.password">Password</div>
                <input id="Password" type="password" />
            </div>
            <div class="LoginContentContainer">
                <div id="LoginButton" class="Button ButtonGreen LoginRegPageButton localize" data-key="text.login" @click="Login">Login</div>
                <div id="RegisterButton" style="margin-top: 25px;" class="Button ButtonGreen LoginRegPageButton localize" data-key="text.register" @click="OpenRegistration">Register</div>
                <div id="ForgotPWButton" class="Button ButtonGreen LoginRegPageButton localize" data-key="text.forgotpassword" @click="OpenForgotPassword">Forgot password</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { InitDarkmode } from './Darkmode.js';
import { LocalizationManager } from './Localization.js';
import {mapActions} from 'vuex';

export default {
    mounted(){
        InitDarkmode();

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }
        
        LocalizationManager.TranslatePage();

        let self = this;

        let inp = document.getElementById('Email');
        inp.addEventListener('keypress', function(event)
        {
            if (event.key === 'Enter')
            {
                self.Login();
            }
        });

        inp = document.getElementById('Password');
        inp.addEventListener('keypress', function(event)
        {
            if (event.key === 'Enter')
            {
                self.Login();
            }
        });
    },
    methods:{
        Login() 
        {
            let data = {
                Email: document.getElementById("Email").value,
                Password: document.getElementById("Password").value,
            };
            
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "login", data)
            .then(async response =>
            {
                console.log(response.data);
                localStorage.setItem('session_token', response.data.Token);
                localStorage.setItem('user_id', response.data.UserID);
                this.saveToken(response.data.Token);
                this.$router.push('/step1');
            })
            .catch(error =>
            {
                localStorage.removeItem('session_token');
                this.saveToken('');
                console.log("Received error", error);
                if (error.response.status == 400){
                    alert(error.response.data);
                }
            });
        },
        OpenRegistration()
        {
            this.$router.push('/register');
        },
        OpenForgotPassword()
        {
            // alert("まだ実装していません。");
            this.$router.push('/forgotpassword');
        },
        ...mapActions(['saveToken']),
    }
}
</script>

<style>
input
{
    height: 30px;
    border-radius: 10px;
}

#Wrapper
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    justify-content: center;
    align-content: center;
}

#LoginContainer
{
    padding: 30px 25px;
    background-color: rgb(90, 196, 189);
    border-radius: 20px;
    width: 400px;
    color: #fff;
}

.LoginContentContainer
{

}

#LoginButton
{

}

.LoginRegPageButton
{
    display: grid;
    align-items: center;
    justify-content: center;
}

.LinkText
{
    cursor: pointer;
    color: #00f;
}

.InputLabel
{
    font-weight: bold;;
    margin-bottom: 8px;
}

#LoginContainer .LoginContentContainer + .LoginContentContainer
{
    margin-top: 20px;
}

.LoginContentContainer div + div
{
    margin-top: 10px;
}
</style>