<template>
    <div class="SheetEntryContainer" :id="ContainerID">
        <div class="EntryInfo SheetEntryName">{{ SheetEntryName }}</div>
        <div class="EntryInfo SheetEntryMadeBy">{{ SheetAuthor }}</div>
        <div class="EntryInfo SheetEntryMadeOn">{{ SheetCreatedOn }}</div>
        <div class="EntryInfo" style="flex:0.5;"><div class="SheetEntryButton ButtonGreen" @click="EditSpecSheet"><img style="height: 24px;" title="Edit" src="/assets/Icon/Edit.svg" /></div></div>
        <div class="EntryInfo" style="flex:0.5;"><div class="SheetEntryButton ButtonGreen" @click="ExportSpecSheet"><img style="height: 24px;" title="Export" src="/assets/Icon/Export.svg" /></div></div>
        <div class="EntryInfo" style="flex:0.5;"><div class="SheetEntryButton ButtonGreen" @click="DeleteSpecSheet"><img style="height: 24px;" title="Delete" src="/assets/Icon/Delete.svg" /></div></div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import { GenerateAndExportExcel } from '../ExportAsExcel.js';
import { LocalizationManager } from '../Localization.js';
import { ShowLoading } from '../FullScreenLoading.js';
import { General_Dialog } from './GeneralDialog.js';
import { ApplyDarkmode } from '@/Darkmode';

export default
{
    props:{
        ContainerID: Number,
        SheetEntryName: String,
        SheetAuthor: String,
        SheetCreatedOn: String,
    },
    computed:{
        ...mapGetters(['getStep1Data','getStep2Data','getStep3Data','getStep4Data']),
        Step1Data(){
            return this.getStep1Data;
        },
        Step2Data(){
            return this.getStep2Data;
        },
        Step3Data(){
            return this.getStep3Data;
        },
        Step4Data(){
            return this.getStep4Data;
        }
    },
    methods:{
        ...mapActions(['saveStep1Data']),
        ...mapActions(['saveStep2Data']),
        ...mapActions(['saveStep3Data']),
        ...mapActions(['saveStep4Data']),
        async GetSpeecSheetData()
        {
            var data =
            {
                SpecSheetID: this.ContainerID,
                SessionToken: localStorage.getItem('session_token')
            }
            
            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "sheet/data", data,
            {
                headers:
                {
                    'Content-Type':  'application/x-www-form-urlencoded'//'application/json'
                }
            })
            .then(response => 
            {
                this.SpecSheetData = JSON.parse(response.data.Result);
                console.log("Received spec sheet", this.SpecSheetData);
            })
            .catch(error =>
            {
                console.log("Received error.", error);
                if (error.response.status >= 400 &&
                    error.response.status <= 499)
                {
                    this.$HandleBadRequest(error.response.status, error.response.data);
                }
            });
        },
        async EditSpecSheet()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));
            await this.GetSpeecSheetData();

            this.saveStep1Data(this.SpecSheetData.Step1);
            this.saveStep2Data(this.SpecSheetData.Step2);
            this.saveStep3Data(this.SpecSheetData.Step3);
            this.saveStep4Data(this.SpecSheetData.Step4);

            ShowLoading(false);

            this.$router.push('/step1');
        },
        async ExportSpecSheet()
        {
            console.log("Export spec sheet with ID-" + this.ContainerID);
            await this.GetSpeecSheetData();
            
            var data =
            {
                SessionToken: localStorage.getItem('session_token')
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'termtranslations/excel', data)
            .then(response =>
            {
                console.log(response);
                GenerateAndExportExcel(this.SpecSheetData.Step1, this.SpecSheetData.Step2, this.SpecSheetData.Step3, this.SpecSheetData.Step4, response.data);
            })
            .catch(error=>
            {
                console.log("Received error", error);
                if (error.response.status != 500)
                {
                    this.$HandleBadRequest(error.response.status, error.response.data);
                }
            });
        },
        DeleteSpecSheet()
        {
            console.log("(DeleteSpecSHeet)");

            let dialogContent = LocalizationManager.GetTranslation("text.delete.confirmation");
            dialogContent = dialogContent.replace("{content}", '[' + this.SheetEntryName + '] ');

            General_Dialog.Display(dialogContent, LocalizationManager.GetTranslation('text.ok') ,this.SendDeleteSpecSheetRequest, LocalizationManager.GetTranslation("text.cancel"));
            this.$nextTick(()=>{
                ApplyDarkmode();
            });
        },
        SendDeleteSpecSheetRequest()
        {
            var data =
            {
                SpecSheetID: this.ContainerID,
                SessionToken: localStorage.getItem('session_token')
            }

            console.log("Delete ID ", this.ContainerID);
            // Show loading animation
            var spinner = document.getElementById("LoadingSpinner");
            if (spinner != null)
            {
                spinner.style.visibility = "visible";
            }

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "sheet/delete", data)
            .then(response =>
            {
                console.log("Received response.", response);

                if (spinner != null)
                {
                    spinner.style.visibility = "hidden";
                }

                let sheetEntry = document.getElementById(this.ContainerID);
                if (sheetEntry != null)
                {
                    sheetEntry.remove();
                }
            })
            .catch(error =>
            {
                console.log("Received error.", error);
                if (spinner != null)
                {
                    spinner.style.visibility = "hidden";
                }
            })

            this.HideOverlay();
        }
    },
    data(){
        return{
            SpecSheetData: null,
        }
    }
}
</script>

<style>
.SheetEntryContainer
{
    display:flex;
    height: 50px;

    background-color: #c8eae7;
    border: 1px #2c6864 solid;
    border-radius: 5px;
    color: #3e3737;
}

.EntryInfo
{
    align-content: center;
    justify-content: center;
    display: grid;
    flex: 1;
}

.SheetEntryName
{
    font-weight: bold;
}

.SheetEntryMadeBy
{
    
}

.SheetEntryMadeOn
{
    font-style: italic;
}
</style>