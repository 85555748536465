export const LicensingNotationsData = [
    {
        CopyrightHolder: "東宝",
        CopyrightHolderID: 1,
        Options:[
            {
                CharacterType: "ゴジラ1998",
                CharacterTypeID: 1,
                IsPlex: true,
                Package: {
                    Licensing: "GODZILLA AND THE CHARACTER DESIGN\nARE TRADEMARKS OF TOHO CO., LTD.\n© 1998, 2022 TOHO CO., LTD.\nALL RIGHTS RESERVED.\n{madeIn}",
                },
                Product: {
                    Licensing: "GODZILLA AND THE CHARACTER DESIGN\nARE TRADEMARKS OF TOHO CO., LTD.\n© 1998, 2022 TOHO CO., LTD.\nALL RIGHTS RESERVED.\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
        
                    ]
                }
            },
            {
                IsPlex: true,
                CharacterType: "ゴジラ2014",
                CharacterTypeID: 2,
                Package: {
                    Licensing: "Godzilla TM & © Toho Co., Ltd.\n© 20{getYear} Legendary and Warner Bros. Entertainment Inc.\nAll Rights Reserved.\n{madeIn}",
                },
                Product: {
                    Licensing: "Godzilla TM & © Toho Co., Ltd.\n© 20{getYear} Legendary and Warner Bros. Entertainment Inc.\nAll Rights Reserved.\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "Godzilla TM & © Toho Co., Ltd.\n© 20{getYear} Legendary and WBEI.\n{madeInShort}\n\nX・PLUS\n{dateCode}"
                    ]
                }
            },
            {
                IsPlex: true,
                CharacterType: "ゴジラ2019, 2021",
                CharacterTypeID: 3,
                Package: {
                    Licensing: "© 20{getYear} Legendary. All Rights Reserved.\nGODZILLA TM & © TOHO CO., LTD.\nMONSTERVERSE TM & © Legendary\n{madeIn}",
                },
                Product: {
                    Licensing: "© 20{getYear} Legendary. All Rights Reserved.\nGODZILLA TM & © TOHO CO., LTD.\nMONSTERVERSE TM & © Legendary\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "© 20{getYear} Legendary. All Rights Reserved.\nGODZILLA TM & © TOHO CO., LTD.\n{madeInShort}\n\nX・PLUS\n{dateCode}",
                        "© 20{getYear} Legendary.\nGODZILLA TM & © TH\n{madeInShort}\n\nX・PLUS\n{dateCode}"
                    ]
                }
            },
            {
                IsPlex: true,
                CharacterType: "東宝ゴジラ, ゴジラ以外",
                CharacterTypeID: 4,
                Package: {
                    Licensing: "TM & © TOHO CO., LTD.\n{madeIn}",
                },
                Product: {
                    Licensing: "TM & © TOHO CO., LTD.\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "TM & © TH\n{madeInShort}\n{dateCode}"
                    ]
                },
            },
        ]
        
    },
    {
        CopyrightHolder: "円谷",
        CopyrightHolderID: 2,
        Options:[
            {
                IsPlex: true,
                IsSaleAbroad: false,
                Package:{
                    Licensing: "©TSUBURAYA PROD.\n{madeIn}",
                },
                Product:{
                    Licensing: "©TSUBURAYA PROD.\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand:[

                    ]
                }
            },
            {
                IsPlex: true,
                IsSaleAbroad: true,
                Package:{
                    Licensing: "©TSUBURAYA PRODUCTIONS\n{madeIn}",
                },
                Product:{
                    Licensing: "©TSUBURAYA PRODUCTIONS\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand:[

                    ]
                }
            },
            {
                IsPlex: false,
                HasEngraving: false,
                IsSaleAbroad: false,
                IsShinUltraman: false,
                Package:{
                    Licensing: "©TSUBURAYA PROD.",
                },
                Product:{
                    Licensing: "©TSUBURAYA PROD.\n\nX・PLUS\n{madeIn}",
                    Shorthand:[

                    ]
                }
            },
            {
                IsPlex: false,
                HasEngraving: false,
                IsSaleAbroad: false,
                IsShinUltraman: true,
                Package:{
                    Licensing: "©2022「シン・ウルトラマン」製作委員会 ©円谷プロ",
                },
                Product:{
                    Licensing: "©2022「シン・ウルトラマン」製作委員会 ©円谷プロ\n\nX・PLUS\n{madeIn}",
                    Shorthand:[
                        "©2022SUM\n©TPC\n\n{madeInShort}"
                    ]
                }
            },
            {
                IsPlex: false,
                HasEngraving: false,
                IsSaleAbroad: true,
                Package:{
                    Licensing: "©TSUBURAYA PRODUCTIONS",
                },
                Product:{
                    Licensing: "©TSUBURAYA PRODUCTIONS\n\nX・PLUS\n{madeIn}",
                    Shorthand:[

                    ]
                }
            },
            {
                IsPlex: false,
                HasEngraving: true,
                IsSaleAbroad: false,
                Package:{
                    Licensing: "©TSUBURAYA PROD.",
                },
                Product:{
                    Licensing: "©TSUBURAYA PROD.\n\nX・PLUS\n{madeIn}",
                    Shorthand:[

                    ]
                }
            },
            {
                IsPlex: false,
                HasEngraving: true,
                IsSaleAbroad: true,
                Package:{
                    Licensing: "©TSUBURAYA PRODUCTIONS",
                },
                Product:{
                    Licensing: "©TSUBURAYA PRODUCTIONS\n\nX・PLUS\n{madeIn}",
                    Shorthand:[

                    ]
                }
            }
        ]
    },
    {
        CopyrightHolder: "大映／KADOKAWA",
        CopyrightHolderID: 3,
        Options:[
            {
                CharacterType: "大怪獣ガメラ",
                CharacterTypeID: 8,
                Package: {
                    Licensing: "©KADOKAWA 1965",
                },
                Product: {
                    Licensing: "©KADOKAWA 1965",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "大怪獣決闘 ガメラ対バルゴン",
                CharacterTypeID: 9,
                Package: {
                    Licensing: "©KADOKAWA 1966",
                },
                Product: {
                    Licensing: "©KADOKAWA 1966",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "大怪獣空中戦 ガメラ対ギャオス",
                CharacterTypeID: 10,
                Package: {
                    Licensing: "©KADOKAWA 1968",
                },
                Product: {
                    Licensing: "©KADOKAWA 1968",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ対大悪獣ギロン",
                CharacterTypeID: 11,
                Package: {
                    Licensing: "©KADOKAWA 1969",
                },
                Product: {
                    Licensing: "©KADOKAWA 1969",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ対大魔獣ジャイガー",
                CharacterTypeID: 12,
                Package: {
                    Licensing: "©KADOKAWA 1970",
                },
                Product: {
                    Licensing: "©KADOKAWA 1970",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ対深海怪獣ジグラ",
                CharacterTypeID: 13,
                Package: {
                    Licensing: "©KADOKAWA 1971",
                },
                Product: {
                    Licensing: "©KADOKAWA 1971",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "宇宙怪獣ガメラ",
                CharacterTypeID: 14,
                Package: {
                    Licensing: "©KADOKAWA 1980",
                },
                Product: {
                    Licensing: "©KADOKAWA 1980",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ 大怪獣空中決戦",
                CharacterTypeID: 15,
                Package: {
                    Licensing: "©KADOKAWA NH/1995",
                },
                Product: {
                    Licensing: "©KADOKAWA NH/1995",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ2 レギオン襲来",
                CharacterTypeID: 16,
                Package: {
                    Licensing: "©KADOKAWA NHFN/1996",
                },
                Product: {
                    Licensing: "©KADOKAWA NHFN/1996",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "ガメラ3 邪神＜イリス＞覚醒",
                CharacterTypeID: 17,
                Package: {
                    Licensing: "©KADOKAWA TNHN/1999",
                },
                Product: {
                    Licensing: "©KADOKAWA TNHN/1999",
                    Shorthand: [
        
                    ]
                }
            },
            {
                CharacterType: "小さき勇者たち～ガメラ～",
                CharacterTypeID: 18,
                Package: {
                    Licensing: "©2006「小さき勇者たち～ガメラ～」製作委員会",
                },
                Product: {
                    Licensing: "©2006「小さき勇者たち～ガメラ～」製作委員会",
                    Shorthand: [
        
                    ]
                }
            },
        ]
    },
    {
        CopyrightHolder: "東映アニメ",
        CopyrightHolderID: 4,
        Options:[
            {
                IsPlex: true,
                CharacterType: "東映アニメーション（DB Z）",
                CharacterTypeID: 5,
                Package: {
                    Licensing: "©バードスタジオ／集英社・東映アニメーション\n{madeIn}",
                },
                Product: {
                    Licensing: "©バードスタジオ／集英社・東映アニメーション\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "©B/S・T\n{madeInShort}\n\nX・PLUS\n{dateCode}"
                    ]
                },
            },
            {
                IsPlex: true,
                CharacterType: "東映アニメーション（DB 超）",
                CharacterTypeID: 6,
                Package: {
                    Licensing: "©バードスタジオ／集英社・東映アニメーション\n{madeIn}",
                },
                Product: {
                    Licensing: "©バードスタジオ／集英社・東映アニメーション\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "©B/S・T\n{madeInShort}\n\nX・PLUS\n{dateCode}"
                    ]
                },
            },
            {
                IsPlex: true,
                CharacterType: "東映アニメ(ドラゴンボール) スーパーヒーロー",
                CharacterTypeID: 7,
                Package:{
                    Licensing: "©バード・スタジオ／集英社\n©「２０２２ ドラゴンボール超」製作委員会\n{madeIn}",
                },
                Product:{
                    Licensing: "©バード・スタジオ／集英社\n©「２０２２ ドラゴンボール超」製作委員会\n{madeIn}\n\nX・PLUS\n{dateCode}",
                    Shorthand: [
                        "©B.S/S\n©2022DBSFP\n{madeInShort}\n\nX・PLUS\n{dateCode}"
                    ]
                },
            }
        ]
    },
];