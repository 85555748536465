<template>
    <div id="Wrapper" style="overflow: visible; justify-content: left !important;">
        <div id="ButtonGridContainer">
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.productspec" @click="OpenDataMenu('producttypes')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.scaledata" @click="OpenDataMenu('scales')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.seriesdata" @click="OpenDataMenu('series')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.factorydata" @click="OpenDataMenu('factories')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.licensordata" @click="OpenDataMenu('licensors')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.seriestable" @click="OpenDataMenu('seriestable')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.factoriestable" @click="OpenDataMenu('factoriestable')"></div>
            <div class="MasterDataButton localize Button ButtonGreen" data-key="text.termtranslation" @click="OpenDataMenu('termtranslation')"></div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { InitDarkmode } from '../Darkmode.js';
import { LocalizationManager } from '../Localization.js';

export default
{
    name: 'App',
    mounted(){
    document.title = "EZ-SPEC-Master Data";

    if (localStorage.getItem('session_token') == null)
    {
        this.$router.push('/');
        return;
    }
    else
    {
        this.saveToken(localStorage.getItem('session_token'));
    }

    if (LocalizationManager.IsLocaInitialized == false)
    {
        LocalizationManager.Initialize();
    }

    this.$nextTick(()=>
    {
        LocalizationManager.TranslatePage();
    });

    this.$nextTick(()=>{
        InitDarkmode();
    });
  },
  methods:{
    ...mapActions(['saveToken']),
    OpenDataMenu(dataType)
    {
        this.$router.push(`/masterdata/${dataType}`);
    }
  },
}
</script>

<style>
.MasterDataButton
{
    min-width: 150px;
    height: 50px;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 110%;
}

#ButtonGridContainer
{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 30px;
    row-gap: 40px;
    padding: 50px;
}
</style>