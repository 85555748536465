<template>
    <div id="Wrapper" style="overflow: visible;" class="ThemeElement" Darkmode="DM_Text">
        <div class="localize" data-key="text.seriestable.explanation"></div>
        <div id="SaveTranslationButtonContainer" style="margin-bottom: 10px; visibility: hidden;">
            <button id="SaveTranslationButton" class="Button ButtonGreen localize" data-key="text.save" style="float: right;" @click="UpdateSeriesRelationships">Save</button>
        </div>
        <!-- <div id="SeriesTable">
            <div id="SeriesTableHeader">
                <div id="SeriesColumnHeader" class="DataColumn localize" data-key="text.series">Series</div>
                <div id="LicensorColumnHeader" class="DataColumn localize" data-key="text.licensor">Licensor</div>
                <div id="ProductTypeColumnHeader" class="DataColumn localize" data-key="text.productspec">Product Types</div>    
            </div>
            <div id="SeriesTableBody">
            </div>
        </div> -->
        <div id="SeriesList"></div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { ApplyDarkmode, InitDarkmode } from '../Darkmode.js';
import { LocalizationManager } from '../Localization.js';
import { ShowLoading } from '../FullScreenLoading.js';
import { General_Dialog } from '@/components/GeneralDialog.js';

export default
{
    mounted(){
        let table = document.getElementById('SeriesList');
        table.replaceChildren();

        document.title = "EZ-SPEC-Series relationship table";

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }

        this.LanguageIndex = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
        LocalizationManager.RegisterOnLanguageChangedListener(this.UpdateDataTranslation);

        var saveBtnContainer = document.getElementById('SaveTranslationButtonContainer');
        this.SaveBtnContainer = saveBtnContainer;
        let stickyObserver = new IntersectionObserver(([e]) => e.target.classList.toggle('IsSaveBtnPinned', e.intersectionRatio < 1), {threshold: [1]});
        stickyObserver.observe(saveBtnContainer);

        this.RetrieveData();

        this.$nextTick(()=>
        {
            LocalizationManager.Debug = true;
            LocalizationManager.TranslatePage();
            InitDarkmode();
        });
    },
    beforeUnmount(){
        LocalizationManager.UnregisterOnLanguageChangedListener(this.UpdateDataTranslation);
    },
    methods:{
        ...mapActions(['saveToken']),
        RetrieveData()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

            let data = 
            {
                SessionToken: localStorage.getItem('session_token')
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "master/seriesrelationships", data)
            .then(response => 
            {
                console.log("Received product type data.", response);
                this.Licensors = response.data.Licensors;
                this.ProductTypes = response.data.ProductTypes;
                this.Series = response.data.Series;
                this.SeriesRelationships = response.data.Relationships;
                this.Local_Relationships = JSON.parse(JSON.stringify(this.SeriesRelationships));

                var dataContainer = document.getElementById('SeriesList');

                // Check if we're still able to append new
                // elements since this request is async
                if (dataContainer != null)
                {
                    for(let i = 0; i < this.Series.length; i++)
                    {
                        let entry = this.CreateNewDataEntry(this.Series[i].ID);
                        dataContainer.append(entry.FoldOut);
                        dataContainer.append(entry.Entry);
                    }
                }
                else
                {
                    console.log('Could not find element with ID DataContainer');
                }
                
                ApplyDarkmode();
                ShowLoading(false);

            })
            .catch(error =>{
                console.log("Received error.", error);
                ShowLoading(false);
                
                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        UpdateSeriesRelationships()
        {
            ShowLoading(true, LocalizationManager.GetTranslation('text.loading'));
            console.log('update relationships');
            
            let data =
            {
                SessionToken: localStorage.getItem('session_token'),
                Relationships: this.Local_Relationships,
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'master/seriesrelationships/update', data)
            .then(response =>
            {
                console.log('Update relationship response', response);
                ShowLoading(false);
                General_Dialog.Display(
                    LocalizationManager.GetTranslation('text.masterdata.updated'),
                    LocalizationManager.GetTranslation('text.ok'),
                    function(){ location.reload(); }
                );

                ApplyDarkmode();
            })
            .catch(error =>
            {
                console.log('Received error.', error);
                ShowLoading(false);

                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        CreateNewDataEntry(seriesID)
        {
            let data = this.GetSeriesRelationships(seriesID);
            console.log('create data entry', data);

            // Create foldout first
            let dataFoldout = document.createElement('div');
            dataFoldout.setAttribute('control', `Series_${data.Series}`);
            dataFoldout.classList.add('DataFoldout');

            let triangleIcon = document.createElement('img');
            triangleIcon.src = '/assets/Icon_Triangle.svg';
            triangleIcon.classList.add('FoldoutIcon', 'NoSelect');
            dataFoldout.append(triangleIcon);
            triangleIcon.onclick = function(event)
            {
                let elID = event.target.parentElement.getAttribute('control');
                let target = document.getElementById(elID);
                if (target.getAttribute('isunfold') == 'true')
                {
                    triangleIcon.classList.remove('FoldoutIconOpen');
                    target.classList.remove('SeriesTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'false');
                }
                else
                {
                    triangleIcon.classList.add('FoldoutIconOpen');
                    target.classList.add('SeriesTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'true');
                }
            };

            let seriesName = document.createElement('div');
            seriesName.classList.add('SeriesColumn', 'NoSelect');
            seriesName.id = "Label_" + data.Series;
            seriesName.textContent = this.GetNameForSeriesID(data.Series);
            dataFoldout.append(seriesName);
            seriesName.onclick = function(event)
            {
                let elID = event.target.parentElement.getAttribute('control');
                let target = document.getElementById(elID);
                if (target.getAttribute('isunfold') == 'true')
                {
                    triangleIcon.classList.remove('FoldoutIconOpen');
                    target.classList.remove('SeriesTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'false');
                }
                else
                {
                    triangleIcon.classList.add('FoldoutIconOpen');
                    target.classList.add('SeriesTableDataEntryUnfold');
                    target.setAttribute('isunfold', 'true');
                }
            };

            // Create data entry
            let newDataEntry = document.createElement('div');
            newDataEntry.id = `Series_${data.Series}`;
            newDataEntry.classList.add('SeriesTableDataEntry');

            var licensorContainer = document.createElement('div');
            licensorContainer.classList.add('LicensorColumn', 'ThemeElement');
            newDataEntry.append(licensorContainer);

            let ezSpec = this;

            for(let i = 0; i < this.Licensors.length; i++)
            {
                let licensorEntry = document.createElement('div');
                licensorEntry.classList.add('Licensor');
                licensorContainer.append(licensorEntry);

                let licensorOption = document.createElement('input');
                licensorOption.type = 'checkbox';
                licensorOption.id = `licensor_${this.Licensors[i].ID}`;
                licensorOption.setAttribute('licensorid', this.Licensors[i].ID);
                licensorOption.checked = this.IsLicensorSelected(this.Licensors[i].ID, data.Licensors);
                licensorEntry.append(licensorOption);
                licensorOption.onchange = function(event)
                {
                    console.log('licensor checkbox clicked', event.target);
                    ezSpec.SetLocalLicensorRelationship(data.Series, event.target.getAttribute('licensorid'), event.target.checked);
                    ezSpec.CheckForChanges();
                    ezSpec.ShowProductTypeSelection(data.Series, event.target);
                };

                let licensorOptionLabel = document.createElement('label');
                licensorOptionLabel.setAttribute('licensorid', `${this.Licensors[i].ID}`);
                // Used for translation in OnUpdateLanguage
                licensorOptionLabel.classList.add('LicensorLabel', 'NoSelect');
                licensorOptionLabel.textContent = this.GetTranslationForData(this.LanguageIndex, this.Licensors[i]);
                licensorEntry.append(licensorOptionLabel);

                licensorOptionLabel.onclick = function(event)
                {
                    event.target.classList.add('LicensorLabelActive');

                    let found = false;
                    for (let j = 0; j < ezSpec.CurrentActiveLicensorLabels.length; j++)
                    {

                        if (ezSpec.CurrentActiveLicensorLabels[j].Series == data.Series)
                        {
                            ezSpec.CurrentActiveLicensorLabels[j].Label.classList.remove('LicensorLabelActive');
                            ezSpec.CurrentActiveLicensorLabels[j].Label = event.target;
                            found = true;
                            break;
                        }
                    }

                    if (found == false)
                    {
                        ezSpec.CurrentActiveLicensorLabels.push({Series: data.Series, Label: event.target });
                    }

                    ezSpec.ShowProductTypeSelection(data.Series, event.target);
                };
            }

            // Create product type container already
            let productTypeContainer = document.createElement('div');
            productTypeContainer.id = `ProductTypes_${data.Series}`;
            newDataEntry.append(productTypeContainer);

            return { FoldOut:dataFoldout, Entry:newDataEntry };
        },
        ShowProductTypeSelection(seriesID, licensorElement)
        {
            console.log('showproducttypeselection -> licensorElement', licensorElement);
            let ezSpec = this;

            let checkbox = document.getElementById(`licensor_${licensorElement.getAttribute('licensorid')}`);
            let licensorID = checkbox.getAttribute('licensorid');

            let productTypeRelationships = this.GetSeriesProductTypes(seriesID, licensorID);

            let productTypesContainer = document.getElementById(`ProductTypes_${seriesID}`);
            productTypesContainer.classList.add('ThemeElement', 'ProductTypeColumn');
            productTypesContainer.setAttribute('darkmode', 'DM_Select')
            productTypesContainer.replaceChildren();

            let headlineContainer = document.createElement('div');
            headlineContainer.style.display = 'flex';
            headlineContainer.style.alignItems = 'center';
            productTypesContainer.append(headlineContainer);

            let headline = document.createElement('div');
            headline.classList.add('Headline');
            headline.setAttribute('licensorid', licensorID);
            headline.textContent = LocalizationManager.GetTranslation('text.licensor.producttypes').replace('{0}', this.GetNameForLicensorID(licensorID));
            headlineContainer.append(headline);

            let resetBtn = document.createElement('span');
            resetBtn.textContent = LocalizationManager.GetTranslation('text.reset');
            resetBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            resetBtn.setAttribute('data-key', 'text.reset');
            headlineContainer.append(resetBtn);
            resetBtn.onclick = function()
            {
                let box = document.getElementById(`Series_${seriesID}_LicensorProductTypes`);
                console.log('reset', productTypeRelationships);
                for(let i = 0; i < box.options.length; i++)
                {
                    let hasRelationship = false;
                    
                    if (productTypeRelationships.length > 0)
                    {
                        for(let j = 0; j < productTypeRelationships.length; j++)
                        {
                            if (box.options[i].value == productTypeRelationships[j])
                            {
                                hasRelationship = true;
                                break;
                            }
                        }
                    }

                    ezSpec.SetLocalProductTypeRelationship(seriesID, licensorID, box.options[i].value, hasRelationship);
                    box.options[i].selected = hasRelationship;
                }

                ezSpec.CheckForChanges();
            };

            let clearBtn = document.createElement('span');
            clearBtn.textContent = LocalizationManager.GetTranslation('text.clear');
            clearBtn.classList.add('ClearButton', 'NoSelect', 'localize');
            clearBtn.setAttribute('data-key', 'text.clear');
            headlineContainer.append(clearBtn);
            clearBtn.onclick = function()
            {
                let box = document.getElementById(`Series_${seriesID}_LicensorProductTypes`);
                for(let i = 0; i < box.options.length; i++)
                {
                    box.options[i].selected = false;
                    ezSpec.SetLocalProductTypeRelationship(seriesID, licensorID, box.options[i].value, false);
                }
                
                ezSpec.CheckForChanges();
            };

            let productTypeSelect = document.createElement('select');
            productTypeSelect.multiple = true;
            productTypeSelect.id = `Series_${seriesID}_LicensorProductTypes`;
            productTypesContainer.append(productTypeSelect);

            for(let i = 0; i < this.ProductTypes.length; i++)
            {
                let option = document.createElement('option');
                option.label = this.GetTranslationForData(this.LanguageIndex, this.ProductTypes[i]);
                option.value = this.ProductTypes[i].ID;
                option.classList.add('ProductTypeLabel');
                option.selected = this.IsProductTypeSelected(this.ProductTypes[i].ID, productTypeRelationships);
                productTypeSelect.options.add(option);
            }

            productTypeSelect.onchange = function (event)
            {
                let selection = event.target;
                
                for(let i = 0; i < selection.options.length; i++)
                {
                    ezSpec.SetLocalProductTypeRelationship(seriesID, licensorID,selection.options[i].value, selection.options[i].selected);
                }

                ezSpec.CheckForChanges();
            };

            ApplyDarkmode();
        },
        UpdateDataTranslation(oldLanguage, newLanguage)
        {
            this.LanguageIndex = LocalizationManager.GetLanguageIndex(newLanguage);
            
            
            for (let i = 0; i < this.Series.length; i++)
            {
                let label = document.getElementById(`Label_${this.Series[i].ID}`);
                if (label != null)
                {
                    label.textContent = this.GetTranslationForData(this.LanguageIndex, this.Series[i]);
                }
            }

            let licensors = document.getElementsByClassName('LicensorLabel');
            let productTypes = document.getElementsByClassName('ProductTypeLabel');
            console.log('update licensors', licensors);
            for(let i = 0; i < licensors.length; i++)
            {
                licensors[i].textContent = this.GetNameForLicensorID(licensors[i].getAttribute('licensorid'));
            }

            for(let i = 0; i < productTypes.length; i++)
            {
                productTypes[i].label = this.GetNameForProductType(productTypes[i].value);
            }

            let headlines = document.getElementsByClassName('Headline');
            for(let i = 0; i < headlines.length; i++)
            {
                let id = headlines[i].getAttribute('licensorid');
                headlines[i].textContent = LocalizationManager.GetTranslation('text.licensor.producttypes').replace('{0}', this.GetNameForLicensorID(id));
            }
        },
        GetTranslationForData(language, data)
        {
            for(let i = 0; i < data.Labels.length; i++)
            {
                if (data.Labels[i].Language == language)
                {
                    return data.Labels[i].Translation;
                }
            }

            console.log('[GetTranslationForData] Could not find translation for language ' + language, data);
            return null;
        },
        GetNameForSeriesID(id)
        {
            id = parseInt(id);

            for(let i = 0; i < this.Series.length; i++)
            {
                if (this.Series[i].ID == id)
                {
                    return this.GetTranslationForData(this.LanguageIndex, this.Series[i]);
                }
            }

            return 'Series ' + id + ' not found';
        },
        GetNameForLicensorID(id)
        {
            id = parseInt(id);

            for(let i = 0; i < this.Licensors.length; i++)
            {
                if (this.Licensors[i].ID == id)
                {
                    return this.GetTranslationForData(this.LanguageIndex, this.Licensors[i]);
                }
            }

            return 'Licensor ' + id + ' not found';
        },
        GetNameForProductType(id)
        {
            id = parseInt(id);
            
            for(let i = 0; i < this.ProductTypes.length; i++)
            {
                if (this.ProductTypes[i].ID == id)
                {
                    return this.GetTranslationForData(this.LanguageIndex, this.ProductTypes[i]);
                }
            }
            
            return 'ProductType ' + id + ' not found';
        },
        GetSeriesRelationships(seriesID)
        {
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Series == seriesID)
                {
                    return this.Local_Relationships[i];
                }
            }

            return {
                Series: seriesID,
                Licensors:[],
            };
        },
        IsLicensorSelected(licensorID, relationships)
        {
            licensorID = parseInt(licensorID);
            
            for(let i = 0; i < relationships.length; i++)
            {
                if (relationships[i].Licensor == licensorID)
                {
                    return true;
                }
            }

            return false;
        },
        IsProductTypeSelected(productTypeID, relationships)
        {
            productTypeID = parseInt(productTypeID);

            for(let i = 0; i < relationships.length; i++)
            {
                if (relationships[i] == productTypeID)
                {
                    return true;
                }
            }

            return false;
        },
        GetSeriesLicensors(id)
        {
            id = parseInt(id);
            
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Series == id)
                {
                    return this.Local_Relationships[i].Licensors;
                }
            }

            return [];
        },
        GetSeriesProductTypes(seriesID, licensorID)
        {
            seriesID = parseInt(seriesID);
            licensorID = parseInt(licensorID);
            
            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Series == seriesID)
                {
                    for(let j = 0; j < this.Local_Relationships[i].Licensors.length; j++)
                    {
                        if (this.Local_Relationships[i].Licensors[j].Licensor == licensorID)
                        {
                            return this.Local_Relationships[i].Licensors[j].ProductTypes;
                        }
                    }
                }
            }

            return [];
        },
        CheckForChanges()
        {
            let hasChanges = false;
            console.log('-- CHECK FOR CHANGES --');
            console.log('local', this.Local_Relationships);
            console.log('original', this.SeriesRelationships);
            if (hasChanges == false)
            {
                for(let i = 0; i < this.Local_Relationships.length; i++)
                {
                    for(let j = 0; j < this.Local_Relationships[i].Licensors.length; j++)
                    {
                        //let hasLicensor = this.HasOriginalSeriesLicensor(this.Local_Relationships[i].Series, this.Local_Relationships[i].Licensors[j].Licensor);
                        //hasChanges = hasLicensor == false;

                        if (hasChanges == false)
                        {
                            for(let k = 0; k < this.Local_Relationships[i].Licensors[j].ProductTypes.length; k++)
                            {
                                let hasProductType = this.HasOriginalSeriesProductType(this.Local_Relationships[i].Series, this.Local_Relationships[i].Licensors[j].Licensor, this.Local_Relationships[i].Licensors[j].ProductTypes[k]);
                                hasChanges = hasProductType == false;
                                
                                if (hasChanges == true)
                                {
                                    console.log(`Change found. Series ${this.Local_Relationships[i].Series} and licensor ${this.Local_Relationships[i].Licensors[j].Licensor} does not have product type ${this.Local_Relationships[i].Licensors[j].ProductTypes[k]}`);
                                    break;
                                }
                            }
                        }
                        else
                        {
                            console.log(`Change found. Series ${this.Local_Relationships[i].Series} does not have licensor ${this.Local_Relationships[i].Licensors[j].Licensor}`);
                            break;
                        }

                        if (hasChanges == true)
                        {
                            break;
                        }
                    }

                    if (hasChanges == true)
                    {
                        break;
                    }
                }
            }

            this.SaveBtnContainer.style.visibility = hasChanges ? 'visible' : 'hidden';
        },
        SetLocalProductTypeRelationship(seriesID, licensorID, productTypeID, hasRelationship)
        {
            seriesID = parseInt(seriesID);
            licensorID = parseInt(licensorID);
            productTypeID = parseInt(productTypeID);

            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Series == seriesID)
                {
                    for(let j = 0; j < this.Local_Relationships[i].Licensors.length; j++)
                    {
                        if (this.Local_Relationships[i].Licensors[j].Licensor == licensorID)
                        {
                            if (hasRelationship == true)
                            {
                                let alreadyHasProductType = false;

                                // Check first if we already have the entry
                                for (let k = 0; k < this.Local_Relationships[i].Licensors[j].ProductTypes.length; k++)
                                {
                                    if (this.Local_Relationships[i].Licensors[j].ProductTypes[k] == productTypeID)
                                    {
                                        alreadyHasProductType = true;
                                        break;
                                    }
                                }

                                if (alreadyHasProductType == false)
                                {
                                    this.Local_Relationships[i].Licensors[j].ProductTypes.push(productTypeID);
                                }
                            }
                            else
                            {
                                for(let k = 0; k < this.Local_Relationships[i].Licensors[j].ProductTypes.length; k++)
                                {
                                    if (this.Local_Relationships[i].Licensors[j].ProductTypes[k] == productTypeID)
                                    {
                                        this.Local_Relationships[i].Licensors[j].ProductTypes.splice(k, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        SetLocalLicensorRelationship(seriesID, licensorID, hasRelationship)
        {
            seriesID = parseInt(seriesID);
            licensorID = parseInt(licensorID);

            for(let i = 0; i < this.Local_Relationships.length; i++)
            {
                if (this.Local_Relationships[i].Series == seriesID)
                {
                    if (hasRelationship == true)
                    {
                        this.Local_Relationships[i].Licensors.push({ Licensor: licensorID, ProductTypes: [] });
                    }
                    else
                    {
                        for(let j = 0; j < this.Local_Relationships[i].Licensors.length; j++)
                        {
                            if (this.Local_Relationships[i].Licensors[j].Licensor == licensorID)
                            {
                                this.Local_Relationships[i].Licensors.splice(j, 1);
                            }
                        }
                    }
                }
            }
        },
        HasOriginalSeriesLicensor(seriesID, licensorID)
        {
            seriesID = parseInt(seriesID);
            licensorID = parseInt(licensorID);
            
            for(let i = 0; i < this.SeriesRelationships.length; i++)
            {
                if (this.SeriesRelationships[i].Series == seriesID)
                {
                    for(let j = 0; j < this.SeriesRelationships[i].Licensors.length; j++)
                    {
                        if (this.SeriesRelationships[i].Licensors[j].Licensor == licensorID)
                        {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        HasOriginalSeriesProductType(seriesID, licensorID, productTypeID)
        {
            seriesID = parseInt(seriesID);
            licensorID = parseInt(licensorID);
            productTypeID = parseInt(productTypeID);

            for(let i = 0; i < this.SeriesRelationships.length; i++)
            {
                if (this.SeriesRelationships[i].Series == seriesID)
                {
                    for(let j = 0; j < this.SeriesRelationships[i].Licensors.length; j++)
                    {
                        if (this.SeriesRelationships[i].Licensors[j].Licensor == licensorID)
                        {
                            for(let k = 0; k < this.SeriesRelationships[i].Licensors[j].ProductTypes.length; k++)
                            {
                                if (this.SeriesRelationships[i].Licensors[j].ProductTypes[k] == productTypeID)
                                {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }

            return false;
        },
    },
  data() {
    return {
        SaveBtnContainer: null,
        LanguageIndex: 0,
        CurrentActiveLicensorLabels: [],
        // Original data
        Licensors: [],
        Series: [],
        ProductTypes: [],
        SeriesRelationships: [],
        // Local data used for udating
        Local_Relationships: [],
    }
  },
}
</script>

<style>
#SeriesTable
{
    padding-bottom: 50px;
}

#SeriesTableHeader
{
    display: flex;
    height: 64px;
    font-weight: bold;
    font-size: 1.2rem;
}

#SeriesTableBody
{

}

.DataColumn
{
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 250px;
    flex: 1;
}

.DataColumn select
{
    border: 1px solid #ccc;
}

.DataColumn select
{
    flex: 1;
    min-height: 150px;
    padding: 10px 0px 10px 5px;
    border-radius: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgb(0,169,157) #ccc;
    overflow-y: auto;
}

#SeriesList
{
    padding: 25px 0px 75px 0px;
    display: grid;
}

.SeriesColumn
{
    text-align: left;
    justify-content: unset;
}

.LicensorColumn
{
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0px 10px 0px;
}

.LicensorColumn .Licensor
{
    display: flex;
    align-items: center;
}

.Licensor label
{
    /* grid-column-start: 2;
    grid-column-end: span 2; */
    display: inline-block;
}

.Licensor input
{
    width: 16px;
    margin: 0px 10px 0px 20px;
}

.ProductTypeColumn
{
    margin-left: 20px;
}

.ProductTypeColumn .Headline
{
    font-weight: bold;
    margin: 10px 0px 10px 0px;
}

.ProductTypeColumn select
{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 8px 5px 8px 5px;
    font-size: 1.2rem;
}

.DataFoldout
{
    display: flex;
    border-bottom: 1px solid #ccc;
}

.DataFoldout .FoldoutIcon
{
    height: 18px;
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.FoldoutIcon
{
    transform: rotateZ(0deg);
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
}

.FoldoutIconOpen
{
    transform: rotateZ(60deg);
}

.SeriesTableDataEntry
{
    display: grid;
    max-height: 0px;
    overflow: hidden;
    transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
}

.SeriesTableDataEntry input
{
    
}

.SeriesTableDataEntry + .DataFoldout
{
    margin-top: 50px;
}

.SeriesTableDataEntryUnfold
{
    max-height: 1000px;
}

.SeriesTableDataEntry + .SeriesTableDataEntry
{
    margin-top: 30px;
}

.LicensorLabel
{
    white-space: nowrap;
    cursor: pointer;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
}

.LicensorLabel:hover
{
    color: rgb(0,169,157);
    text-decoration: underline;
}

.LicensorLabelActive
{
    color: rgb(0,169,157);
    font-weight: bold;
}

.ClearButton
{
    margin-left: 25px;
    color: rgb(0,169,157);
    cursor: pointer;
}
</style>