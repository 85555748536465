<template>
    <div id="Wrapper">
        <div id="RegisterContainer">
            <div class="RegisterContentContainer">
                <div class="InputLabel localize" data-key="text.username">Username</div>
                <p style="font-size: 80%; font-style: italic; margin: 3px 0px;" class="localize" data-key="text.username.hint">Please enter your full name, it'll be used as author.</p>
                <input id="Username" type="text" @input="OnNameInputChange" />
            </div>
            <div class="RegisterContentContainer">
                <div class="InputLabel localize" data-key="text.email">Email</div>
                <input id="Email" type="text" @input="OnEmailInputChange" />
            </div>
            <div class="RegisterContentContainer">
                <div class="InputLabel localize" data-key="text.password">Password</div>
                <input id="Password" type="password" @input="OnPasswordInputChange"/>
            </div>
            <div class="RegisterContentContainer" style="margin-top: 5px;">
                <div id="PasswordStrengthBarContainer">
                    <span class="localize" data-key="text.passwordstrength">Password strength</span>
                    <div id="BarBackground">
                        <div id="StrengthBar"></div>
                    </div>
                    <span class="Pointer localize" @click="ToggleHint" style="color:#f9f9f9; font-size: 80%;" data-key="text.hints">Hints</span>
                    <div id="PasswordHintContainer">
                        <div id="Hint_LowerCase" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.lowercase">
                                Lowercase
                            </div>
                            <div class="HintImgContainer">
                                <img id="LowerCaseIMG" class="HintImg" src='/assets/Icon/Icon_Cross.webp' />
                            </div>
                        </div>
                        <div id="Hint_UpperCase" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.uppercase">
                                Uppercase
                            </div>
                            <div class="HintImgContainer">
                                <img id="UpperCaseIMG" class="HintImg" src='/assets/Icon/Icon_Cross.webp' />
                            </div>
                        </div>
                        <div id="Hint_Number" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.number">
                                Number
                            </div>
                            <div class="HintImgContainer">
                                <img id="NumberIMG" class="HintImg" src='/assets/Icon/Icon_Cross.webp' />
                            </div>
                        </div>
                        <div id="Hint_SpecialChar" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.specialchar">
                                Special character
                            </div>
                            <div class="HintImgContainer">
                                <img id="SpecialCharIMG" class="HintImg" src='/assets/Icon/Icon_Cross.webp' />
                            </div>
                        </div>
                        <div id="Hint_Length" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.length">
                                Length
                            </div>
                            <div class="HintImgContainer">
                                <img id="LengthIMG" class="HintImg" src='/assets/Icon/Icon_PW_Low.webp' />
                            </div>
                        </div>
                        <div id="Hint_ConsecutiveChar" class="HintEntry">
                            <div class="HintLabel localize" data-key="text.consecrepchar">
                                Consecutive repeating character
                            </div>
                            <div class="HintImgContainer">
                                <img id="RepeatIMG" class="HintImg" src='/assets/Icon/Icon_Check.webp' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="RegisterContentContainer">
                <div class="InputLabel localize" data-key="text.password.confirmation">Password Confirmation</div>
                <input id="PasswordConfirmation" type="password" />
            </div>
            <div class="RegisterContentContainer">
                <div id="RegisterButton" class="Button ButtonGreen LoginRegPageButton localize" data-key="text.register" @click="Register">Register</div>
                <div id="LoginButton" class="Button ButtonGreen LoginRegPageButton localize" data-key="text.login" style="margin-top: 25px;" @click="OpenLogin">Login</div>
            </div>
        </div>
    </div>
</template>

<script>
// import { useStore } from 'vuex';
import axios from 'axios';
import { InitDarkmode } from './Darkmode.js';
import { LocalizationManager } from './Localization.js';
import { CheckPasswordStrength } from './PasswordCheck.js';

export default {
    mounted(){
        InitDarkmode();
        
        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }
        
        LocalizationManager.TranslatePage();
    },
    data(){
        return{
            IsHintOpen: false,
        }
    },
    methods:{
        ToggleHint()
        {
            let hintContainer = document.getElementById("PasswordHintContainer");
            if (this.IsHintOpen)
            {
                hintContainer.classList.remove('HintOpen');
            }
            else
            {
                hintContainer.classList.add('HintOpen');
            }
            this.IsHintOpen = !this.IsHintOpen;
            
            console.log('Hint box open', this.IsHintOpen);
        },
        OnPasswordInputChange(event)
        {
            let pwCheck = CheckPasswordStrength(event.target.value);
            console.log('Password strength', pwCheck);
            let bar = document.getElementById("StrengthBar");
            bar.classList.remove('PW1','PW2','PW3');

            if (pwCheck.StrengthLevel == 1)
            {
                bar.classList.add('PW1');
            }
            else if (pwCheck.StrengthLevel == 2)
            {
                bar.classList.add('PW2');
            }
            else if (pwCheck.StrengthLevel == 3)
            {
                bar.classList.add('PW3');
            }

            let hint = document.getElementById('LowerCaseIMG');
            hint.src = pwCheck.HasLowercase ? '/assets/Icon/Icon_Check.webp' : '/assets/Icon/Icon_Cross.webp';

            hint = document.getElementById('UpperCaseIMG');
            hint.src = pwCheck.HasUppercase ? '/assets/Icon/Icon_Check.webp' : '/assets/Icon/Icon_Cross.webp';

            hint = document.getElementById('NumberIMG');
            hint.src = pwCheck.HasNumber ? '/assets/Icon/Icon_Check.webp' : '/assets/Icon/Icon_Cross.webp';

            hint = document.getElementById('SpecialCharIMG');
            hint.src = pwCheck.HasSpecialChar ? '/assets/Icon/Icon_Check.webp' : '/assets/Icon/Icon_Cross.webp';

            hint = document.getElementById('LengthIMG');
            if (pwCheck.HasStrongLength)
            {
                hint.src = '/assets/Icon/Icon_PW_Good.webp';
            }
            else if (pwCheck.HasMidLength)
            {
                hint.src = '/assets/Icon/Icon_PW_Mid.webp';
            }
            else
            {
                hint.src = '/assets/Icon/Icon_PW_Low.webp';
            }

            hint = document.getElementById('RepeatIMG');
            hint.src = pwCheck.HasConsecutiveRepeatChar ? '/assets/Icon/Icon_Cross.webp' : '/assets/Icon/Icon_Check.webp';
        },
        OnNameInputChange(event)
        {
            var inp = event.target.value;
            inp = inp.replace(/[!@#$%^&*()_+\-=;:'",<.>/?\\| ]/g, "");
            event.target.value = inp;
        },
        OnEmailInputChange(event)
        {
            var inp = event.target.value;
            inp = inp.replace(/\s/g, "");
            event.target.value = inp.trim().toLowerCase();
        },
        Register() 
        {
            let data = {
                Username: document.getElementById("Username").value,
                Email: document.getElementById("Email").value,
                Password: document.getElementById("Password").value,
                PasswordConfirmation: document.getElementById("PasswordConfirmation").value
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "register", data)
            .then(async response =>
            {
                console.log("Server response", response);
                if (response.status == 201)
                {
                    this.OpenLogin();
                }
                else
                {
                    alert(response.data);
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                if (Object.hasOwn(error, 'response')==true)
                {
                    if (error.response.status == 400 ||
                        error.response.status == 401)
                    {
                        alert(error.response.data);
                    }
                }
                else
                {
                    alert("An error appeared: " + error);
                }
            });
        },
        OpenLogin()
        {
            this.$router.push('/');
        }
    }
}
</script>

<style>
input
{
    height: 30px;
    border-radius: 10px;
}

#Wrapper
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    justify-content: center;
    align-content: center;
}

#RegisterContainer
{
    padding: 30px 25px;
    background-color: rgb(90, 196, 189);
    border-radius: 20px;
    width: 400px;
    color: #fff;
}

.RegisterContentContainer
{

}

#RegisterButton
{

}

.LoginRegPageButton
{
    display: grid;
    align-items: center;
    justify-content: center;
}

.InputLabel
{
    font-weight: bold;;
    margin-bottom: 8px;
}

#RegisterContainer .RegisterContentContainer + .RegisterContentContainer
{
    margin-top: 20px;
}

.RegisterContentContainer div + div
{
    margin-top: 10px;
}

#BarBackground
{
    background-color: rgb(4, 51, 41);
    height: 13px;
    border-radius: 50px;
    display: grid;
    align-content: center;
    padding-right: 1px;
    padding-left: 1px;
}

#StrengthBar
{
    height: 11px;
    background-color: #f33;
    width: 2%;
    border-radius: 50px;
}

.PW1
{
    background-color: #ff7f33 !important;
    width: 33% !important;
}

.PW2
{
    background-color: #fff675 !important;
    width: 66% !important;
}

.PW3
{
    background-color: #a7ff75 !important;
    width: 100% !important;
}

.Pointer
{
    cursor: pointer;
}

#PasswordHintContainer
{
    height: 0px;
    transition: 0.2s;
    overflow: hidden;
    background: rgb(90,196,189);
    background: linear-gradient(180deg, rgba(90,196,189,1) 0%, rgba(51,107,103,1) 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0px;
}

.HintOpen
{
    height: 200px !important;
    padding: 10px !important;
}

.HintLabel
{
    display: flex;
    align-content: center;
    align-items: center;
}

.HintEntry
{
    display: flex;
}

.HintImgContainer
{
    margin: 0px 0px 0px 5px !important;
    display: grid;
    align-content: center;
}

.HintImg
{
    height: 18px;
    width: 18px;
}

</style>