let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{14,})');
let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{10,}))|((?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{10,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,}))');

let LowerCaseCheck = new RegExp('(?=.*[a-z])');
let UpperCaseCheck = new RegExp('(?=.*[A-Z])');
let NumberCheck = new RegExp('(?=.*[0-9])');
let MinLengthCheck = new RegExp('(?=.{8,})');
let MidLengthCheck = new RegExp('(?=.{10,})');
let StrongLengthCheck = new RegExp('(?=.{14,})');
let SpecialCharCheck = new RegExp('(?=.*[^A-Za-z0-9])');
let ConsecutiveRepeatCharCheck = new RegExp('.((w)\\2{1,})');

export function CheckPasswordStrength(password)
{
    var strengthLevel = 1;
    if (strongPassword.test(password))
    {
        strengthLevel = 3;
    }
    else if (mediumPassword.test(password))
    {
        strengthLevel = 2;
    }

    var hasLowerCase = LowerCaseCheck.test(password);
    var hasUpperCase = UpperCaseCheck.test(password);
    var hasNumber = NumberCheck.test(password);
    var hasMinLength = MinLengthCheck.test(password);
    var hasMidLength = MidLengthCheck.test(password);
    var hasStrongLength = StrongLengthCheck.test(password);
    var hasSpecialChar = SpecialCharCheck.test(password);
    var hasConsecutiveRepeatChar = ConsecutiveRepeatCharCheck.test(password);

    return {
        HasLowercase: hasLowerCase,
        HasUppercase: hasUpperCase,
        HasNumber: hasNumber,
        HasMinLength: hasMinLength,
        HasMidLength: hasMidLength,
        HasStrongLength: hasStrongLength,
        HasSpecialChar: hasSpecialChar,
        StrengthLevel: strengthLevel,
        HasConsecutiveRepeatChar: hasConsecutiveRepeatChar
    }
}