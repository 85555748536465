<template>
    <div id="MainContent">
        <div class="BrightColorText localize" data-key="text.maintitle" style="font-size: 2rem;">仕様書登録</div>
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.packagespec.title">PKG仕様</div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packagespec">PKG仕様:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'PackageSelection'" :options="packageOptions" v-model="selPackageType" @change="onUpdateSelectedPackageType"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.diecut">ダイカット仕様:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="true" :elementID="'CutTypeSelection'" v-model="selCutType" :options="cutTypeOptions" @change="onUpdateSelectedCutType"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === true" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.wrapping">ラッピング:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :disabled="false" :elementID="'WrappingSelection'" :options="wrappingOptions" @change="onUpdateSelectedWrapping"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packageprint1">PKG印刷①:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Printing_1_Selection'" v-model="selPrinting_1" :options="printing_1_Options" @change="onUpdateSelectedPrinting_1"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packageprint2">コーティング:</span>
                <span class="spacer"></span>
                <CustomCheckbox :elementID="'IsCoatingCheckbox'" :disabled="isCoatingDisabled" v-model="isCoating" @togglechange="onUpdateSelectedIsCoating"></CustomCheckbox>
            </div>
            <div v-if="IsPlasticModelKit === true" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packageprint.pm">PKG印刷:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Printing_pm_Selection'" v-model="selPrinting_pm" :options="printing_pm_Options" @change="onUpdateSelectedPMPrinting"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.middlemount">中台紙:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'MiddelMount_Selection'" v-model="selMiddleMount" :options="middleMountOptions" @change="onUpdateSelectedMiddleMount"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.tapestop">テープ止め:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'TapeStop_Selection'" v-model="selTapeStop" :options="tapeStopOptions" @change="onUpdateSelectedTapeStop"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.certstamp.title">証紙:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'CertStamp_Selection'" v-model="selCertStamp" :options="certStampOptions" @change="onUpdateSelectedCertStamp"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.ltdsticker.label">限定版シール:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'LtdSticker_Selection'" v-model="selLtdSticker" :options="ltdStickerOptions" @change="onUpdateSelectedLtdSticker"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" id="PackageImageSelectionContainer">
                <div class="ImagePreviewSelection">
                    <ManufacturerDropdown class="ThemeElement" Darkmode="DM_Select" :elementID="'PackageImageSelection'" :options="PackageImageOptions" @change="onUpdateSelectedPackageImage"></ManufacturerDropdown>
                    <div id="PackageImagePreview">
                        <img id="PackagePreview" class="PreviewImage" lazy/>
                    </div>
                </div>
                <div class="ImagePreviewSelection">
                    <div id="LimitedVersionImagePreview">
                        <img id="LimitedPreview" class="PreviewImage" lazy/>
                    </div>
                </div>
            </div>
            <div v-if="IsPlasticModelKit === true" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.setcontent.label">セット内容、梱包材表記シール:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'setContent_Selection'" :options="setContentOptions" @change="onUpdateSelectedSetContent"></ManufacturerDropdown>
            </div>
        </div>
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.packagematerial.title">梱包素材</div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packagematerial.label">PKG素材:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'PackageMaterialSelection'" :options="packageMaterialOptions" @change="onUpdateSelectedPackageMaterial"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packaging1">包装①:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Package1Selection'" :options="package1Options" @change="onUpdateSelectedPackage1"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packaging2">包装②:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Package2Selection'" :options="package2Options" @change="onUpdateSelectedPackage2"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === false" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packaging3">包装③:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Package3Selection'" :options="package3Options" @change="onUpdateSelectedPackage3"></ManufacturerDropdown>
            </div>
            <div v-if="IsPlasticModelKit === true" class="FormEntry">
                <span class="ContentSubline localize" data-key="text.packaging.label">包装:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'Package_pm_Selection'" :options="package_pm_Options" @change="onUpdateSelectedPMPackage"></ManufacturerDropdown>
            </div>
            <!--<div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.ltdsticker">限定版シール</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement" Darkmode="DM_Select" :elementID="'StickerSelection'" :options="stickerOptions" v-model="selStickerIndex" @change="onUpdateSelectedSticker"></ManufacturerDropdown>
            </div>-->
        </div>
        <div class="ContentContainer ThemeElement" Darkmode="DM_ContentContainer">
            <div class="ContentHeadlineContent localize" data-key="text.carton">カートン</div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.inpnumber">入り数:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'InputAmountSelection'" :options="inputAmountOptions" v-model="selInputAmountID" @change="onUpdateSelectedInputAmount"></ManufacturerDropdown>
            </div>
            <div class="FormEntry">
                <span class="ContentSubline localize" data-key="text.inner">インナー:</span>
                <span class="spacer"></span>
                <ManufacturerDropdown class="ThemeElement ContentSubline" Darkmode="DM_Select" :elementID="'InnerSelection'" :options="innerOptions" v-model="selInnerID" @change="onUpdateSelectedInner"></ManufacturerDropdown>
            </div>
        </div>
        <button class="Button DisabledButton ButtonGreen NextPrevBtn localize" data-key="text.back" :disabled="true" id="BackBtn" @click="GoBackStep">戻る</button>
        <button class="Button DisabledButton ButtonGreen NextPrevBtn localize" data-key="text.next" :disabled="true" id="NextBtn" @click="GoToNextStep">次へ</button>
    </div>
</template>

<script>
import ManufacturerDropdown from './components/ManufacturerDropdown.vue';
import CustomCheckbox from './components/CustomCheckbox.vue';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import { InitDarkmode, ApplyDarkmode } from './Darkmode';
import { LocalizationManager } from './Localization.js';
import axios from 'axios';

export default
{
    components:{
        ManufacturerDropdown,
        CustomCheckbox
    },
    computed:{
        ...mapGetters(['getStep1Data','getStep2Data']),
        Step1Data(){
            return this.getStep1Data;
        },
        Step2Data(){
            return this.getStep2Data;
        }
    },
    mounted(){
        document.title = "EZ-SPEC-Step 2";
        
        InitDarkmode();

        if (this.Step1Data == null)
        {
            this.$router.push('/');
            return;
        }

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if(this.Step2Data)
        {
            this.LoadStepData();
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }
        else
        {
            LocalizationManager.TranslatePage();
        }

        this.$nextTick(()=>
        {
            // If plastic model kits product type
            if (this.Step1Data.ProductSpecificationID == 4)
            {
                this.IsPlasticModelKit = true;
                this.packageOptions = [
                    { locakey: "text.pkgspec.box.pasted", label: "貼り箱", value: 0 },
                    { locakey: "text.pkgspec.box.folded", label: "畳み箱", value: 1 },
                ];
            }
            else
            {
                this.IsPlasticModelKit = false;
                this.packageOptions = [
                    { locakey: "text.pkgspec.window", label: "ウィンドウ", value: 0 },
                    { locakey: "text.pkgspec.closed", label: "クローズド", value: 1 },
                    { locakey: "text.pkgspec.header", label: "ヘッダー", value: 2 },
                ];
            }

            this.InitializeStep((success) =>
            {
                if (success == false)
                {
                    console.log("Could not retrieve all initial data");
                    return;
                }

                const event = new Event('change', { bubbles: true });
                event.PreventUpdate = true;

                setTimeout(()=>
                {
                    var sel = document.getElementById("WrappingSelection");
                    if (sel != null)
                    {
                        sel.selectedIndex = this.GetOptionIndexOfID(this.wrappingOptions, this.selWrappingID);
                        sel.dispatchEvent(event);
                    }

                    sel = document.getElementById("Printing_pm_Selection");
                    if (sel != null)
                    {
                        sel.selectedIndex = this.GetOptionIndexOfID(this.printing_pm_Options, this.selPrinting_pmID);
                        sel.dispatchEvent(event);
                    }

                    sel = document.getElementById("Package_pm_Selection");
                    if (sel != null)
                    {
                        sel.selectedIndex = this.GetOptionIndexOfID(this.package_pm_Options, this.selPackage_pmID);
                        sel.dispatchEvent(event);
                    }

                    if (this.Step1Data.ProductSpecificationID == 4)
                    {
                        console.log("Pre select cert stamp for plastic model kit");
                        console.log("Copyright source ID", this.Step1Data.CopyrightSourceID);
                        // let certStampSel = document.getElementById("CertStamp_Selection");
                        if (this.Step1Data.CopyrightSourceID == 2 ||
                            this.Step1Data.CopyrightSourceID == 3)
                        {
                            this.selCertStampID = 1;
                        }
                        else if (this.Step1Data.CopyrightSourceID == 1)
                        {
                            this.selCertStampID = 2;
                        }
                        else
                        {
                            this.selCertStampID = 0;
                        }

                        console.log("set content options",this.setContentOptions);
                        if (this.Step1Data.CopyrightSourceID == 1 ||
                            this.Step1Data.CopyrightSourceID == 2 ||
                            this.Step1Data.CopyrightSourceID == 3)
                        {
                            this.selSetContent = this.setContentOptions[0].locakey;
                            this.selSetContentID = this.setContentOptions[0].value;
                        }
                        else
                        {
                            this.selSetContent = this.setContentOptions[1].locakey;
                            this.selSetContentID = this.setContentOptions[1].value;
                        }

                        setTimeout(()=>
                        {
                            let certSel = document.getElementById("CertStamp_Selection");
                            if (certSel != null)
                            {
                                let index = this.GetOptionIndexOfID(this.certStampOptions, this.selCertStampID);
                                certSel.selectedIndex = index;
                                certSel.dispatchEvent(event);
                            }

                            let setContentSel = document.getElementById("setContent_Selection");
                            console.log("found set content selector", setContentSel);
                            console.log("set content options",this.setContentOptions);
                            console.log("set content ID",this.selSetContentID);
                            if (setContentSel != null)
                            {
                                let index = this.GetOptionIndexOfID(this.setContentOptions, this.selSetContentID);
                                console.log("set content index", index);
                                setContentSel.selectedIndex = index;
                                setContentSel.dispatchEvent(event);
                            }
                            else{
                                console.log("NEED NEXT-TICK");
                            }
                        }, 50);
                        
                        console.log("Pre selected cert stamp ID", this.selCertStampID);
                    }

                    if (LocalizationManager.IsLocaInitialized == false)
                    {
                        LocalizationManager.Initialize();
                    }
                
                    if(this.Step2Data)
                    {
                        // This is a copy of the code above because the
                        // this.LoadStepData is going to override the cert stamp ID
                        if (this.Step1Data.ProductSpecificationID == 4)
                        {
                            console.log("Pre select cert stamp for plastic model kit");
                            console.log("Copyright source ID", this.Step1Data.CopyrightSourceID);
                            // let certStampSel = document.getElementById("CertStamp_Selection");
                            if (this.Step1Data.CopyrightSourceID == 2 ||
                                this.Step1Data.CopyrightSourceID == 3)
                            {
                                this.selCertStampID = 1;
                            }
                            else if (this.Step1Data.CopyrightSourceID == 1)
                            {
                                this.selCertStampID = 2;
                            }
                            else
                            {
                                this.selCertStampID = 0;
                            }
                            console.log("Pre selected cert stamp ID", this.selCertStampID);
                        }

                        console.log(this.selCutTypeID, this.selCutType);

                        this.$nextTick(()=>
                        {
                            const event = new Event('change', { bubbles: true });
                            event.PreventUpdate = true;

                            let target = document.getElementById("PackageSelection");
                            let index = -1;

                            if (target != null)
                            {
                                index = this.GetOptionIndexOfID(this.packageOptions, this.selPackageTypeID);
                                target.selectedIndex = index;                 
                                target.dispatchEvent(event);
                            }

                            target = document.getElementById("CutTypeSelection");
                            if (target != null)
                            {
                                index = this.GetOptionIndexOfID(this.cutTypeOptions, this.selCutTypeID);
                                target.selectedIndex = index;
                                
                                if (this.selCutTypeID === 1)
                                {
                                    target.disabled = false;
                                }

                                target.dispatchEvent(event);
                            }

                            this.$nextTick(()=>
                            {
                                target = document.getElementById("Printing_1_Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.printing_1_Options, this.selPrinting_1ID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("IsCoatingCheckbox");
                                if (target != null)
                                {
                                    target.checked = this.isCoating;
                                }

                                target = document.getElementById("MiddelMount_Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.middleMountOptions, this.selMiddleMountID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("TapeStop_Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.tapeStopOptions, this.selTapeStopID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("CertStamp_Selection");
                                if (target != null)
                                {
                                    console.log("Load cert stamp selection", this.selCertStampID);
                                    console.log("cert stamp options", this.certStampOptions);
                                    index = this.GetOptionIndexOfID(this.certStampOptions, this.selCertStampID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("LtdSticker_Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.ltdStickerOptions, this.selLtdStickerID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("PackageMaterialSelection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.packageMaterialOptions, this.selPackageMaterialID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("Package1Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.package1Options, this.selPackage1ID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("Package2Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.package2Options, this.selPackage2ID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("Package3Selection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.package3Options, this.selPackage3ID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }
                                
                                // target = document.getElementById("StickerSelection");
                                // if (target != null)
                                // {
                                //     target.selectedIndex = this.Step2Data.StickerIndex;
                                //     this.selStickerIndex = target.selectedIndex;
                                //     target.dispatchEvent(event);
                                // }

                                target = document.getElementById("InputAmountSelection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.inputAmountOptions, this.selInputAmountID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                target = document.getElementById("InnerSelection");
                                if (target != null)
                                {
                                    index = this.GetOptionIndexOfID(this.innerOptions, this.selInnerID);
                                    target.selectedIndex = index;
                                    target.dispatchEvent(event);
                                }

                                this.$nextTick(()=>{
                                    // target = document.getElementById("Printing_1_Selection");
                                    // if (target != null){
                                    //     target.selectedIndex = this.Step2Data.Print1Index;
                                    //     this.selPrinting_1Index = target.selectedIndex;
                                    //     this.selPrinting_1 = this.Step2Data.Print1;
                                    //     target.dispatchEvent(event);
                                    // }

                                    // Wait for the PackageImageOptions to be updated
                                    this.$nextTick(()=>{
                                        target = document.getElementById("PackageImageSelection");
                                        if (target != null){
                                            // We need to make a special event here to
                                            // trigger the loading properly
                                            let e = new Event('change', { bubbles: true });
                                            console.log("Load Package image index: " + this.Step2Data.PackageImageIndex);
                                            target.selectedIndex = this.Step2Data.PackageImageIndex;
                                            this.selPackageImageIndex = target.selectedIndex;
                                            this.selPackageImage = this.Step2Data.PackageImage;
                                            target.dispatchEvent(e);
                                        }
                                    });
                                });

                                this.IsInitialized = true;
                                this.EnablePageButtons();
                                ApplyDarkmode();
                                LocalizationManager.TranslatePage();
                            });
                        });
                    }
                    else
                    {
                        this.IsInitialized = true;
                        this.EnablePageButtons();
                        LocalizationManager.TranslatePage();
                        this.$nextTick(()=>
                        {
                            ApplyDarkmode();
                        });
                    }
                }, 10);
            });
        });
    },
    methods:
    {
        async InitializeStep(callback)
        {
            let success = await this.GetPackageMaterials();
            if (success === false)
            {
                callback(false);
                return;
            }
            success = await this.GetPackaging();
            if (success === false)
            {
                callback(false);
                return;
            }
            success = await this.GetCartonInner();
            if (success === false)
            {
                callback(false);
                return;
            }
            success = await this.GetCartonInsideCount();
            if (success === false)
            {
                callback(false);
                return;
            }

            callback(true);
        },
        EnablePageButtons()
        {
            var btn = document.getElementById("BackBtn");
            btn.disabled= false;
            btn.classList.remove("DisabledButton");
            btn = document.getElementById("NextBtn");
            btn.disabled= false;
            btn.classList.remove("DisabledButton");
        },
        onUpdateSelectedPackageType(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }

            if (event.PreventUpdate !== undefined)
            {
                return;
            }

            this.selPackageType = event.target.options[event.target.selectedIndex].label;
            this.key_packageType = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPackageTypeID = parseInt(event.target.options[event.target.selectedIndex].value);

            this.printing_1_Options = [];
            this.printing_2_Options = [];

            console.log(`[Step2](OnUpdateSelectedPackageType) PackageTypeID: ${this.selPackageTypeID}`);

            let cutTypeID = -1;
            let printing_1ID = -1;
            let middleMountID = -1;
            let tapeStopID = -1;
            let packageImageID = -1;
            let disableCutTypeSelection = false;
            let disablePrinting_1_Selection = false;
            let disableMiddleMountSelection = false;
            let disableTapeStopSelection = false;
            let disablePackageImageSelection = false;
            let disableCoatingSelection = false;

            switch (this.selPackageTypeID)
            {
                case 0:
                    this.cutTypeOptions = [
                        { locakey: "text.cut.top_lid", label: LocalizationManager.GetTranslation("text.cut.top_lid"), value: 0 },
                        { locakey: "text.cut.with_door", label: LocalizationManager.GetTranslation("text.cut.with_door"), value: 1 },
                    ];
                    
                    cutTypeID = 0;
                    printing_1ID = 0;
                break;

                case 1:
                    this.cutTypeOptions = [
                        { locakey: "text.cut.top_lid", label: LocalizationManager.GetTranslation("text.cut.top_lid"), value: 0 },
                        { locakey: "text.cut.roll_lid_open", label: LocalizationManager.GetTranslation("text.cut.roll_lid_open"), value: 1 },
                        { locakey: "text.cut.lid_bottom_box", label: LocalizationManager.GetTranslation("text.cut.lid_bottom_box"), value: 2 },
                    ];

                    cutTypeID = 0;
                    printing_1ID = 0;
                break;

                case 2:
                    this.cutTypeOptions = [
                        { locakey: '', label: '', value: 0 }
                    ];

                    cutTypeID = 0;

                    this.printing_1_Options = [
                        { locakey: 'text.printing.option6', label: LocalizationManager.GetTranslation('text.printing.option6'), value: 0 },
                        { locakey: 'text.printing.option7', label: LocalizationManager.GetTranslation('text.printing.option7'), value: 1 },
                        { locakey: 'text.printing.option8', label: LocalizationManager.GetTranslation('text.printing.option8'), value: 2 },
                        { locakey: 'text.printing.option9', label: LocalizationManager.GetTranslation('text.printing.option9'), value: 3 },
                    ];

                    disableCutTypeSelection = true;
                    disableMiddleMountSelection = true;
                    disableTapeStopSelection = true;
                    disablePackageImageSelection = true;
                    disableCoatingSelection = true;
                break;
            }
            
            const e = new Event('change', { bubbles: true });

            var target = document.getElementById("CutTypeSelection");
            if (target != null)
            {
                this.selCutType = cutTypeID >= 0 ? this.cutTypeOptions[cutTypeID].label : '';
                this.key_cutType = cutTypeID >= 0 ? this.cutTypeOptions[cutTypeID].locakey : '';
                this.selCutTypeID = cutTypeID;
                target.disabled = disableCutTypeSelection;
                target.selectedIndex = cutTypeID;
                target.dispatchEvent(e);
            }

            target = document.getElementById("Printing_1_Selection");
            if (target != null)
            {
                this.selPrinting_1ID = printing_1ID;
                this.key_print1 = printing_1ID >= 0 ? this.printing_1_Options[printing_1ID].locakey : '';
                this.selPrinting_1 = printing_1ID >= 0 ? this.printing_1_Options[printing_1ID].label : '';
                target.disabled = disablePrinting_1_Selection;
                target.selectedIndex = printing_1ID;
                target.dispatchEvent(e);
            }

            target = document.getElementById('IsCoatingCheckbox');
            if (target !== null)
            {
                target.disabled = disableCoatingSelection;
            }

            target = document.getElementById("MiddelMount_Selection");
            if (target != null)
            {
                this.selMiddleMountID = middleMountID;
                this.selMiddleMount = middleMountID >= 0 ? this.middleMountOptions[middleMountID].label : '';
                target.selectedIndex = middleMountID;
                target.disabled = disableMiddleMountSelection;
                target.dispatchEvent(e);
            }

            target = document.getElementById("TapeStop_Selection");
            if (target != null)
            {
                this.selTapeStopID = tapeStopID;
                this.selTapeStop = tapeStopID >= 0 ? this.tapeStopOptions[tapeStopID].label : '';
                target.selectedIndex = tapeStopID;
                target.disabled = disableTapeStopSelection;
                target.dispatchEvent(e);
            }

            target = document.getElementById("PackageImageSelection");
            if (target != null)
            {
                this.selPackageImageID = packageImageID;
                this.selPackageImage = packageImageID >= 0 ? this.PackageImageOptions[packageImageID] : '';
                target.selectedIndex = packageImageID;
                target.disabled = disablePackageImageSelection;
                target.dispatchEvent(e);
            }
        },
        onUpdateSelectedCutType(event)
        {
            if (event === null)
            {
                return;
            }

            if (event.target.selectedIndex === -1)
            {
                return;
            }

            let t;
            const e = new Event('change', { bubbles: true });

            let printing_1ID = 0;
            let printing_2ID = 0;
            let middleMountID = 0;
            let tapeStopID = 0;
            let packageImageID = 0;
            let disablePrinting_1_Selection = false;
            let disablePrinting_2_Selection = false;
            let disableMiddleMountSelection = false;
            let disableTapeStopSelection = false;
            let disablePackageImageSelection = false;
            let disableCoatingSelection = false;

            if (event.PreventUpdate !== undefined)
            {
                console.log(`[Step2](OnSelectedCutType) PackeTypeID: ${this.selPackageTypeID} CutTypeID: ${this.selCutTypeID}`);

                switch(this.selPackageTypeID)
                {
                    case 0:
                        switch(this.selCutTypeID)
                        {
                            case 0:
                            case 1:
                                this.printing_1_Options = [
                                    { locakey: "text.printing.option1", label: LocalizationManager.GetTranslation("text.printing.option1"), value: 0},
                                ];

                                this.printing_2_Options = [
                                    { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                ];

                                this.middleMountOptions = [
                                    { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                ];

                                this.tapeStopOptions = [
                                    { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                ];

                                if (this.selCutTypeID === 0)
                                {
                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_01_01.jpg", value: 0},
                                        {label: "Pkg_Img_01_02.jpg", value: 1},
                                    ];
                                }
                                else if (this.selCutTypeID === 1)
                                {
                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_02_01.jpg", value: 0},
                                    ];
                                }

                                t = document.getElementById("Printing_1_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.printing_1_Options, this.selPrinting_1ID);
                                    t.disabled = disablePrinting_1_Selection;
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("IsCoatingCheckbox");
                                if (t != null)
                                {
                                    t.checked = this.isCoating;
                                }

                                t = document.getElementById("MiddelMount_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.middleMountOptions, this.selMiddleMountID);
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("TapeStop_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.tapeStopOptions, this.selTapeStopID);
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }

                                if (this.PackageImageOptions.length > 0)
                                {
                                    this.$nextTick(()=>
                                    {
                                        t = document.getElementById("PackageImageSelection");
                                        if (t != null)
                                        {
                                            t.selectedIndex = this.Step2Data.PackageImageIndex;
                                            t.dispatchEvent(e);
                                        }
                                    });
                                    
                                }
                            break;
                        }
                    break;

                    case 1:
                        switch(this.selCutTypeID)
                        {
                            case 0:
                                this.printing_1_Options = [
                                    { locakey: "text.printing.option1", label: LocalizationManager.GetTranslation("text.printing.option1"), value: 0},
                                    { locakey: "text.printing.option2", label: LocalizationManager.GetTranslation("text.printing.option2"), value: 1},
                                    { locakey: "text.printing.option3", label: LocalizationManager.GetTranslation("text.printing.option3"), value: 2},
                                ];

                                this.printing_2_Options = [
                                    { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                ];

                                this.middleMountOptions = [
                                    { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                ];

                                this.tapeStopOptions = [
                                    { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                ];

                                t = document.getElementById("Printing_1_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.printing_1_Options, this.selPrinting_1ID);
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("IsCoatingCheckbox");
                                if (t != null)
                                {
                                    t.checked = this.isCoating;
                                }

                                t = document.getElementById("MiddelMount_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.middleMountOptions, this.selMiddleMountID);
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("TapeStop_Selection");
                                if (t != null)
                                {
                                    let index = this.GetOptionIndexOfID(this.tapeStopOptions, this.selTapeStopID);
                                    t.selectedIndex = index;
                                    t.dispatchEvent(e);
                                }
                            break;
                        }
                    break;

                    case 2:
                        switch(this.selCutTypeID)
                        {
                            case 0:

                            break;
                        }
                    break;
                }
            }
            else
            {
                this.selCutType = event.target.options[event.target.selectedIndex].label;
                this.key_cutType = event.target.options[event.target.selectedIndex].getAttribute('data-key');
                this.selCutTypeID = parseInt(event.target.options[event.target.selectedIndex].value);

                console.log(`[Step2](OnSelectedCutType) PackeTypeID: ${this.selPackageTypeID} CutTypeID: ${this.selCutTypeID}`);

                switch(this.selPackageTypeID)
                {
                    case 0:
                        switch(this.selCutTypeID)
                        {
                            case 0:
                            case 1:
                                this.printing_1_Options = [
                                    { locakey: "text.printing.option1", label: LocalizationManager.GetTranslation("text.printing.option1"), value: 0},
                                ];

                                this.printing_2_Options = [
                                    { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                ];

                                this.middleMountOptions = [
                                    { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                ];

                                this.tapeStopOptions = [
                                    { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                ];

                                if (this.selCutTypeID === 0)
                                {
                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_01_01.jpg", value: 0},
                                        {label: "Pkg_Img_01_02.jpg", value: 1},
                                    ];
                                }
                                else if (this.selCutTypeID === 1)
                                {
                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_02_01.jpg", value: 0},
                                    ];
                                }
                            break;
                        }
                    break;

                    case 1:
                        switch(this.selCutTypeID)
                        {
                            case 0:
                                this.printing_1_Options = [
                                    { locakey: "text.printing.option1", label: LocalizationManager.GetTranslation("text.printing.option1"), value: 0},
                                    { locakey: "text.printing.option2", label: LocalizationManager.GetTranslation("text.printing.option2"), value: 1},
                                    { locakey: "text.printing.option3", label: LocalizationManager.GetTranslation("text.printing.option3"), value: 2},
                                ];

                                this.printing_2_Options = [
                                    { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                ];

                                this.middleMountOptions = [
                                    { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                ];

                                this.tapeStopOptions = [
                                    { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                ];

                                t = document.getElementById("Printing_1_Selection");
                                if (t != null)
                                {
                                    t.selectedIndex = -1;
                                    this.selPrinting_1ID = 0;
                                    this.selPrinting_1 = this.printing_1_Options[0];
                                    this.key_print1 = this.printing_1_Options[0].locakey;
                                    console.log("[Step2](OnUpdateSelectedCutType)1 Set key_print1", this.key_print1);
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("IsCoatingCheckbox");
                                if (t != null)
                                {
                                    t.checked = this.isCoating;
                                }

                                t = document.getElementById("MiddelMount_Selection");
                                if (t != null)
                                {
                                    t.selectedIndex = 0;
                                    this.selMiddleMountID = 0;
                                    this.selMiddleMount = this.middleMountOptions[0].label;
                                    t.dispatchEvent(e);
                                }

                                t = document.getElementById("TapeStop_Selection");
                                if (t != null)
                                {
                                    t.selectedIndex = 0;
                                    this.selTapeStopID = 0;
                                    this.selTapeStop = this.tapeStopOptions[0];
                                    t.dispatchEvent(e);
                                }
                            break;

                            case 1:
                                this.printing_1_Options = [
                                    { locakey: "text.printing.option4", label: LocalizationManager.GetTranslation("text.printing.option4"), value: 0},
                                    { locakey: "text.printing.option5", label: LocalizationManager.GetTranslation("text.printing.option5"), value: 1},
                                ];

                                // ToDo: disable middle mount and tape stop options temporarily
                                //       until printing_1_option has been selected
                            break;

                            case 2:
                                this.printing_1_Options = [
                                { locakey: "text.printing.option1", label: LocalizationManager.GetTranslation("text.printing.option1"), value: 0},
                                ];

                                t = document.getElementById("Printing_1_Selection");
                                if (t != null)
                                {
                                    this.selPrinting_1ID = 0;
                                    this.selPrinting_1 = this.printing_1_Options[0];
                                    this.key_print1 = this.printing_1_Options[0].locakey;
                                    t.selectedIndex = 0;
                                    t.disabled = disablePrinting_1_Selection;
                                    t.dispatchEvent(e);
                                }
                            break;
                        }
                    break;

                    case 2:
                        // No options available for this package type
                        // switch(this.selCutTypeID)
                        // {
                        // }
                    break;
                }

                this.$nextTick(() => {
                    t = document.getElementById("Printing_1_Selection");
                    if (t != null)
                    {
                        this.selPrinting_1ID = printing_1ID;
                        this.selPrinting_1 = this.printing_1_Options[printing_1ID];
                        this.key_print1 = this.printing_1_Options[printing_1ID].locakey;
                        console.log("[Step2](OnSelectedCutType)2 Set key_print1", this.key_print1);
                        t.selectedIndex = printing_1ID;
                        t.disabled = disablePrinting_1_Selection;
                        t.dispatchEvent(e);
                    }

                    t = document.getElementById('Printing_2_Selection');
                    if (t !== null)
                    {
                        this.selPrinting_2ID = printing_2ID;
                        this.selPrinting_2 = this.printing_2_Options[printing_2ID];
                        this.key_print2 = this.printing_2_Options[printing_2ID].locakey;
                        console.log('Set key_print2', this.key_print2);
                        t.selectedIndex = printing_2ID;
                        t.disabled = disablePrinting_2_Selection;
                        t.dispatchEvent(e);
                    }

                    t = document.getElementById("IsCoatingCheckbox");
                    if (t != null)
                    {
                        t.checked = this.isCoating;
                        t.disabled = disableCoatingSelection;
                    }

                    t = document.getElementById("MiddelMount_Selection");
                    if (t != null)
                    {
                        this.selMiddleMountID = middleMountID;
                        this.selMiddleMount = this.middleMountOptions[0].label;
                        t.selectedIndex = middleMountID;
                        t.disabled = disableMiddleMountSelection;
                        t.dispatchEvent(e);
                    }

                    t = document.getElementById("TapeStop_Selection");
                    if (t != null)
                    {
                        this.selTapeStopID = tapeStopID;
                        this.selTapeStop = this.tapeStopOptions[0];
                        t.selectedIndex = tapeStopID;
                        t.disabled = disableTapeStopSelection;
                        t.dispatchEvent(e);
                    }

                    if (this.PackageImageOptions.length > 0)
                    {
                        this.$nextTick(()=>
                        {
                            t = document.getElementById("PackageImageSelection");
                            if (t != null)
                            {
                                this.selPackageImageID = packageImageID;
                                this.selPackageImage = this.PackageImageOptions[0].label;
                                t.selectedIndex = packageImageID;
                                t.disabled = disablePackageImageSelection;
                                t.dispatchEvent(e);
                            }
                        });
                        
                    }
                });
            }
        },
        onUpdateSelectedWrapping(event)
        {
            if (event==null)
            {
                return;
            }
            
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selWrapping = event.target.options[event.target.selectedIndex].label;
            this.key_wrapping = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selWrappingID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPrinting_1(event)
        {
            if (event == null)
            {
                return;
            }

            if (event.target.selectedIndex == -1)
            {
                return;
            }

            
            this.selPrinting_1 = event.target.options[event.target.selectedIndex].label;
            this.key_print1 = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPrinting_1ID = parseInt(event.target.options[event.target.selectedIndex].value);

            let t;
            const e = new Event('change', { bubbles: true });

            console.log(`[Step2](OnSelectedPrinting_1) PackageTypeID: ${this.selPackageTypeID} CutTypeID: ${this.selCutTypeID} Print1ID: ${this.selPrinting_1ID}`);

            switch(this.selPackageTypeID)
            {
                case 1:

                    switch(this.selCutTypeID)
                    {
                        case 0:
                            
                            switch(this.selPrinting_1ID)
                            {
                                case 0:
                                    this.printing_2_Options = [
                                      { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                    ];

                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.tapeStopOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_03_01.png", value: 0},
                                        {label: "Pkg_Img_03_02.png", value: 1},
                                        {label: "Pkg_Img_03_03.png", value: 2},
                                        {label: "Pkg_Img_03_04.jpg", value: 3},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }
                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selPackageImageID = 0;
                                        this.selPackageImage = this.PackageImageOptions[0].label;
                                        this.$nextTick(()=>{
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;

                                case 1:
                                    this.printing_2_Options = [
                                      { locakey: "text.coating.none", label: LocalizationManager.GetTranslation("text.coating.none"), value: 0}
                                    ];

                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.tapeStopOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_04_01.jpg", value: 0},
                                        {label: "Pkg_Img_04_02.png", value: 1},
                                        {label: "Pkg_Img_04_03.png", value: 2},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selPackageImageID = 0;
                                        this.selPackageImage = this.PackageImageOptions[0].label;
                                        this.$nextTick(()=>{
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;

                                case 2:
                                    this.printing_2_Options = [
                                        { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                    ];
                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];
                                    this.tapeStopOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];
                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_05_01.png", value: 0},
                                        {label: "Pkg_Img_05_02.png", value: 1},
                                        {label: "Pkg_Img_05_03.png", value: 2},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = -1;
                                        this.selPackageImageID = -1;
                                        this.selPackageImage = '';
                                        this.$nextTick(()=>{
                                            e.selectedIndex = 0;
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;
                            }
                        break;

                        case 1:
                            switch(this.selPrinting_1ID)
                            {
                                case 0: 
                                    this.printing_2_Options = [
                                      { locakey: "text.coating.none", label: LocalizationManager.GetTranslation("text.coating.none"), value: 0}
                                    ];

                                    this.isCoating = false;
                                    this.isCoatingDisabled = true;

                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.tapeStopOptions = [
                                        { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                    ];

                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_06_01.jpg", value: 0},
                                        {label: "Pkg_Img_06_02.png", value: 1},
                                        {label: "Pkg_Img_06_03.png", value: 2},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selPackageImageID = 0;
                                        this.selPackageImage = this.PackageImageOptions[0].label;
                                        this.$nextTick(()=>{
                                            e.selectedIndex = 0;
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;

                                case 1:
                                    this.printing_2_Options = [
                                      { locakey: "text.coating.with", label: LocalizationManager.GetTranslation("text.coating.with"), value: 0}
                                    ];

                                    this.isCoating = true;
                                    this.isCoatingDisabled = true;

                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.tapeStopOptions = [
                                        { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                                    ];

                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_07_01.jpg", value: 0},
                                        {label: "Pkg_Img_07_02.png", value: 1},
                                        {label: "Pkg_Img_07_03.jpg", value: 2},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        e.selectedIndex = 0;
                                        t.selectedIndex = 0;
                                        this.selPackageImageID = 0;
                                        this.selPackageImage = this.PackageImageOptions[0].label;
                                        this.$nextTick(()=>{
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;
                            }
                        break;

                        case 2:
                            switch(this.selPrinting_1ID)
                            {
                                case 0:
                                    this.printing_2_Options = [
                                      { locakey: "text.coating.none", label: LocalizationManager.GetTranslation("text.coating.none"), value: 0}
                                    ];

                                    this.isCoating = true;
                                    this.isCoatingDisabled = true;

                                    this.middleMountOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.tapeStopOptions = [
                                        { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                                    ];

                                    this.PackageImageOptions = [
                                        {label: "Pkg_Img_08_01.png", value: 0},
                                        {label: "Pkg_Img_08_02.jpg", value: 1},
                                    ];

                                    t = document.getElementById("MiddelMount_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selMiddleMountID = 0;
                                        this.selMiddleMount = this.middleMountOptions[0].label;
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("TapeStop_Selection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selTapeStopID = 0;
                                        this.selTapeStop = this.tapeStopOptions[0];
                                        t.dispatchEvent(e);
                                    }

                                    t = document.getElementById("PackageImageSelection");
                                    if (t != null)
                                    {
                                        t.selectedIndex = 0;
                                        this.selPackageImageID = 0;
                                        this.selPackageImage = this.PackageImageOptions[0].label;
                                        this.$nextTick(()=>{
                                            e.selectedIndex = 0;
                                            t.dispatchEvent(e);
                                        });
                                    }
                                break;
                            }
                        break;
                    }
                break;

                case 2:
                    switch (this.selPrinting_1ID)
                    {
                        case 0:
                        case 1:
                            this.isCoating = true;
                            this.isCoatingDisabled = true;

                            this.middleMountOptions = [
                                { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                            ];

                            this.tapeStopOptions = [
                                { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                            ];

                            this.PackageImageOptions = [
                                {label: "Pkg_Img_09_01.png", value: 0},
                                {label: "Pkg_Img_09_02.jpg", value: 1},
                                {label: "Pkg_Img_09_03.jpg", value: 2},
                            ];
                        break;

                        case 2:
                        case 3:
                            this.isCoating = false;
                            this.isCoatingDisabled = true;

                            this.middleMountOptions = [
                                { locakey: "text.none", label: LocalizationManager.GetTranslation("text.none"), value: 0}
                            ];

                            this.tapeStopOptions = [
                                { locakey: "text.with", label: LocalizationManager.GetTranslation("text.with"), value: 0}
                            ];

                            this.PackageImageOptions = [
                                {label: "Pkg_Img_10_01.jpg", value: 0},
                            ];
                        break;
                    }

                    t = document.getElementById("MiddelMount_Selection");
                    if (t != null)
                    {
                        t.selectedIndex = 0;
                        this.selMiddleMountID = 0;
                        this.selMiddleMount = this.middleMountOptions[0].label;
                        t.dispatchEvent(e);
                    }

                    t = document.getElementById("TapeStop_Selection");
                    if (t != null)
                    {
                        t.selectedIndex = 0;
                        this.selTapeStopID = 0;
                        this.selTapeStop = this.tapeStopOptions[0];
                        t.dispatchEvent(e);
                    }

                    t = document.getElementById("PackageImageSelection");
                    if (t != null)
                    {
                        t.selectedIndex = 0;
                        this.selPackageImageID = 0;
                        this.selPackageImage = this.PackageImageOptions[0].label;
                        this.$nextTick(()=>{
                            e.selectedIndex = 0;
                            t.dispatchEvent(e);
                        });
                    }
                break;
            }
            
        },
        onUpdateSelectedPrinting_2(event)
        {
            if (event==null){
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selPrinting_2 = event.target.options[event.target.selectedIndex].label;
            this.key_print2 = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPrinting_2ID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedIsCoating(event)
        {
            if (event == null)
            {
                return;
            }

            this.isCoating = event.target.checked;
        },
        onUpdateSelectedPMPrinting(event)
        {
            if (event == null)
            {
                return;
            }

            if (event.target.selectedIndex === -1)
            {
                return;
            }

            this.selPrinting_pm = event.target.options[event.target.selectedIndex].label;
            this.key_printpm = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPrinting_pmID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedMiddleMount(event)
        {
            if (event==null)
            {
                return;
            }

            if (event.target.selectedIndex === -1)
            {
                return;
            }

            this.selMiddleMount = event.target.options[event.target.selectedIndex].label;
            this.selMiddleMountID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedTapeStop(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selTapeStop = event.target.options[event.target.selectedIndex].label;
            this.selTapeStopID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedCertStamp(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selCertStamp = event.target.options[event.target.selectedIndex].label;
            this.key_certStamp = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selCertStampID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedLtdSticker(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selLtdSticker = event.target.options[event.target.selectedIndex].label;
            this.selLtdStickerID = parseInt(event.target.options[event.target.selectedIndex].value);

            console.log("[onUpdateSelectedLtdSticker] OR THIS ONE??");

            // Load image
            let target = document.getElementById("LimitedPreview");
            if (target != null)
            {
                switch (this.selLtdStickerID)
                {
                    case 0:
                        this.selLtdSticker = "Limited_Sticker.jpg";
                    break;

                    case 1:
                        this.selLtdSticker = "Godzilla_Store_Sticker.jpg";
                    break;

                    case 2:
                        this.selLtdSticker = "";
                    break;
                }
            }

            target.src = "/assets/" + this.selLtdSticker;
        },
        onUpdateSelectedPackageMaterial(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selPackageMaterial = event.target.options[event.target.selectedIndex].label;
            this.key_pkgMaterial = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPackageMaterialID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPackage1(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            this.selPackage1 = event.target.options[event.target.selectedIndex].label;
            this.key_pkg1 = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPackage1ID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPackage2(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            this.selPackage2 = event.target.options[event.target.selectedIndex].label;
            this.key_pkg2 = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPackage2ID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPackage3(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            this.selPackage3 = event.target.options[event.target.selectedIndex].label;
            this.key_pkg3 = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selPackage3ID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPMPackage(event)
        {
            if (event == null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }
            
            var selOpt = event.target.options[event.target.selectedIndex];
            this.selPackage_pm = selOpt.label;
            this.key_pkg_pm = selOpt.getAttribute('data-key');
            this.selPackage_pmID = parseInt(selOpt.value);
        },
        onUpdateSelectedSticker(event)
        {
            if (event == null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            console.log("[onUpdateSelectedSticker] THIS ONE??");

            switch(event.target.options[event.target.selectedIndex].value)
            {
                case 0:
                    this.selSticker = "Limited_Sticker.jpg";
                break;

                case 1:
                    this.selSticker = "Godzilla_Store_Sticker.jpg";
                break;

                default:
                    this.selSticker = "";
                    break;
            }
            
            this.selStickerID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedInputAmount(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selInputAmount = event.target.options[event.target.selectedIndex].label;
            this.selInputAmountID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedInner(event)
        {
            if (event==null)
            {
                return;
            }
            if (event.target.selectedIndex == -1)
            {
                return;
            }

            this.selInner = event.target.options[event.target.selectedIndex].label;
            this.key_selInner = event.target.options[event.target.selectedIndex].getAttribute('data-key');
            this.selInnerID = parseInt(event.target.options[event.target.selectedIndex].value);
        },
        onUpdateSelectedPackageImage(event)
        {
            if (event == null)
            {
                return;
            }

            if (event.PreventUpdate != undefined)
            {
                return;
            }

            if (this.PackageImageOptions.length == 0)
            {
                return;
            }

            console.log(`[Step2](onUpdateSelectedPackageImage) Event`, event);
            console.log("[onUpdateSelectedPackageImage] event.target.selectedIndex", event.target.selectedIndex);
            console.log("[onUpdateSelectedPackageImage] event.selectedIndex", event.selectedIndex);
            console.log("[onUpdateSelectedPackageImage] event.target.options", event.target.options);
            console.log("[onUpdateSelectedPackageImage] this.PackageImageOption", this.PackageImageOptions);

            if (Object.hasOwn(event, 'selectedIndex') === true)
            {
                this.selPackageImageID = parseInt(this.PackageImageOptions[event.selectedIndex].value);
                event.target.selectedIndex = event.selectedIndex;
                event.target.options.selectedIndex = event.selectedIndex;
            }
            else if (event.target.selectedIndex >= 0)
            {
                this.selPackageImageID = parseInt(event.target.options[event.target.selectedIndex].value);    
            }
            else
            {
                this.selPackageImageID = event.target.selectedIndex;
            }
            
            console.trace("Sel image option: " + this.selPackageImageID + " El: " + event.target.selectedIndex);

            let target = document.getElementById("PackagePreview");
            if (this.selPackageImageID >= 0)
            {
                for (let i = 0; i < this.PackageImageOptions.length; i++)
                {
                    if (this.PackageImageOptions[i].value === this.selPackageImageID)
                    {
                        this.selPackageImage = this.PackageImageOptions[i].label;
                        break;
                    }
                }

                // Load image
                if (target != null)
                {
                    target.src = "";
                    target.src = "/assets/PkgImg/" + this.selPackageImage;
                }
            }
            else
            {
                console.log('could not set image preview');
                // Reset image
                if (target != null)
                {
                    target.src = "";
                }
                this.selPackageImageID = -1;
            }
        },
        SaveStepData()
        {
            var d = 
            {
                PackageType: this.selPackageType,
                Key_PackageType: this.key_packageType,
                PackageTypeID: this.selPackageTypeID,
                CutType: this.selCutType,
                Key_CutType: this.key_cutType,
                CutTypeID: this.selCutTypeID,
                Wrapping: this.selWrapping,
                Key_Wrapping: this.key_wrapping,
                WrappingID: this.selWrappingID,
                Print1: this.selPrinting_1,
                Key_Print1: this.key_print1,
                Print1ID: this.selPrinting_1ID,
                Print2: this.selPrinting_2,
                Key_Print2: this.key_print2,
                Print2ID: this.selPrinting_2ID,
                IsCoating: this.isCoating,
                PrintPM: this.selPrinting_pm,
                Key_PrintPM: this.key_printpm,
                PrintPMID: this.selPrinting_pmID,
                MiddleMount: this.selMiddleMount,
                MiddleMountID: this.selMiddleMountID,
                TapeStop: this.selTapeStop,
                TapeStopID: this.selTapeStopID,
                CertStamp: this.selCertStamp,
                Key_CertStamp: this.key_certStamp,
                CertStampID: this.selCertStampID,
                LtdSticker: this.selLtdSticker,
                LtdStickerID: this.selLtdStickerID,
                SetContent: this.selSetContent,
                SetContentID: this.selSetContentID,
                PackageMaterial: this.selPackageMaterial,
                Key_PackageMaterial: this.key_pkgMaterial,
                PackageMaterialID: this.selPackageMaterialID,
                Package1: this.selPackage1,
                Key_Package1: this.key_pkg1,
                Package1ID: this.selPackage1ID,
                Package2: this.selPackage2,
                Key_Package2: this.key_pkg2,
                Package2ID: this.selPackage2ID,
                Package3: this.selPackage3,
                Key_Package3: this.key_pkg3,
                Package3ID: this.selPackage3ID,
                PackagePM: this.selPackage_pm,
                Key_PackagePM: this.key_pkg_pm,
                PackagePMID: this.selPackage_pmID,
                Sticker: this.selSticker,
                StickerID: this.selStickerID,
                InputAmount: this.selInputAmount,
                InputAmountID: this.selInputAmountID,
                Inner: this.selInner,
                Key_Inner: this.key_selInner,
                InnerID: this.selInnerID,
                PackageImage: this.selPackageImage,
                PackageImageID: this.selPackageImageID,
            };
            console.log("Save step data 2", d);
            this.saveStep2Data(d);
        },
        LoadStepData()
        {
            console.log("Load step 2 data", this.Step2Data);
            this.selPackageType = this.Step2Data.PackageType;
            this.key_packageType = this.Step2Data.Key_PackageType;
            this.selPackageTypeID = this.Step2Data.PackageTypeID;
            this.selCutType = this.Step2Data.CutType;
            this.key_cutType = this.Step2Data.Key_CutType;
            this.selCutTypeID = this.Step2Data.CutTypeID;
            this.selWrapping = this.Step2Data.Wrapping;
            this.key_wrapping = this.Step2Data.Key_Wrapping;
            this.selWrappingID = this.Step2Data.WrappingID;
            this.selPrinting_1 = this.Step2Data.Print1;
            this.key_print1 = this.Step2Data.Key_Print1;
            this.selPrinting_1ID = this.Step2Data.Print1ID;
            this.selPrinting_2 = this.Step2Data.Print2;
            this.key_print2 = this.Step2Data.Key_Print2;
            this.selPrinting_2ID = this.Step2Data.Print2ID;
            this.isCoating = this.Step2Data.IsCoating;
            this.selPrinting_pm = this.Step2Data.PrintPM;
            this.key_printpm = this.Step2Data.Key_PrintPM;
            this.selPrinting_pmID = this.Step2Data.PrintPMID;
            this.selMiddleMount = this.Step2Data.MiddleMount;
            this.selMiddleMountID = this.Step2Data.MiddleMountID;
            this.selTapeStop = this.Step2Data.TapeStop;
            this.selTapeStopID = this.Step2Data.TapeStopID;
            this.selCertStamp = this.Step2Data.CertStamp;
            this.key_certStamp = this.Step2Data.Key_CertStamp;
            this.selCertStampID = this.Step2Data.CertStampID;
            this.selLtdSticker = this.Step2Data.LtdSticker;
            this.selLtdStickerID = this.Step2Data.LtdStickerID;
            this.selSetContent = this.Step2Data.SetContent;
            this.selSetContentID = this.Step2Data.SetContentID;
            this.selPackageMaterial = this.Step2Data.PackageMaterial;
            this.key_pkgMaterial = this.Step2Data.Key_PackageMaterial;
            this.selPackageMaterialID = this.Step2Data.PackageMaterialID;
            this.selPackage1 = this.Step2Data.Package1;
            this.key_pkg1 = this.Step2Data.Key_Package1;
            this.selPackage1ID = this.Step2Data.Package1ID;
            this.selPackage2 = this.Step2Data.Package2;
            this.key_pkg2 = this.Step2Data.Key_Package2;
            this.selPackage2ID = this.Step2Data.Package2ID;
            this.selPackage3 = this.Step2Data.Package3;
            this.key_pkg3 = this.Step2Data.Key_Package3;
            this.selPackage_pm = this.Step2Data.PackagePM;
            this.key_pkg_pm = this.Step2Data.Key_PackagePM;
            this.selPackage_pmID = this.Step2Data.PackagePMID;
            this.selPackage3ID = this.Step2Data.Package3ID;
            this.selSticker = this.Step2Data.Sticker;
            this.selStickerID = this.Step2Data.StickerID;
            this.selInputAmount = this.Step2Data.InputAmount;
            this.selInputAmountID = this.Step2Data.InputAmountID;
            this.selInner = this.Step2Data.Inner;
            this.selInnerID = this.Step2Data.InnerID;
            this.key_selInner = this.Step2Data.Key_Inner;
            this.selPackageImage = this.Step2Data.PackageImage;
            this.selPackageImageID = this.Step2Data.PackageImageID;
        },
        async GetPackageMaterials()
        {
            let data = 
            {
                SessionToken: localStorage.getItem('session_token'),
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
            };

            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "packagematerials", data)
            .then(async response =>
            {
                console.log("Received package materials", response);
                this.packageMaterialOptions = [];
                try
                {
                    this.packageMaterialOptions = response.data;

                    if (this.packageMaterialOptions.length === 1)
                    {
                        this.selPackageMaterial = this.packageMaterialOptions[0].label;
                        this.key_pkgMaterial = this.packageMaterialOptions[0].locakey;
                        this.selPackageMaterialID = this.packageMaterialOptions[0].value;

                        setTimeout(() =>
                        {
                            const event = new Event('change', { bubbles: true });
                            event.PreventUpdate = true;

                            let sel = document.getElementById("PackageMaterialSelection");
                            sel.selectedIndex = 0;
                            sel.dispatchEvent(event);    
                        }, 10);
                    }

                    console.log("Assigned package material options", this.packageMaterialOptions);
                    if (LocalizationManager.IsLocaInitialized == false)
                    {
                        await this.WaitForLocalizationInit();
                        this.TranslatePackageMaterials();
                        // callback(true);
                        return true;
                    }
                    else
                    {
                        this.TranslatePackageMaterials();
                        // callback(true);
                        return true;
                    }
                }
                catch(error)
                {
                    console.error(error);
                    // callback(false);
                    return false;
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                // callback(false);
                return false;
            });

        },
        async GetPackaging()
        {
            let data = 
            {
                SessionToken: localStorage.getItem('session_token'),
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
            };

            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "packaging", data)
            .then(async response =>
            {
                console.log("Received packaging", response);
                this.package_pm_Options = [];
                try
                {
                    this.package_pm_Options = response.data;

                    if (this.package_pm_Options.length === 1)
                    {
                        this.selPackage_pm = this.package_pm_Options[0].label;
                        this.key_pkg_pm = this.package_pm_Options[0].locakey;
                        this.selPackage_pmID = this.package_pm_Options[0].value;

                        setTimeout(() =>
                        {
                            const event = new Event('change', { bubbles: true });
                            event.PreventUpdate = true;

                            let sel = document.getElementById("Package_pm_Selection");
                            sel.selectedIndex = 0;
                            sel.dispatchEvent(event);    
                        }, 10);
                    }

                    console.log("Assigned packaging options", this.package_pm_Options);
                    if (LocalizationManager.IsLocaInitialized == false)
                    {
                        await this.WaitForLocalizationInit();
                        this.TranslatePackaging();
                        // callback(true);
                        return true;
                    }
                    else
                    {
                        this.TranslatePackaging();
                        // callback(true);
                        return true;
                    }
                }
                catch(error)
                {
                    console.error(error);
                    // callback(false);
                    return false;
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                // callback(false);
                return false;
            });

        },
        async GetCartonInsideCount()
        {
            let data = 
            {
                SessionToken: localStorage.getItem('session_token'),
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
            };

            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "cartoninsidecount", data)
            .then(async response =>
            {
                console.log("Received carton inside count", response);
                this.inputAmountOptions = [];
                try
                {
                    this.inputAmountOptions = response.data;
                    // callback(true);
                    return true;
                }
                catch(error)
                {
                    console.error(error);
                    // callback(false);
                    return false
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                // callback(false);
                return false;
            });
        },
        async GetCartonInner()
        {
            let data = 
            {
                SessionToken: localStorage.getItem('session_token'),
                SelectedProductTypeID: this.Step1Data.ProductSpecificationID,
            };

            await axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "cartoninner", data)
            .then(async response =>
            {
                console.log("Received carton inner", response);
                this.innerOptions = [];
                try
                {
                    this.innerOptions = response.data;
                    if (this.innerOptions.length === 1)
                    {
                        console.log("set carton inner automatically as it's just one option");
                        this.selInner = this.innerOptions[0].label;
                        this.key_selInner = this.innerOptions[0].locakey;
                        this.selInnerID = this.innerOptions[0].value;
                        
                        setTimeout(() =>
                        {
                            const event = new Event('change', { bubbles: true });
                            event.PreventUpdate = true;

                            let innerSel = document.getElementById("InnerSelection");
                            innerSel.selectedIndex = 0;
                            innerSel.dispatchEvent(event);    
                        }, 10);
                        
                    }

                    if (LocalizationManager.IsLocaInitialized == false)
                    {
                        await this.WaitForLocalizationInit();
                        this.TranslateCartonInnerOptions();
                        // callback(true);
                        return true;
                    }
                    else
                    {
                        this.TranslateCartonInnerOptions();
                        // callback(true);
                        return true;
                    }
                }
                catch(error)
                {
                    console.error(error);
                    // callback(false);
                    return false;
                }
            })
            .catch(error =>
            {
                console.log("Received error", error);
                // callback(false);
                return false;
            });
        },
        TranslateCartonInnerOptions()
        {
            for (var i = 0; i < this.innerOptions.length; i++)
            {
                this.innerOptions[i] = ({ locakey: this.innerOptions[i].label, label: LocalizationManager.GetTranslation(this.innerOptions[i].label), value: this.innerOptions[i].value });
            }
        },
        TranslatePackageMaterials()
        {
            for (var i = 0; i < this.packageMaterialOptions.length; i++)
            {
                this.packageMaterialOptions[i] = ({ locakey: this.packageMaterialOptions[i].label, label: LocalizationManager.GetTranslation(this.packageMaterialOptions[i].label), value: this.packageMaterialOptions[i].value });
            }
        },
        TranslatePackaging()
        {
            for (var i = 0; i < this.package_pm_Options.length; i++)
            {
                this.package_pm_Options[i] = ({ locakey: this.package_pm_Options[i].label, label: LocalizationManager.GetTranslation(this.package_pm_Options[i].label), value: this.package_pm_Options[i].value });
            }
        },
        GetOptionIndexOfID(optionArray, id)
        {
            for(let i = 0; i < optionArray.length; i++)
            {
                if (optionArray[i].value == id)
                {
                    return i;
                }
            }

            return -1;
        },
        ...mapActions(['saveStep2Data','saveToken']),
        GoToNextStep()
        {
            this.SaveStepData();
            this.$router.push('/step3');
        },
        GoBackStep()
        {
            this.SaveStepData();
            this.$router.push('/step1');
        }
    },
    data()
    {
        return{
            IsInitialized: false,
            IsPlasticModelKit: false,
            isCoatingDisabled: false,
            selPackageType: '',
            key_packageType: '',
            selPackageTypeID: -1,
            selCutType: '',
            key_cutType: '',
            selCutTypeID: -1,
            selWrapping: '',
            key_wrapping: '',
            selWrappingID: -1,
            selPrinting_1: '',
            key_print1: '',
            selPrinting_1ID: -1,
            selPrinting_2: '',
            key_print2: '',
            selPrinting_2ID: -1,
            isCoating: false,
            selPrinting_pm: '',
            key_printpm: '',
            selPrinting_pmID: -1,
            selMiddleMount: '',
            selMiddleMountID: -1,
            selTapeStop: '',
            selTapeStopID: -1,
            selCertStamp: '',
            key_certStamp: '',
            selCertStampID: -1,
            selLtdSticker: '',
            selLtdStickerID: -1,
            selSetContent: '',
            selSetContentID: -1,
            selPackageMaterial: '',
            key_pkgMaterial: '',
            selPackageMaterialID: -1,
            selPackage1: '',
            key_pkg1: '',
            selPackage1ID: -1,
            selPackage2: '',
            key_pkg2: '',
            selPackage2ID: -1,
            selPackage3: '',
            key_pkg3: '',
            selPackage3ID: -1,
            selPackage_pm: '',
            key_pkg_pm: '',
            selPackage_pmID: -1,
            selSticker: '',
            selStickerID: -1,
            selInputAmount: '',
            selInputAmountID: -1,
            selInner: '',
            key_selInner: '',
            selInnerID: -1,
            selPackageImage: '',
            selPackageImageID: -1,
            PackageImageOptions: [],
            packageMaterialOptions:[
                { locakey: "text.paper", label: "紙", value: 0},
                { locakey: "text.cardboard", label: "段ボール", value: 1}
            ],
            package1Options:[
                { locakey: "text.transparent_tray_cover", label: "透明トレイ、透明カバー", value: 0},
                { locakey: "text.transparent_tray_fixwire", label: "透明トレイ、固定ワイヤー", value: 1}
            ],
            package2Options:[
                { locakey: "text.transparent_sheet", label: "透明シート", value: 0},
                { locakey: "text.transparent_sheet_bag", label: "透明シート、袋", value: 1}
            ],
            package3Options:[
                { locakey: "text.transparentpacking", label: "透明パッキン有り", value: 0},
                { locakey: "text.notransparentpacking", label: "透明パッキン無し", value: 1}
            ],
            package_pm_Options:[],
            stickerOptions:[
                { locakey: "text.ltdsticker", label: "限定版シール", value: 0},
                { locakey: "text.godzillastoresticker", label: "ゴジラストア限定シール", value: 1},
                { locakey: "text.none", label: "無し", value: 2}
            ],
            inputAmountOptions:[
                {label: "1", value: 0},
                {label: "2", value: 1},
                {label: "4", value: 2},
                {label: "6", value: 3},
                {label: "12", value: 4}
            ],
            innerOptions:[
                { locakey: "text.with", label: "有り", value: 0},
                { locakey: "text.none", label: "無し", value: 1}
            ],
            packageOptions:[
                { locakey: "text.pkgspec.window", label: "ウィンドウ", value: 0},
                { locakey: "text.pkgspec.closed", label: "クローズド", value: 1},
                { locakey: "text.pkgspec.header", label: "ヘッダー", value: 2}
            ],
            cutTypeOptions:[
                { locakey: "text.cut.top_lid", label: "天面蓋", value: 0},
                { locakey: "text.cut.with_door", label: "扉付き", value: 1},
                { locakey: "text.cut.roll_lid_open", label: "巻き蓋開き", value: 2},
                { locakey: "text.cut.lid_bottom_box", label: "蓋と下箱", value: 3},
            ],
            wrappingOptions:[
                { locakey: "text.necessary", label: "必要", value: 0},
                { locakey: "text.unnecessary", label: "不要", value: 1},
            ],
            printing_1_Options:[],
            printing_2_Options:[
                { locakey: "text.coating.with", label: "コーティング有り", value: 0},
                { locakey: "text.coating.none", label: "コーティング無し", value: 1}
            ],
            printing_pm_Options:[
                { locakey: "text.coating.pm.with", label: "4Cコーティング有り", value: 0},
                { locakey: "text.coating.pm.none", label: "4Cコーティング無し", value: 1}
            ],
            middleMountOptions:[
                { locakey: "text.with", label: "有り", value: 0},
                { locakey: "text.none", label: "無し", value: 1}
            ],
            tapeStopOptions:[
                { locakey: "text.with", label: "有り", value: 0},
                { locakey: "text.none", label: "無し", value: 1}
            ],
            certStampOptions:[
                { locakey: "text.none", label: "無し", value: 0},
                { locakey: "text.certstamp", label: "シール貼り", value: 1},
                { locakey: "text.packageprint", label: "PKG印刷", value: 2}
            ],
            setContentOptions:[
                { locakey: "text.necessary", label: "必要", value: 0},
                { locakey: "text.unnecessary", label: "不要", value: 1}
            ],
            ltdStickerOptions:[
                { locakey: "text.ltdsticker", label: "限定版シール", value: 0},
                { locakey: "text.godzillastoresticker", label: "ゴジラストア限定シール", value: 1},
                { locakey: "text.none", label: "無し", value: 2}
            ]
        }
    }
}
</script>

<style>
#PackageImageSelectionContainer
{
    display: flex;
}

#PackageImagePreview
{
    display: grid;
    align-items: center;
    justify-content: center;
}

#LimitedVersionImagePreview
{
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 33px;
}

.PreviewImage
{
    width: auto;
    height: 150px;
}

.ImagePreviewSelection
{
    flex: 1;
}
</style>