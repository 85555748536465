<template>
    <div id="Wrapper" style="overflow: visible;">
        <div id="SaveTranslationButtonContainer" style="margin-bottom: 10px;">
            <button id="SaveTranslationButton" class="Button ButtonGreen localize" data-key="text.save" style="float: right;" @click="SaveTranslations">Save</button>
        </div>
        <table id="TranslationTable">
        </table>
        <div style="margin-top: 80px;"></div>
    </div>
</template>

<script>
import axios from 'axios';
import { InitDarkmode, ApplyDarkmode } from './Darkmode.js';
// import TermTranslationEntry from './components/TermTranslationEntry.vue';
import { mapActions } from 'vuex';
import { LocalizationManager } from './Localization.js';
import { ShowLoading } from './FullScreenLoading.js';

export default
{
    name: 'App',
    mounted(){
    document.title = "EZ-SPEC-Term translations";

    if (localStorage.getItem('session_token') == null)
    {
        this.$router.push('/');
        return;
    }
    else
    {
        this.saveToken(localStorage.getItem('session_token'));
    }

    var saveBtnContainer = document.getElementById('SaveTranslationButtonContainer');
    let stickyObserver = new IntersectionObserver(([e]) => e.target.classList.toggle('IsSaveBtnPinned', e.intersectionRatio < 1), {threshold: [1]});
    stickyObserver.observe(saveBtnContainer);

    if (LocalizationManager.IsLocaInitialized == false)
    {
        LocalizationManager.Initialize();
    }

    this.$nextTick(()=>
    {
        LocalizationManager.TranslatePage();
    });

    this.RetrieveTranslations();

    this.$nextTick(()=>{
        InitDarkmode();
    });
  },
  components:{
    // TermTranslationEntry
  },
  methods:{
    GetTermType(termType)
    {
        console.log('GetTermType',termType);
        if(termType=='1')
        {
            return 'UI';
        }

        return "Spec-Sheet";
    },
    RetrieveTranslations()
    {
        ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

        var sheetEntries = document.getElementsByClassName("SheetEntryContainer");
        for(var i = sheetEntries.length - 1; i >= 0; i--)
        {
            sheetEntries[i].remove();
        }

        let data = 
        {
            SessionToken: localStorage.getItem('session_token')
        };
        
        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "termtranslations", data)
        .then(response => 
        {
            console.log("Received translation data.", response);
            
            ShowLoading(false);

            this.SupportedLanguages = response.data.supportedLanguages;
            this.TermTypes = response.data.termTypes;
            this.TranslationData = response.data.translations;

            this.TranslationData.sort(function(a,b)
            {
                // Split the keys into parts
                const aParts = a.Key.split('.');
                const bParts = b.Key.split('.');

                // Find the first index with different content (excluding numbers)
                let diffIndex = -1;
                for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
                    if (isNaN(aParts[i]) && isNaN(bParts[i]) && aParts[i] !== bParts[i]) {
                    diffIndex = i;
                    break;
                    }
                }

                // If prefixes are identical, compare numbers
                if (diffIndex === -1) {
                    const aNumber = parseFloat(aParts.slice(-1)[0]);
                    const bNumber = parseFloat(bParts.slice(-1)[0]);
                    if (!isNaN(aNumber) && !isNaN(bNumber)) {
                    return aNumber - bNumber;
                    }
                }

                // Otherwise, compare strings from the point of difference
                if (diffIndex !== -1) {
                    return aParts[diffIndex].localeCompare(bParts[diffIndex]);
                }

                // Fallback: identical keys (shouldn't happen often)
                return 0;
            });

            let translationTable = document.getElementById("TranslationTable");

            for(let i = 0; i < this.TermTypes.length; i++)
            {
                if (this.TermTypes[i].ID == 1)
                {
                    continue;
                }
                let headerRow = document.createElement("tr");
                translationTable.append(headerRow);

                let header = document.createElement("th");
                header.innerHTML = this.TermTypes[i].TypeName + " - " + LocalizationManager.GetTranslation("text.translations");
                header.classList.add("TableHeader");
                header.colSpan = "4";
                headerRow.append(header);

                for(let j = 0; j < this.TranslationData.length; j++)
                {
                    let entry = this.TranslationData[j];
                    if (entry.TermType != this.TermTypes[i].ID)
                    {
                        continue;
                    }

                    let locaKey = entry.Key;
                    let termType = entry.TermType;
                    let row = document.createElement("tr");
                    row.id = locaKey + "_" + termType;
                    row.classList.add("TranslationRow");
                    translationTable.append(row);

                    var keyColumn = document.createElement("td");
                    keyColumn.innerHTML = entry.Key;
                    keyColumn.classList.add("KeyColumn");
                    row.append(keyColumn);

                    if (row != null)
                    {
                        for(let k = 0; k < entry.Translations.length; k++)
                        {
                            let translation = entry.Translations[k];

                            var newTd = document.createElement("td");
                            newTd.setAttribute('language', translation.Language);

                            var inp = document.createElement("input");
                            inp.setAttribute("orig", translation.Translation);
                            inp.setAttribute('translationid', translation.ID);
                            inp.setAttribute('language', translation.Language);
                            inp.classList.add("TranslationInput");

                            if (translation.Translation == '')
                            {
                                inp.placeholder = this.GetLanguageName(translation.Language);
                            }
                            else
                            {
                                inp.value = translation.Translation;
                            }
                            
                            inp.oninput = this.OnTranslationUpdate;
                            newTd.append(inp);
                            row.append(newTd);
                        }
                    }
                }
            }

            ApplyDarkmode();
        })
        .catch(error =>{
            console.log("Received error.", error);
            ShowLoading(false);
            
            this.$HandleBadRequest(error.response.status, error.response.data);
        })
    },
    SaveTranslations()
    {
        ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

        // Get all edited translation input fields
        let dirty = document.getElementsByClassName('TranslationEdit');
        console.log("Update dirty translations", dirty.length);

        let newData = [];
        for (let i = 0; i < dirty.length; i++)
        {
            let lang = dirty[i].getAttribute('language');
            let id = dirty[i].getAttribute('translationid')
            let translation = dirty[i].value;
            newData.push({ ID: id, Language: lang, Translation: translation });
        }

        let data = 
        {
            SessionToken: localStorage.getItem('session_token'),
            TranslationData: newData
        };
        
        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "termtranslations/update", data)
        .then(response => 
        {
            console.log("save response", response);
            console.log("reset" ,dirty);
            ShowLoading(false);
            for (let i = dirty.length - 1; i >= 0; i--)
            {
                dirty[i].setAttribute('orig', dirty[i].value);
                dirty[i].classList.remove('TranslationEdit');
            }
        })
        .catch(error =>
        {
            console.log("Received error.", error);
            
            ShowLoading(false);

            this.$HandleBadRequest(error.response.status, error.response.data);
        })
    },
    OnTranslationUpdate(event)
    {
        let original = event.target.getAttribute("orig");
        this.HasClass(event.target);
        if (original != event.target.value)
        {
            if (this.HasClass(event.target, 'TranslationEdit') == false)
            {
                event.target.classList.add('TranslationEdit');
            }
        }
        else
        {
            event.target.classList.remove('TranslationEdit');
        }
    },
    HasClass(target, lookForClass)
    {
        for(let i = 0; i < target.classList.length; i++)
        {
            if (target.classList[i] == lookForClass)
            {
                return true;
            }
        }

        return false;
    },
    GetLanguageName(languageID)
    {
        for(let i = 0; i < this.SupportedLanguages.length; i++)
        {
            if (this.SupportedLanguages[i].ID == languageID)
            {
                return this.SupportedLanguages[i].Language;
            }
        }

        return '';
    },
    ...mapActions(['saveToken']),
  },
  data(){
    return {
        // ID, Language, Enabled (will always be 1, otherwise it won't be send)
        SupportedLanguages: [],
        // ID, TypeName
        TermTypes: [],
        // ID, Key, Language (Language-ID), Translation, Type (TermTypes-ID)
        TranslationData: [],
    }
  }
}
</script>

<style>
#TranslationTable
{
    color: #ccc;
}

.TranslationEdit
{
    background-color: rgb(255, 255, 163);
}

.TableHeader
{
    font-size: 150%;
    padding-bottom: 20px;
}

.KeyColumn
{
    padding-right: 20px;
}

.TranslationRow td + td
{
    padding-left: 20px;
}

#SaveTranslationButton
{
    position: relative;
    right: 0px;
    top: 0px;
    transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
}

#SaveTranslationButtonContainer
{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: -1px;
    transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
}

.IsSaveBtnPinned
{

}

.IsSaveBtnPinned button
{
    right: -10vw !important;
    top: 50px !important;
    width: 110px;
    height: 35px;
    font-size: 125%;
}
</style>