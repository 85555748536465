<template>
    <div id="Wrapper" style="overflow: visible;" class="ThemeElement" Darkmode="DM_Text">
        <div id="DataContainer"></div>
        <div style="display: flex; flex-direction: column;">
            <button class="AddNewBtn" @click="AddNewDataInput">+</button>
            <button id="SaveBtn" style="display: none; margin-top: 15px; align-self: flex-end;" class="Button ButtonGreen localize" data-key="text.save" @click="CreateNewData"></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { ApplyDarkmode, InitDarkmode } from '../Darkmode.js';
import { LocalizationManager } from '../Localization.js';
import { ShowLoading } from '../FullScreenLoading.js';
import { General_Dialog } from '@/components/GeneralDialog';

export default
{
    name: 'App',
    mounted(){
        document.title = "EZ-SPEC-Factory Data";

        if (localStorage.getItem('session_token') == null)
        {
            this.$router.push('/');
            return;
        }
        else
        {
            this.saveToken(localStorage.getItem('session_token'));
        }

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }

        LocalizationManager.RegisterOnLanguageChangedListener(this.UpdateDataTranslation);

        this.RetrieveData();

        this.$nextTick(()=>
        {
            LocalizationManager.Debug = true;
            LocalizationManager.TranslatePage();
            InitDarkmode();
        });
    },
    beforeUnmount(){
        LocalizationManager.UnregisterOnLanguageChangedListener(this.UpdateDataTranslation);
    },
    methods:{
        ...mapActions(['saveToken']),
        RetrieveData()
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

            let data = 
            {
                SessionToken: localStorage.getItem('session_token')
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "master/factory", data)
            .then(response => 
            {
                console.log("Received data.", response);
                this.Data = response.data;

                var dataContainer = document.getElementById('DataContainer');

                for(let i = 0; i < this.Data.length; i++)
                {
                    let entry = this.CreateNewDataEntry(this.Data[i]);
                    dataContainer.append(entry);
                }

                ShowLoading(false);

            })
            .catch(error =>{
                console.log("Received error.", error);
                ShowLoading(false);
                
                this.$HandleBadRequest(error.response.status, error.response.data);
            })
        },
        ShowNewDataInput()
        {
            this.IsShowNewDataInput = true;
        },
        AddNewDataInput()
        {
            let container = document.getElementById('DataContainer');
            if (container != null)
            {
                container.append(this.CreateNewDataEntry());
            }

            let saveBtn = document.getElementById('SaveBtn');
            if (saveBtn != null)
            {
                saveBtn.style.display = 'block';
            }
        },
        CreateNewDataEntry(data)
        {
            if (data == null ||
                data == undefined)
            {
                console.log('Create new empty data element');
            }

            let id = data? data.ID : '';
            let val = data? data.Name : 'text.factory.';
            let rnd = data? 0 : Math.floor(Math.random() * 10000);

            let entry = document.createElement('div');
            entry.setAttribute('dataid', id);
            entry.classList.add('DataEntry');

            let currentLanguage = LocalizationManager.GetLanguageIndex(LocalizationManager.CurrentLanguage);
            let labelContent = '';
            if (data != null &&
                data != undefined)
            {
                for(let i = 0; i < data.Labels.length; i++)
                {
                    if (data.Labels[i].Language == currentLanguage)
                    {
                        labelContent = data.Labels[i].Translation;
                        break;
                    }
                }
            }

            if (data != null &&
                data != undefined)
            {
                var label = document.createElement('div');
                label.innerHTML = labelContent;
                label.classList.add('ThemeElement', 'Label');
                label.id = 'Label_' + id;
                entry.append(label);
            }

            if (data == null ||
                data == undefined)
            {
                var inputField = document.createElement('input');
                inputField.classList.add('DataEntryNew');
                inputField.id = rnd;
                inputField.style.paddingRight = '20px';
                inputField.style.flex = '1';
                inputField.value = val;
                entry.append(inputField);
                inputField.setAttribute('original', val);
                inputField.onchange = function(event)
                {
                    let orig = event.target.getAttribute('original');
                    console.log('Data change', event);
                    if (event.target.value != orig)
                    {
                        event.target.style.backgroundColor = '#ff0';
                    }
                    else
                    {
                        event.target.style.backgroundColor = '#fff';
                    }
                };
            }
            else
            {
                var locaKeyLabel = document.createElement('div');
                locaKeyLabel.classList.add('LocaKeyLabel');
                locaKeyLabel.innerHTML = val;
                entry.append(locaKeyLabel);
            }

            if (data == null ||
                data == undefined)
            {
                var translationContainer = document.createElement('div');
                translationContainer.classList.add('DataEntryNewTranslations');
                entry.append(translationContainer);

                
                // English
                var lab = document.createElement('div');
                lab.classList.add('SmallLabel', 'localize');
                lab.setAttribute('data-key', 'text.language.english');
                lab.innerHTML = LocalizationManager.GetTranslation('text.language.english');
                translationContainer.append(lab);
                var inp = document.createElement('input');
                inp.id = rnd + '_en';
                translationContainer.append(inp);

                // Japanese
                lab = document.createElement('div');
                lab.classList.add('SmallLabel', 'localize');
                lab.setAttribute('data-key', 'text.language.japanese');
                lab.innerHTML = LocalizationManager.GetTranslation('text.language.japanese');
                translationContainer.append(lab);
                inp = document.createElement('input');
                inp.id = rnd + '_jp';
                translationContainer.append(inp);

                // Chinese
                lab = document.createElement('div');
                lab.classList.add('SmallLabel', 'localize');
                lab.setAttribute('data-key', 'text.language.chinese');
                lab.innerHTML = LocalizationManager.GetTranslation('text.language.chinese');
                translationContainer.append(lab);
                inp = document.createElement('input');
                inp.id = rnd + '_cn';
                translationContainer.append(inp);
            }

            var rmBtnContainer = document.createElement('div');
            rmBtnContainer.style.width = '30px';
            rmBtnContainer.style.height = '30px';
            rmBtnContainer.style.display = 'flex';
            rmBtnContainer.style.justifyContent = 'center';
            rmBtnContainer.style.alignItems = 'center';
            entry.append(rmBtnContainer);

            var rmBtn = document.createElement('div');
            rmBtn.classList.add('DeleteBtn');
            
            var trashIcon = document.createElement('img');
            trashIcon.src = '/assets/Icon/Delete.svg';
            rmBtn.append(trashIcon);

            let ezSpec = this;

            rmBtnContainer.append(rmBtn);
            rmBtn.onclick = function(event)
            {
                console.log('delete data', event);
                if (entry.getAttribute('dataid') == '')
                {
                    entry.remove();
                }
                else
                {
                    console.log('confirm first');
                    General_Dialog.Display(
                        LocalizationManager.GetTranslation('text.masterdata.delete.question').replace('{0}', labelContent),
                        LocalizationManager.GetTranslation('text.yes'),
                        function(){ezSpec.DeleteData(entry);},
                        LocalizationManager.GetTranslation('text.cancel'));
                    ApplyDarkmode();
                }
            };

            return entry;
        },
        CreateNewData()
        {
            // Get the new data key input fields
            let entries = document.getElementsByClassName('DataEntryNew');
            let newEntries = [];

            for (let i = 0; i < entries.length; i++)
            {
                let id = entries[i].id;

                // Get the translation input fields
                let en = document.getElementById(id + '_en').value;
                let jp = document.getElementById(id + '_jp').value;
                let cn = document.getElementById(id + '_cn').value;

                if (entries[i].getAttribute('dataid') == null)
                {
                    newEntries.push({Key: entries[i].value, Translations: { English: en, Japanese: jp, Chinese: cn }});
                }
            }

            let data = {
                SessionToken: localStorage.getItem('session_token'),
                NewData: newEntries
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'master/factory/add', data)
            .then(response => {
                console.log('Add data response', response);
                if (response.data.Failed.length > 0)
                {
                    let errString = LocalizationManager.GetTranslation('text.masterdata.key.adderror');
                    
                    for(let i = 0; i < response.data.Failed.length; i++)
                    {
                        console.log('Could not add data', response.data.Failed[i]);
                        errString += `${response.data.Failed[i].Key} ➞ ${response.data.Failed[i].Reason}`;
                    }

                    General_Dialog.Display(errString, LocalizationManager.GetTranslation('text.ok'));
                    ApplyDarkmode();
                }

                let dataContainer = document.getElementById('DataContainer');
                if (dataContainer == null)
                {
                    console.log('Could not find data container. Stop here');
                    return;
                }

                for(let i = 0; i < response.data.Added.length; i++)
                {
                    this.Data.push(response.data.Added[i]);

                    let newEntry = this.CreateNewDataEntry(response.data.Added[i]);
                    console.log('Created new entry for ', response.data.Added[i]);
                    for(let j = 0; j < entries.length; j++)
                    {
                        console.log(`compare ${entries[j].value} with ${response.data.Added[i].Name}`);
                        if (entries[j].value == response.data.Added[i].Name)
                        {
                            dataContainer.insertBefore(newEntry, entries[j].parentElement);
                            entries[j].parentElement.remove();
                        }
                    }
                }
            })
            .catch(error => {
                console.log("Received error.", error);
                ShowLoading(false);
                
                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        DeleteData(dataEntry)
        {
            console.log('delete data', dataEntry);
            
            ShowLoading(true, LocalizationManager.TranslateKey('text.loading'));

            let data =
            {
                SessionToken: localStorage.getItem('session_token'),
                DataID: dataEntry.getAttribute('dataid')
            };

            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'master/factory/delete', data)
            .then(response => {
                console.log("Delete data response", response);
                if (response.data.success == true)
                {
                    dataEntry.remove();
                }
                ShowLoading(false);
            })
            .catch(error => {
                console.log("Received error.", error);
                ShowLoading(false);
                
                this.$HandleBadRequest(error.response.status, error.response.data);
            });
        },
        UpdateDataTranslation(oldLanguage, newLanguage)
        {
            let curLangIndex = LocalizationManager.GetLanguageIndex(newLanguage);
            
            for (let i = 0; i < this.Data.length; i++)
            {
                let label = document.getElementById(`Label_${this.Data[i].ID}`);
                if (label != null)
                {
                    label.textContent = this.GetTranslationForData(curLangIndex, this.Data[i]);
                }
            }
        },
        GetTranslationForData(language, data)
        {
            for(let i = 0; i < data.Labels.length; i++)
            {
                if (data.Labels[i].Language == language)
                {
                    return data.Labels[i].Translation;
                }
            }

            console.log('[GetTranslationForData] Could not find translation for language ' + language, data);
            return null;
        }
    },
    data() {
        return {
            Data: [],
            IsShowNewDataInput: false,
        }
    },
}
</script>

<style>
.MasterDataButton
{
    min-width: 150px;
    height: 50px;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 110%;
}

#ButtonGridContainer
{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 30px;
    row-gap: 40px;
    padding: 50px;
}

#DataContainer
{
    margin-top: 20px;
}

.DataEntry
{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.DataEntry .Label
{
    display: grid;
    align-content: center;
    padding-right: 40px;
    flex: 1;
}

.DataEntry .LocaKeyLabel
{
    margin-right: 20px;
    width: 200px;
}

.DataEntry input
{
    margin-right: 20px;
    width: 200px;
}

.DataEntryNew
{
    background-color: #ff0;
}

.DataEntryNewTranslations
{
    display: grid;
}

.DataEntryNewTranslations input
{
    
}

.SmallLabel
{
    font-size: 70%;
}

.DataEntryNewTranslations div + input
{
    margin-bottom: 8px;
}

.DeleteBtn
{
    background-color: rgb(209, 24, 24);
    color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0px;
    cursor: pointer;
    height: 23px;
    width: 23px;
    transition: 0.2s;
}

.DeleteBtn:hover
{
    height: 30px;
    width: 30px;
}

.DeleteBtn img
{
    width: auto;
    height: 80%;
}

.AddNewBtn
{
    width: 34px;
    height: 34px;
    border-radius: 100px;
    border: none;
    background-color: greenyellow;
    color: white;
    font-weight: bold;
    font-size: 30px;
    display: grid;
    justify-content: center;
    align-content: center;
    padding: 0px 0px 4px 1px;
    float: right;
    align-self: flex-end;
    cursor: pointer;
}
</style>