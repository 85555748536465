<template>
    <div class="CustomCheckbox">
        <input style="height: 60%;" :class="elementClasses" :disabled="disabled" type="checkbox" :id="elementID" :checked="modelValue" @change="emitChange">
    </div>
</template>

<script>
export default{
    props:
    {
        elementID: String,
        elementClasses: String,
        disabled: Boolean,
        modelValue: { type: Boolean, default: false },
    },
    // data(){
    //     return{
    //         checkValue: false
    //     };
    // },
    // computed:
    // {
    //     selectedOption:
    //     {
    //         get()
    //         {
    //             return this.checked
    //         },
    //         set(newValue)
    //         {
    //             this.$emit('checked', newValue);
    //         }
    //     }
    // },
    methods:{
        emitChange(event){
            this.$emit('togglechange', event);
        }
    }
};
</script>

<style>

</style>