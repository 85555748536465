import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

export function NumberToColumn(number)
{
    var n = (number - 1) % 26;
    var c = String.fromCharCode(65 + n);
    var n2 = Math.floor((number - 1) / 26);
    if (n2 > 0) {
        return NumberToColumn(n2) + c;
    } else {
        return c;
    }
}

export function ColumnToNumber(columnString) {
    columnString = columnString.toUpperCase(); // Ensure uppercase for consistency
  
    let number = 0;
    for (let i = 0; i < columnString.length; i++) {
      const charCode = columnString.charCodeAt(i);
      const offset = charCode - 65; // Offset from 'A' (65)
  
      // Consider single-digit columns (A-Z) and multi-digit columns (AA, AB, etc.)
      number = (number * 26) + (offset + 1);
    }
  
    return number;
}

export function CheckFilename(filename){
    const specialCharsExp = /[\\/:*?<>|]/g;
    const checkedFilename = filename.replace(specialCharsExp, '_');
    return checkedFilename;
}

export function GetTranslation(term, language, dictionary)
{
    let languageName = LanguageCodeToName(language, dictionary.dictionary);

    if (term == "text.hanbaisaki.lsg")
    {
        console.log("[GetTranslation] From dictionary: " + term + " in language: " + languageName + " [" + language + "]");
        console.log(dictionary.dictionary[term]);
        console.log(dictionary.dictionary[term].Translations[languageName]);
    }

    try
    {
        return dictionary.dictionary[term].Translations[languageName];
    }
    catch(error)
    {
        console.log("[GetTranslation] " + term + ' not in dictionary');
        return term + " (NA)";
    }
}

export function LanguageCodeToName(languageCode, dictionary)
{
    // ToDo: Add language codes in database so we can loop
    // through the dictionary object instead of hardcoding
    //console.log(dictionary);
    if (dictionary == null)
    {
        return 'no dict';
    }
    switch (languageCode)
    {
        case "EN":
            return 'English';

        case "JP":
            return 'Japanese';

        case "ZH-CN":
            return 'Chinese-ZH';
    }
}

export async function GenerateAndExportExcel(step1Data, step2Data, step3Data, step4Data, dictionaryData)
{
    console.log("Step1Data", step1Data);
    console.log("Step2Data", step2Data);
    console.log("Step3Data", step3Data);
    console.log("Step4Data", step4Data);
    console.log("DictionaryData", dictionaryData);

    let hasInner = step2Data.InnerID == 0;
    let filename = step1Data.ProductName;

    CreateWorkbook(step1Data, hasInner, 'JP', dictionaryData, (workbook) =>
    {
        var worksheet = workbook.getWorksheet('仕様');
        SetPageSetup(worksheet);
        GenerateSpecSheet(worksheet, hasInner, 'JP', dictionaryData, step1Data);
        InsertData(workbook, worksheet, hasInner, step1Data, step2Data, step3Data, step4Data, 'JP', dictionaryData, () =>
        {
            SetCellSize(worksheet);
            ExportFile(workbook, filename, 'JP');
        });
    });
    
    CreateWorkbook(step1Data, hasInner, 'EN', dictionaryData, (workbook) =>
    {
        var worksheet = workbook.getWorksheet('仕様');
        SetPageSetup(worksheet);
        GenerateSpecSheet(worksheet, hasInner, 'EN', dictionaryData, step1Data);
        InsertData(workbook, worksheet, hasInner, step1Data, step2Data, step3Data, step4Data, 'EN', dictionaryData, () =>
        {
            SetCellSize(worksheet);
            ExportFile(workbook, filename, 'EN');
        });
    });

    CreateWorkbook(step1Data, hasInner, 'ZH-CN', dictionaryData, (workbook) =>
    {
        var worksheet = workbook.getWorksheet('仕様');
        SetPageSetup(worksheet);
        GenerateSpecSheet(worksheet, hasInner, 'ZH-CN', dictionaryData, step1Data);
        InsertData(workbook, worksheet, hasInner, step1Data, step2Data, step3Data, step4Data, 'ZH-CN', dictionaryData, () =>
        {
            SetCellSize(worksheet);
            ExportFile(workbook, filename, 'ZH-CN');
        });
    });
}

export async function ExportBlank()
{
    CreateWorkbook(null, true, 'EN', null, (workbook) =>
    {
        ExportFile(workbook, "BLANK", "BLANK");
    });
}

export async function ExportBlank_JP(dictionaryData)
{
    CreateWorkbook(null, true, 'JP', dictionaryData, (workbook) =>
    {
        let worksheet = workbook.getWorksheet('仕様');
        GenerateSpecSheet(worksheet, true, 'JP', dictionaryData);
        ExportFile(workbook, "BLANK", "BLANK");
    });
}

export async function ExportBlank_EN(dictionaryData)
{
    CreateWorkbook(null, true, 'EN', dictionaryData, (workbook) =>
    {
        let worksheet = workbook.getWorksheet('仕様');
        GenerateSpecSheet(worksheet, true, 'EN', dictionaryData);
        ExportFile(workbook, "BLANK", "BLANK");
    });
}

export async function ExportBlank_ZH_CN(dictionaryData)
{
    CreateWorkbook(null, true, 'ZH-CN', dictionaryData, (workbook) =>
    {
        let worksheet = workbook.getWorksheet('仕様');
        GenerateSpecSheet(worksheet, true, 'CN-ZH', dictionaryData);
        ExportFile(workbook, "BLANK", "BLANK");
    });
}

/// Creates and formats an new Excel workbook
export async function CreateWorkbook(step1Data, hasInner, languageCode, dictionaryData, callback)
{
    var workbook = new ExcelJS.Workbook();
    await FormatExcelWorkbook(workbook, step1Data, hasInner, languageCode, dictionaryData, callback);
}

export async function FormatExcelWorkbook(workbook, step1Data, hasInner, languageCode, dictionaryData, callback)
{
    let worksheet = workbook.addWorksheet('仕様');

    worksheet.views = { zoomScale: 80 };

    // Generate column information first
    var tmp = worksheet.getCell('BE60');
    tmp.value = "";

    worksheet.views = [{}];

    // Merge cells
    {
        worksheet.mergeCells('A1', 'E2');
        worksheet.mergeCells('Q1', 'T2');
        worksheet.mergeCells('AV1', 'AW2');
        worksheet.mergeCells('AX1', 'BA2');
        worksheet.mergeCells('BB1', 'BC1');
        worksheet.mergeCells('BD1', 'BG1');
        worksheet.mergeCells('BH1', 'BI1');
        worksheet.mergeCells('BJ1', 'BM1');

        worksheet.mergeCells('BB2', 'BC2');
        worksheet.mergeCells('BD2', 'BG2');
        worksheet.mergeCells('BH2', 'BI2');
        worksheet.mergeCells('BJ2', 'BM2');
        //品番
        worksheet.mergeCells('A3', 'C4');
        worksheet.mergeCells('D3', 'J4');
        // JAN
        worksheet.mergeCells('K3', 'M4');
        worksheet.mergeCells('N3', 'U4');
        //商品仕様
        worksheet.mergeCells('V3', 'X4');
        worksheet.mergeCells('Y3', 'AK4');
        //工場出荷
        worksheet.mergeCells('AL3', 'AN4');
        worksheet.mergeCells('AO3', 'AS4');
        //電源仕様
        if (step1Data == null ||
            step1Data != null && step1Data.ProductSpecificationID != 4)
        {
            worksheet.mergeCells('AT3', 'AW3');
            worksheet.mergeCells('AT4', 'AU4');
            worksheet.mergeCells('AV4', 'AW4');
            worksheet.mergeCells('AT5', 'AU5');
            worksheet.mergeCells('AV5', 'AW5');
            worksheet.mergeCells('AT6', 'AW12');
        }
        else
        {
            //販売先
            worksheet.mergeCells('AT3', 'BA4');
            worksheet.mergeCells('AT5', 'BA12');
        }
        //台座仕様
        if (step1Data == null ||
            step1Data != null && step1Data.ProductSpecificationID != 4)
        {
            worksheet.mergeCells('AX3', 'BA3');
            worksheet.mergeCells('AX4', 'AY4');
            worksheet.mergeCells('AZ4', 'BA4');
            worksheet.mergeCells('AX5', 'AY5');
            worksheet.mergeCells('AZ5', 'BA5');
            worksheet.mergeCells('AX6', 'BA12');
        }
        //梱包仕様
        worksheet.mergeCells('BB3', 'BM4');
        //商品名
        worksheet.mergeCells('A5', 'C6');
        worksheet.mergeCells('D5', 'J6');
        worksheet.mergeCells('K5', 'U6');
        worksheet.mergeCells('V5', 'AD6');
        worksheet.mergeCells('AE5', 'AK6');
        //生産工場
        worksheet.mergeCells('AL5', 'AN6');
        worksheet.mergeCells('AO5', 'AS6');
        //発売日
        worksheet.mergeCells('AL7', 'AN8');
        worksheet.mergeCells('AO7', 'AS8');
        //販売先
        if (step1Data == null ||
            step1Data != null && step1Data.ProductSpecificationID != 4)
        {
            worksheet.mergeCells('AL9', 'AN10');
            worksheet.mergeCells('AO9', 'AS10');
            //ING金型番号
            worksheet.mergeCells('AL11', 'AN12');
            worksheet.mergeCells('AO11', 'AS12');
        }
        else
        {
            //ING金型番号
            worksheet.mergeCells('AL9', 'AN12');
            worksheet.mergeCells('AO9', 'AS12');
        }
        //PKG形態
        worksheet.mergeCells('BB5', 'BD5');
        worksheet.mergeCells('BE5', 'BG5');
        //証紙貼り
        worksheet.mergeCells('BH5', 'BJ5');
        worksheet.mergeCells('BK5', 'BM5');
        //印刷
        worksheet.mergeCells('BB6', 'BD6');
        worksheet.mergeCells('BE6', 'BG6');
        //中台紙
        worksheet.mergeCells('BB7', 'BD7');
        worksheet.mergeCells('BE7', 'BG7');
        //テープ止め
        worksheet.mergeCells('BB8', 'BD8');
        worksheet.mergeCells('BE8', 'BG8');
        //PKGサイズ（cm）
        worksheet.mergeCells('BH6', 'BM6');
        //W
        worksheet.mergeCells('BH7', 'BI7');
        worksheet.mergeCells('BH8', 'BI8');
        //H
        worksheet.mergeCells('BJ7', 'BK7');
        worksheet.mergeCells('BJ8', 'BK8');
        //D
        worksheet.mergeCells('BL7', 'BM7');
        worksheet.mergeCells('BL8', 'BM8');
        //本体版権表記
        worksheet.mergeCells('A7', 'C12');
        //表記位置
        worksheet.mergeCells('D7', 'G7');
        worksheet.mergeCells('D8', 'G9');
        //表記方法
        worksheet.mergeCells('D10', 'G10');
        worksheet.mergeCells('D11', 'G12');
        //本体
        worksheet.mergeCells('H7', 'U7');
        worksheet.mergeCells('H8', 'U12');
        //PKG
        worksheet.mergeCells('V7', 'AG7');
        worksheet.mergeCells('V8', 'AG12');
        //説明書
        worksheet.mergeCells('AH7', 'AI8');
        worksheet.mergeCells('AJ7', 'AK8');
        worksheet.mergeCells('AH9', 'AK12');
        //セット内容
        worksheet.mergeCells('BB18', 'BG18');
        worksheet.mergeCells('BB19', 'BG19');
        worksheet.mergeCells('BB20', 'BG20');
        worksheet.mergeCells('BB21', 'BG21');
        worksheet.mergeCells('BB22', 'BG22');
        worksheet.mergeCells('BB23', 'BG23');
        worksheet.mergeCells('BB24', 'BG24');
        worksheet.mergeCells('BB25', 'BG25');
        worksheet.mergeCells('BB26', 'BG26');
        //製品素材
        worksheet.mergeCells('BH18', 'BM18');
        worksheet.mergeCells('BH19', 'BM19');
        worksheet.mergeCells('BH20', 'BM20');
        worksheet.mergeCells('BH21', 'BM21');
        worksheet.mergeCells('BH22', 'BM22');
        worksheet.mergeCells('BH23', 'BM23');
        worksheet.mergeCells('BH24', 'BM24');
        worksheet.mergeCells('BH25', 'BM25');
        worksheet.mergeCells('BH26', 'BM26');
        //梱包素材
        worksheet.mergeCells('BB27', 'BM27');
        worksheet.mergeCells('BB28', 'BC30');
        worksheet.mergeCells('BD28', 'BG28');
        worksheet.mergeCells('BD29', 'BG29');
        worksheet.mergeCells('BD30', 'BG30');

        worksheet.mergeCells('BB31', 'BC33');
        worksheet.mergeCells('BD31', 'BG31');
        worksheet.mergeCells('BD32', 'BG32');
        worksheet.mergeCells('BD33', 'BG33');

        worksheet.mergeCells('BH28', 'BI33');
        worksheet.mergeCells('BJ28', 'BM28');
        worksheet.mergeCells('BJ29', 'BM29');
        worksheet.mergeCells('BJ30', 'BM30');
        worksheet.mergeCells('BJ31', 'BM31');
        worksheet.mergeCells('BJ32', 'BM32');
        worksheet.mergeCells('BJ33', 'BM33');
        //カートン仕様
        worksheet.mergeCells('BB34', 'BH34');
        //QUANTITEY PER CARTON（入数）
        worksheet.mergeCells('BB35', 'BH35');
        //OUTER（アウター）
        worksheet.mergeCells('BB36', 'BF36');
        worksheet.mergeCells('BG36', 'BH36');
        //INNER（インナー）
        worksheet.mergeCells('BB37', 'BF37');
        worksheet.mergeCells('BG37', 'BH37');
        //カートンサイズ(W + H + D)设定外箱尺寸
        worksheet.mergeCells('BB38', 'BH39');
        worksheet.mergeCells('BB40', 'BE41');
        worksheet.mergeCells('BF40', 'BH41');
        worksheet.mergeCells('B81', 'AE82');

        if (step1Data.ProductSpecificationID == 4)
        {
            worksheet.mergeCells('B14', 'I15');
            worksheet.mergeCells('AI14', 'AT16');
            worksheet.mergeCells('AK44', 'AT46');
            worksheet.mergeCells('N44', 'AA54');
            worksheet.mergeCells('A60', 'I61');
            worksheet.mergeCells('T60', 'AB61');
            worksheet.mergeCells('T63', 'AC66');
            worksheet.mergeCells('AJ63', 'AP66');
        }
    }

    // cell layout
    {
        var cell = null;

        cell = worksheet.getCell('A1');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffff' } };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.font = { size: 14, bold: true };

        cell = worksheet.getCell('A3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00ff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('A5');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00ff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('A7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('A8');
        cell.font = { name: "ＭＳ Ｐゴシック", color: { argb: '000000' }, size: 7, bold: false };

        cell = worksheet.getCell('AB8');
        cell.font = { name: "ＭＳ Ｐゴシック", color: { argb: '000000' }, size: 7, bold: false };

        cell = worksheet.getCell('D3');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 14};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('D5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('D7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('D8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };

        cell = worksheet.getCell('D10');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('D11');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('K3');
        cell.value = "JAN";
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00ff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('K5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('N3');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 14 };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('V3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00ff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('V5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 14, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('Y3');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('H7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00b0f0' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, bold: true, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AB7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '00b0f0' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, bold: true, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AH7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AE5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('AJ7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } }
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };


        cell = worksheet.getCell('H8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 9};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('AB8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AH9');
        cell.fill = { type: 'pattern', pattern: 'solid', bgcolor: { argb: 'ffffffcc'}, fgColor: { argb: 'ffffffcc' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AL3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 11, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AO3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 12, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AL5');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff66' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AL7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff66' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AL9');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff66' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AL11');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff66' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AO5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AO7');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AO9');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AO11');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AT3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ff99' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        if (step1Data.ProductSpecificationID != 4)
        {
            cell = worksheet.getCell('AT6');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };

            cell = worksheet.getCell('AX6');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };

            cell = worksheet.getCell('AX3');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ff99' } };
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AT4');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffe699' } };
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'dotted', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AV4');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                bottom: { style: 'thin', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AT5');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffe699' } };
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'dotted', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AV5');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                bottom: { style: 'thin', color: { argb: '000000' } },
            };
            
            cell = worksheet.getCell('AX4');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffe699' } };
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'dotted', color: { argb: '000000' } },
            };

            cell = worksheet.getCell('AX5');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffe699' } };
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'dotted', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AZ4');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                bottom: { style: 'thin', color: { argb: '000000' } },
            };
    
            cell = worksheet.getCell('AZ5');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                bottom: { style: 'thin', color: { argb: '000000' } },
            };
        }
        else
        {
            cell = worksheet.getCell('AT6');
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };
        }

        cell = worksheet.getCell('BM4');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BM5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10};
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AV1');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('AX1');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB1');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BD1');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB2');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BD2');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, vertical: 'middle' };

        cell = worksheet.getCell('BH1');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BJ1');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('BH2');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc000' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BJ2');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB3');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ff99' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB5');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BE5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB6');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BE6');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB7');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BE7');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB8');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BE8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BH5');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('BK5');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BH6');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BH7');
        cell.value = "W";
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BJ7');
        cell.value = "H";
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BL7');
        cell.value = "D";
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BH8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BJ8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'dotted', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BL8');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB18');
        cell.fill = { type: 'pattern', pattern: 'solid', bgColor: { argb: 'd9e1f2' }, fgColor: { argb: 'd9e1f2' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'dotted', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BH18');
        cell.fill = { type: 'pattern', pattern: 'solid', bgColor: { argb: 'd9e1f2' }, fgColor: { argb: 'd9e1f2' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'dotted', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB27');
        cell.fill = { type: 'pattern', pattern: 'solid', bgColor: { argb: 'd9e1f2' }, fgColor: { argb: 'd9e1f2' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'dotted', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB28');
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB31');
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BD28');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BD29');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BD30');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BD31');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BD32');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BH28');
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BJ28');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BJ29');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BJ30');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BJ31');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BJ32');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };

        cell = worksheet.getCell('BJ33');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB34');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB35');
        cell.fill = { type: 'pattern', pattern: 'solid', bgcolor: { argb: 'ffffffcc'}, fgColor: { argb: 'ffffffcc' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: false, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'dotted', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('BB36');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'dotted', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BG36');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB37');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BG37');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 10 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            right: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
        };
        
        cell = worksheet.getCell('BB38');
        cell.value = "カートンサイズ(W + H + D) 设定外箱尺寸";
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 9, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BB40');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 18 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            left: { style: 'medium', color: { argb: '000000' } },
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'dotted', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('BF40');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 18 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('B82');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 11 };
        cell.alignment = { vertical: 'middle' };
        // cell.border = {
        //     bottom: { style: 'medium', color: { argb: '000000' } },
        // }

        worksheet.mergeCells('BC82', 'BL82');
        cell = worksheet.getCell('BC82');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: 'ff0000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, vertical: 'middle' };
        // cell.border = {
        //     bottom: { style: 'medium', color: { argb: '000000' } },
        // };

        cell = worksheet.getCell('BB82');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };
        cell = worksheet.getCell('BM82');
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } };

        cell = worksheet.getCell('A82')
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            left: {style: 'medium', color: { argb: '000000' } },
        };

        cell = worksheet.getCell('B82');
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
        };

        var yColNum = 25;
        var bzColNum = 65;
        for(var i = yColNum; i <= bzColNum; i++)
        {
            var column = NumberToColumn(i);
            cell = worksheet.getCell(column + "82");
            cell.border = {
                bottom: { style: 'medium', color: { argb: '000000' } },
            };
        }

        cell = worksheet.getCell('BB42');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };

        cell = worksheet.getCell('BB55');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };

        if(hasInner == true)
        {
            cell = worksheet.getCell('BB69');
            cell.value = "インナーカートン　表記位置①";
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };                
            
            cell = worksheet.getCell('BI69');
            cell.value = "内箱";
        }

        for (let i = 13; i <= 81; i++)
        {
            cell = worksheet.getCell('A' + i);
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
            }
        }

        for (let i = 13; i <= 26; i++) {
            if (i==18){continue;}
            cell = worksheet.getCell('BB' + i);
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: false };  
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
            };
        }

        for (let i = 42; i <= 82; i++) {
            cell = worksheet.getCell('BB' + i);
            if (i == 82)
            {
                cell.border = {
                    left: { style: 'medium', color: { argb: '000000' } },
                    bottom: { style: 'medium', color: { argb: '000000' } },
                };
            }
            else if (i >= 19 && i <= 26){
                cell.border = {
                    left: { style: 'medium', color: { argb: '000000' } },
                    right: { style: 'medium', color: { argb: '000000' } },
                };
            }
            else{
                cell.border = {
                    left: { style: 'medium', color: { argb: '000000' } },
                };
            }
        }

        for (let i = 19; i <= 26; i++) {
            cell = worksheet.getCell('BH' + i);
            cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: false }; 
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
            };
        }

        for (let i = 9; i <= 82; i++)
        {
            if (i==18 ||
                i==27)
            {
                continue;
            }
            cell = worksheet.getCell('BM' + i);
            if (i == 33 ||
                i == 34 ||
                i == 82)
            {
                cell.border = {
                    bottom: { style: 'medium', color: { argb: '000000' } },    
                    right: { style: 'medium', color: { argb: '000000' } },
                };
            }
            else if (i >= 19 &&
                    i <= 26)
            {
                cell.border = {
                    left: { style: 'medium', color: { argb: '000000' } },    
                    right: { style: 'medium', color: { argb: '000000' } },
                };
            }
            else
            {
                cell.border = {
                    right: { style: 'medium', color: { argb: '000000' } },
                };
            }
        }

        // Square frame 1
        // TL
        cell = worksheet.getCell('BC43');
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
        };
        // TR
        cell = worksheet.getCell('BL43');
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        // BL
        cell = worksheet.getCell('BC53');
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
        };
        // BR
        cell = worksheet.getCell('BL53');
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        for (let i = 44; i <= 52; i++) {
            cell = worksheet.getCell('BC' + i);
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
            };
            cell = worksheet.getCell('BL' + i);
            cell.border = {
                right: { style: 'medium', color: { argb: '000000' } },
            };
        }
        // Top line
        var topLineStart = 56;
        var topLineEnd = 63;
        for(let i = topLineStart; i <= topLineEnd; i++)
        {
            cell = worksheet.getCell(NumberToColumn(i) + "43");
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
            };    
        }
        // Bottom line
        var bottomLineStart = 56;
        var bottomLineEnd = 63;
        for(let i = bottomLineStart; i <= bottomLineEnd; i++){
            cell = worksheet.getCell(NumberToColumn(i) + "53");
            cell.border = {
                bottom: { style: 'medium', color: { argb: '000000' } },
            };    
        }

        worksheet.mergeCells('BC51', 'BE52');
        cell = worksheet.getCell('BD51');
        cell.value = "Item name";
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: false };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('BF51', 'BK52');
        cell = worksheet.getCell('BF51');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };


        // Square frame 2
        // TL
        cell = worksheet.getCell('BC56');
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
        };
        // TR
        cell = worksheet.getCell('BL56');
        cell.border = {
            top: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };
        // BL
        cell = worksheet.getCell('BC67');
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            left: { style: 'medium', color: { argb: '000000' } },
        };
        // BR
        cell = worksheet.getCell('BL67');
        cell.border = {
            bottom: { style: 'medium', color: { argb: '000000' } },
            right: { style: 'medium', color: { argb: '000000' } },
        };

        worksheet.mergeCells('BC59', 'BE60');
        cell = worksheet.getCell('BC59');
        cell.value = "Item name";
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('BF59', 'BK60');
        cell = worksheet.getCell('BF59');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };

        cell = worksheet.getCell('BE61');
        cell.value = "QTY";
        cell = worksheet.getCell('BE62');
        cell.value = "Net Weigth";
        cell = worksheet.getCell('BJ62');
        cell.value = "KGS";
        cell = worksheet.getCell('BE63');
        cell.value = "Gross Weigth";
        cell = worksheet.getCell('BJ63');
        cell.value = "KGS";
        cell = worksheet.getCell('BE64');
        cell.value = "C/NO.";
        cell = worksheet.getCell('BE65');
        cell.value = "MADE IN CHINA";
        cell = worksheet.getCell('BE66');
        cell.value = "JAN CODE:";

        for (let i = 57; i <= 66; i++) {
            cell = worksheet.getCell('BC' + i);
            cell.border = {
                left: { style: 'medium', color: { argb: '000000' } },
            };
            cell = worksheet.getCell('BL' + i);
            cell.border = {
                right: { style: 'medium', color: { argb: '000000' } },
            };
        }
        // Top line
        for(let i = topLineStart; i <= topLineEnd; i++)
        {
            cell = worksheet.getCell(NumberToColumn(i) + "56");
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
            };    
        }
        // Bottom line
        for(let i = bottomLineStart; i <= bottomLineEnd; i++){
            cell = worksheet.getCell(NumberToColumn(i) + "67");
            cell.border = {
                bottom: { style: 'medium', color: { argb: '000000' } },
            };    
        }

        console.log('CHECK FOR THIRD BOX',hasInner);
        if(hasInner === true)
        {
            // Square frame 3
            // TL
            cell = worksheet.getCell('BC70');
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
                left: { style: 'medium', color: { argb: '000000' } },
            };
            // TR
            cell = worksheet.getCell('BL70');
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };
            // BL
            cell = worksheet.getCell('BC80');
            cell.border = {
                bottom: { style: 'medium', color: { argb: '000000' } },
                left: { style: 'medium', color: { argb: '000000' } },
            };
            // BR
            cell = worksheet.getCell('BL80');
            cell.border = {
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };

            if(hasInner == true)
            {   
                worksheet.mergeCells('BC78', 'BE79');
                cell = worksheet.getCell('BC78');
                cell.value = "Item name";
                cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: false};
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                
                worksheet.mergeCells('BF78', 'BK79');
                cell = worksheet.getCell('BF78');
                cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 10, bold: true};
                cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
                
                for (let i = 71; i <= 79; i++) {
                    cell = worksheet.getCell('BC' + i);
                    cell.border = {
                        left: { style: 'medium', color: { argb: '000000' } },
                    };
                    cell = worksheet.getCell('BL' + i);
                    cell.border = {
                        right: { style: 'medium', color: { argb: '000000' } },
                    };
                }
                // Top line
                for(let i = topLineStart; i <= topLineEnd; i++)
                {
                    cell = worksheet.getCell(NumberToColumn(i) + "70");
                    cell.border = {
                        top: { style: 'medium', color: { argb: '000000' } },
                    };    
                }
                // Bottom line
                for(let i = bottomLineStart; i <= bottomLineEnd; i++){
                    cell = worksheet.getCell(NumberToColumn(i) + "80");
                    cell.border = {
                        bottom: { style: 'medium', color: { argb: '000000' } },
                    };    
                }
            }
        }

        // Last cell merges
        worksheet.mergeCells('BI34', 'BM35');
        cell = worksheet.getCell('BI34');
        cell.font = { name: 'ＭＳ Ｐゴシック', color: { argb: '000000' }, size: 8 };
        cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };

        // Re-draw the line since the
        // previous merge will remove it
        cell = worksheet.getCell('BM34');
        cell.border = {
            right: { style: 'medium', color: { argb: '000000' } },
        };

        // Draw plastic model related styles
        // in description area
        if (step1Data.ProductSpecificationID == 4)
        {
            cell = worksheet.getCell('B14');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B8CCE4' } };
            cell.font = { name: 'Calibri', color: { argb: '000000' }, size: 14, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                top: { style: 'medium', color: { argb: '1F497D' } },
                left: { style: 'medium', color: { argb: '1F497D' } },
                bottom: { style: 'medium', color: { argb: '1F497D' } },
                right: { style: 'medium', color: { argb: '1F497D' } },
            };
            console.log('Get translation for "Set Content" for ' + languageCode, dictionaryData);
            cell.value = GetTranslation('text.excel.setcontent', languageCode, dictionaryData);

            cell = worksheet.getCell('AI14');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } };
            cell.font = { name: 'Yu Gothic', color: { argb: '000000' }, size: 14, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                top: { style: 'thin', color: { argb: '1F497D' } },
                left: { style: 'thin', color: { argb: '1F497D' } },
                bottom: { style: 'thin', color: { argb: '1F497D' } },
                right: { style: 'thin', color: { argb: '1F497D' } },
            };
            cell.value = GetTranslation('text.excel.partscolor', languageCode, dictionaryData);

            cell = worksheet.getCell('AK44');
            cell.font = { name: 'Yu Gothic', color: { argb: '000000' }, size: 12, bold: false };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'top' };
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };
            cell.value = GetTranslation('text.excel.instructionsheet', languageCode, dictionaryData);

            cell = worksheet.getCell('N44');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } };
            cell.font = { name: 'Yu Gothic', color: { argb: 'FF0000' }, size: 12, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'top' };
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } },
            };
            cell.value = GetTranslation('text.excel.luminous', languageCode, dictionaryData);

            cell = worksheet.getCell('AC16');
            cell.border = {
                left: { style:'medium', color: { argb:'1F497D' } },
                top: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('AZ16');
            cell.border = {
                right: { style:'medium', color: { argb:'1F497D' } },
                top: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('AC57');
            cell.border = {
                left: { style:'medium', color: { argb:'1F497D' } },
                bottom: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('AZ57');
            cell.border = {
                right: { style:'medium', color: { argb:'1F497D' } },
                bottom: { style:'medium', color: { argb:'1F497D' } }
            };

            DrawLine(worksheet, 'AC', 17, 'AC', 56, { left: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'AD', 57, 'AY', 57, { bottom: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'AZ', 17, 'AZ', 56, { right: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'AD', 16, 'AH', 16, { top: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'AU', 16, 'AY', 16, { top: { style:'medium', color: { argb:'1F497D' } } });

            cell = worksheet.getCell('R62');
            cell.border = {
                top: { style:'medium', color: { argb:'1F497D' } },
                right: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('R80');
            cell.border = {
                bottom: { style:'medium', color: { argb:'1F497D' } },
                right: { style:'medium', color: { argb:'1F497D' } }
            };

            DrawLine(worksheet, 'J', 62, 'Q', 62, { top: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'R', 63, 'R', 79, { right: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'A', 80, 'Q', 80, { bottom: { style:'medium', color: { argb:'1F497D' } } });

            cell = worksheet.getCell('T62');
            cell.border = {
                left: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('AZ62');
            cell.border = {
                top: { style:'medium', color: { argb:'1F497D' } },
                right: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('AZ80');
            cell.border = {
                bottom: { style:'medium', color: { argb:'1F497D' } },
                right: { style:'medium', color: { argb:'1F497D' } }
            };

            cell = worksheet.getCell('T80');
            cell.border = {
                left: { style:'medium', color: { argb:'1F497D' } },
                bottom: { style:'medium', color: { argb:'1F497D' } }
            };

            DrawLine(worksheet, 'AC', 62, 'AY', 62, { top: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'AZ', 63, 'AZ', 79, { right: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'U', 80, 'AY', 80, { bottom: { style:'medium', color: { argb:'1F497D' } } });
            DrawLine(worksheet, 'T', 63, 'T', 79, { left: { style:'medium', color: { argb:'1F497D' } } });

            cell = worksheet.getCell('A60');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B8CCE4' } };
            cell.font = { name: 'Calibri', color: { argb: '000000' }, size: 14, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                top: { style: 'medium', color: { argb: '1F497D' } },
                left: { style: 'medium', color: { argb: '1F497D' } },
                bottom: { style: 'medium', color: { argb: '1F497D' } },
                right: { style: 'medium', color: { argb: '1F497D' } },
            };
            cell.value = GetTranslation('text.excel.copyrightposition', languageCode, dictionaryData);

            cell = worksheet.getCell('T60');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B8CCE4' } };
            cell.font = { name: 'Calibri', color: { argb: '000000' }, size: 14, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' };
            cell.border = {
                top: { style: 'medium', color: { argb: '1F497D' } },
                left: { style: 'medium', color: { argb: '1F497D' } },
                bottom: { style: 'medium', color: { argb: '1F497D' } },
                right: { style: 'medium', color: { argb: '1F497D' } },
            };
            cell.value = GetTranslation('text.excel.packing', languageCode, dictionaryData);

            cell = worksheet.getCell('T63');
            cell.font = { name: 'Yu Gothic', color: { argb: '000000' }, size: 12, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'top' };
            cell.border = {
                top: { style: 'medium', color: { argb: '000000' } },
                left: { style: 'medium', color: { argb: '000000' } },
                bottom: { style: 'medium', color: { argb: '000000' } },
                right: { style: 'medium', color: { argb: '000000' } },
            };
            cell.value = GetTranslation('text.excel.clearbag', languageCode, dictionaryData);

            cell = worksheet.getCell('X67');
            cell.border = {
                right: { style: 'medium', color: { argb: '000000' } },
            };

            cell = worksheet.getCell('AJ63');
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } };
            cell.font = { name: 'Yu Gothic', color: { argb: 'FF0000' }, size: 12, bold: true };
            cell.alignment = { wrapText: true, horizontal: 'center', vertical: 'top' };
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } },
            };
            cell.value = GetTranslation('text.excel.wrapping', languageCode, dictionaryData);

            cell = worksheet.getCell('AM67');
            cell.border = {
                right: { style: 'medium', color: { argb: '000000' } },
            };
        }
    }

    // images
    {
        let imagePaths = [
            "/assets/cartonmark.png",
            "/assets/barcode_sample.png",
            "/assets/carton_pic.png",
            "/assets/XPLogo_black.png",
        ];

        if (step1Data.ProductSpecificationID == 4)
        {
            imagePaths.push('/assets/RunnerSample.png', '/assets/WrappingSample.png');
        }

        const imageIds = {};

        Promise.all(
            imagePaths.map(imagePath =>
                fetch(imagePath)
                .then(response => response.blob())
                .then(blob => 
                {
                    let id = workbook.addImage({
                        buffer: blob,
                        extension: 'png'
                    });

                    imageIds[imagePath] = id;

                    // blob.text().then(text => {
                    //     console.log("Fetched image " + imagePath + " ID: " + id + " BlobText: " + text);
                    // });
                    
                })
            )
        )
        .then(() => 
        {
            let imgID = imageIds["/assets/cartonmark.png"];
            worksheet.addImage(
                imgID,
                'BH44:BL47'
            );

            if(hasInner == true)
            {   
                worksheet.addImage(
                    imgID,
                    'BH71:BL74'
                );
            }

            imgID = imageIds["/assets/barcode_sample.png"];
            worksheet.addImage(
                imgID,
                'BD44:BE45'
            );

            if(hasInner == true)
            {   
                worksheet.addImage(
                    imgID,
                    'BD71:BE72'
                );
            }

            imgID = imageIds["/assets/carton_pic.png"];
            worksheet.addImage(
                imgID,
                'BI36:BM41'
            );

            imgID = imageIds["/assets/XPLogo_black.png"];
            worksheet.addImage(
                imgID,
                'BG57:BH58'
            );

            if (step1Data.ProductSpecificationID == 4)
            {
                imgID = imageIds['/assets/RunnerSample.png'];
                worksheet.addImage(
                    imgID,
                    'V68:AB79'
                );

                imgID = imageIds['/assets/WrappingSample.png'];
                worksheet.addImage(
                    imgID,
                    'AI68:AR79'
                );
            }

            callback(workbook);
        });
    }
}

export function DrawLine(worksheet, fromColumn, fromRow, toColumn, toRow, lineStyle)
{
    let startCol = ColumnToNumber(fromColumn);
    let endCol = ColumnToNumber(toColumn);

    for(let i = fromRow; i <= toRow; i++)
    {
        for(let j = startCol; j <= endCol; j++)
        {
            let cell = worksheet.getCell(NumberToColumn(j) + i);
            cell.border = lineStyle;
        }
    }
}

export function SetCellSize(worksheet)
{
    // Set column width and row height (this should maybe be moved to the place where we save the file...)
    const columnWidth = 4.3; // [3.35,3.29] -> 2,57 (23px), [3.75,3.5] -> 2,71 (24px), [4.0] -> 3,29 (28px) 4.3 -> 3.57 (30px) [4.4, 4.5] -> 3,71
    const rowHeight = 14.25; // 14.25 -> 15,00 (20px)

    worksheet.columns.forEach((column) => {
        column.width = columnWidth;
    });

    worksheet.getColumn('BD').width = columnWidth - 1.6;
    worksheet.getColumn('BE').width = columnWidth + 1.6;

    let rowCounter = 0;

    for (let i = 1; i <= 82; i++)
    {
        if (rowCounter < 2)
        {
            worksheet.getRow(i).height = 18;
            rowCounter++;
        }
        else
        {
            worksheet.getRow(i).height = rowHeight;
        }
    }

    // worksheet.eachRow((row) => {
    //     if (rowCounter < 2)
    //     {
    //         row.height = 18;
    //         rowCounter++;
    //     }
    //     else
    //     {
    //         row.height = rowHeight;
    //     }
    // });
}

// Setup for printing the spec sheet
export function SetPageSetup(worksheet)
{
    worksheet.pageSetup.orientation = 'landscape';
    worksheet.pageSetup.margins = {
        left: 0.393701, right: 0.19685,
        top: 0.19685, bottom: 0.0,
        header: 0.19685, footer: 0.19685
    };
    worksheet.pageSetup.printArea = 'A1:BM82';
    // 9 -> A4
    worksheet.pageSetup.paperSize = 9;
    worksheet.pageSetup.fitToPage = true;
    // In %
    worksheet.pageSetup.scale = 46;
    console.log('Worksheet page setup',worksheet.pageSetup);
    console.log('Worksheet header/footer',worksheet.headerFooter);
}

export async function InsertData(workbook, worksheet, hasInner, step1Data, step2Data, step3Data, step4Data, languageCode, dictionaryData, callback)
{
    console.log('[InsertData] ' + languageCode + ' Step_1 data', step1Data);
    console.log('[InsertData] ' + languageCode + ' Step_2 data', step2Data);
    console.log('[InsertData] ' + languageCode + ' Step_3 data', step3Data);
    console.log('[InsertData] ' + languageCode + ' Step_4 data', step4Data);

    let cell;
    
    // Insert input from user
    cell = worksheet.getCell('BJ66');
    cell.value = "0"; // ToDo: INSERT ACTUAL JAN CODE HERE

    // ToDo: Something missing here?
    cell = worksheet.getCell('D3');
    cell.value = "";

    cell = worksheet.getCell('D5');
    if (step1Data.ProductSpecificationID !== 4)
    {
        cell.value = GetTranslation(step1Data.Key_Series, languageCode, dictionaryData);
    }
    else
    {
        cell.value = GetTranslation(step1Data.Key_Scale, languageCode, dictionaryData);
    }

    cell = worksheet.getCell('K5');
    cell.value = step1Data.ProductName;

    cell = worksheet.getCell('Y3');
    cell.value = GetTranslation(step1Data.Key_ProductSpecification, languageCode, dictionaryData);

    cell = worksheet.getCell('D8');
    console.log('notation position', step3Data.NotationPosition);
    if (step3Data.Key_NotationPosition != undefined)
    {
        cell.value = GetTranslation(step3Data.Key_NotationPosition, languageCode, dictionaryData);
    }
    else
    {
        cell.value = step3Data.NotationPosition;
    }

    cell = worksheet.getCell('D11');
    console.log('notation method', step3Data.NotationMethod);
    if (step3Data.Key_NotationMethod != undefined)
    {
        cell.value = GetTranslation(step3Data.Key_NotationMethod, languageCode, dictionaryData);
    }
    else
    {
        cell.value = step3Data.NotationMethod;
    }

    if (step1Data.ProductSpecificationID != 4)
    {
        cell = worksheet.getCell('H8');
        cell.value = step3Data.PublisherProductNotation;
    
        cell = worksheet.getCell('AB8');
        cell.value = step3Data.PublisherPackageNotation;
    }

    if (step1Data.ProductSpecificationID == 4)
    {
        cell = worksheet.getCell('AE5');
        cell.value = GetTranslation(step1Data.Key_Series, languageCode, dictionaryData);
    }

    cell = worksheet.getCell('AJ7');
    if (step1Data.ProductSpecificationID != 4)
    {
        cell.value = GetTranslation(step2Data.Key_Inner, languageCode, dictionaryData);
    }
    else
    {
        cell.value = GetTranslation(step3Data.InstructionSheet, languageCode, dictionaryData);
    }
    
    cell = worksheet.getCell('AH9');
    if (step1Data.ProductSpecificationID != 4)
    {
        // 0 -> 有り 1 -> 無し
        cell.value = step3Data.InstructionSheetID == 0 ? "1C" : "-";
    }
    else
    {
        cell.value = GetTranslation("spec.doublesideprint", languageCode, dictionaryData);
    }

    cell = worksheet.getCell('AO3');
    cell.value = step1Data.ShippingDate;
    cell.numFmt = 'yyyy-mm-dd';

    cell = worksheet.getCell('AO5');
    cell.value = step1Data.Manufacturer;

    cell = worksheet.getCell('AO7');
    cell.value = step1Data.SalesDate;
    cell.numFmt = 'yyyy-mm-dd';

    if (step1Data.ProductSpecificationID != 4)
    {
        cell = worksheet.getCell('AO9');
    }
    else
    {
        console.log("Get puramo sales destinations cell");
        cell = worksheet.getCell('AT5');
    }
    // cell.value = GetTranslation(salesDestination, languageCode, dictionaryData);
    var salesDestString = "";
    for (var i = 0; i < step1Data.Key_SalesDestinations.length; i++)
    {
        salesDestString += GetTranslation(step1Data.Key_SalesDestinations[i], languageCode, dictionaryData);
        if (i < step1Data.Key_SalesDestinations.length - 1)
        {
            salesDestString += "\n";
        }
    }
    cell.value = salesDestString;

    cell = worksheet.getCell('BE5');
    // cell.value = step2Data.PackageType;
    cell.value = GetTranslation(step2Data.Key_PackageType, languageCode, dictionaryData);

    cell = worksheet.getCell('BE6');
    if (step1Data.ProductSpecificationID != 4)
    {
        console.log("CHECK KEY_PACKAGE TYPE", step2Data.Key_PackageType);
        console.log("CHECK KEY_CUT TYPE", step2Data.Key_CutType);
        console.log("CHECK Key_Print1", step2Data.Key_Print1);

        let cType = '';
        let coating = step2Data.IsCoating ? '+' + GetTranslation('spec.varnishcoat', languageCode, dictionaryData) : '';

        if (step2Data.Key_Print1 == "text.printing.option1" ||
            step2Data.Key_Print1 == "text.printing.option2")
        {
            cType = "4C";
        }
        else
        {
            cType = "1C";
        }
        cell.value = cType + coating;
        /*
        switch(step2Data.Key_PackageType)
        {
            case "text.pkgspec.window":
            case "ウィンドウ":

                switch(step2Data.Key_CutType)
                {
                    case 'text.cut.top_lid':
                    case "天面蓋":
                        // cell.value = "4C+ニスコート";
                        cell.value = GetTranslation('spec.diecut.4coat', languageCode, dictionaryData);
                    break;

                    case 'text.cut.with_door':
                    case "扉付き":
                        // cell.value = "4C+ニスコート";
                        cell.value = GetTranslation('spec.diecut.4coat', languageCode, dictionaryData);
                    break;

                    default:
                        console.log("NOT IMPLEMENTED CUT TYPE", step2Data.Key_CutType);
                        cell.value = "";
                    break;
                }

            break;

            case 'text.pkgspec.closed':
            case "クローズド":

                switch(step2Data.Key_CutType)
                {
                    case 'text.cut.top_lid':
                    case "天面蓋":

                        switch(step2Data.Key_Print1)
                        {
                            case 'text.printing.option1':
                            case "白紙、4C、5面印刷":
                                cell.value = "4C+ニスコート";
                                cell.value = GetTranslation('spec.diecut.4coat', languageCode, dictionaryData);
                            break;

                            case 'text.printing.option2':
                            case "クラフト紙、正面4C印刷":
                                cell.value = "4C";
                                cell.value = GetTranslation('spec.diecut.4c', languageCode, dictionaryData);
                            break;

                            case 'text.printing.option3':
                            case "クラフト紙、5面1C印刷":
                                cell.value = "";
                            break;

                            default:
                                console.log("NOT IMPLEMENTED PRINT 1 TYPE", step2Data.Key_Print1);
                                cell.value = "";
                            break;

                        }

                    break;

                    default:
                        console.log("NOT IMPLEMENTED PRINT 1 TYPE", step2Data.Key_Print1);
                        cell.value = "";
                    break;
                }

            break;

            default:
                console.log("NOT IMPLEMENTED PACKAGE TYPE", step2Data.Key_PackageType);
                cell.value = "";
            break;
        }
        */
    }
    else
    {
        cell.value = GetTranslation(step2Data.Key_PrintPM, languageCode, dictionaryData);
    }
    
    cell = worksheet.getCell('BE7');
    if (step1Data.ProductSpecificationID != 4)
    {
        cell.value = step2Data.MiddleMount;
    }
    else
    {
        cell.value = GetTranslation(step2Data.Key_Wrapping, languageCode, dictionaryData);
    }

    cell = worksheet.getCell('BE8');
    if (step1Data.ProductSpecificationID != 4)
    {
        cell.value = step2Data.TapeStop;
    }
    else
    {
        cell.value = GetTranslation(step2Data.SetContent, languageCode, dictionaryData);
    }

    cell = worksheet.getCell('BK5');
    // cell.value = step2Data.CertStamp;
    cell.value = GetTranslation(step2Data.Key_CertStamp, languageCode, dictionaryData);
    console.log("CERT STAMP", step2Data.Key_CertStamp);

    if (step1Data.ProductSpecificationID != 4)
    {
        cell = worksheet.getCell('AV4');
        cell.value = step3Data.PowerSpec;
        cell = worksheet.getCell('AV5');
        cell.value = step3Data.PowerSpecAmount;
    }

    cell = worksheet.getCell('BG36');
    cell.value = step2Data.InputAmount;

    cell = worksheet.getCell('BG37');
    // 0 -> 有り 1 -> 無し
    cell.value = step2Data.InnerIndex == 0 ? "1" : "-";

    cell = worksheet.getCell('BF51');
    cell.value = step1Data.ProductName;

    cell = worksheet.getCell('BF59');
    cell.value = step1Data.ProductName;

    if (hasInner === true)
    {
        cell = worksheet.getCell('BF78');
        cell.value = step1Data.ProductName;
    }

    if(step2Data.InnerIndex == 0)
    {   
        cell = worksheet.getCell('BF78');
        cell.value = step1Data.ProductName;
    }

    cell = worksheet.getCell('BE65');
    cell.value = step3Data.MadeIn;

    cell = worksheet.getCell('BD1');
    cell.value = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

    cell = worksheet.getCell('BD2');
    cell.value = step4Data.Author;

    cell = worksheet.getCell('AX1');
    cell.value = step4Data.InCharge;

    cell = worksheet.getCell('BD28');
    cell.value = GetTranslation(step2Data.Key_PackageMaterial, languageCode, dictionaryData);

    if (step1Data.ProductSpecificationID !== 4)
    {
        cell = worksheet.getCell('BJ28');
        cell.value = GetTranslation(step2Data.Key_Package1, languageCode, dictionaryData);
    
        cell = worksheet.getCell('BJ29');
        cell.value = GetTranslation(step2Data.Key_Package2, languageCode, dictionaryData);
    
        cell = worksheet.getCell('BJ30');
        cell.value = GetTranslation(step2Data.Key_Package3, languageCode, dictionaryData);
    }
    else
    {
        cell = worksheet.getCell('BJ28');
        cell.value = GetTranslation(step2Data.Key_PackagePM, languageCode, dictionaryData);
    }

    cell = worksheet.getCell('BJ61');
    cell.value = step2Data.InputAmount + "PCS";

    var setRow = 19;
    if (step3Data.LeftSideOutput != undefined)
    {
        for(let j = 0; j < step3Data.LeftSideOutput.length; j++)
        {
            cell = worksheet.getCell('BB'+setRow);
            //console.log("Write [" + step3Data.LeftSideOutput[j] + "・・・1" + "] in BO"+setRow);
            // cell.value = step3Data.LeftSideOutput[j] + "・・・1";
            cell.value = GetTranslation(step3Data.LeftSideOutput[j], languageCode, dictionaryData) + "・・・1";
            setRow++;
        }
    }
    
    setRow = 19;
    if (step3Data.RightSideOutput != undefined)
    {
        for(let j = 0; j < step3Data.RightSideOutput.length; j++)
        {
            cell = worksheet.getCell('BH'+setRow);
            // cell.value = step3Data.RightSideOutput[j];
            cell.value = GetTranslation(step3Data.RightSideOutput[j], languageCode, dictionaryData);
            if (step3Data.RightSideOutputMaterial != undefined)
            {
                cell.value += ' ' + step3Data.RightSideOutputMaterial[j];
            }
            setRow++;
        }
    }

    if (step1Data.ProductSpecificationID !== 4 &&
        step3Data.RightSideOutput2 != undefined)
    {
        for(let j = 0; j < step3Data.RightSideOutput2.length; j++)
        {
            cell = worksheet.getCell('BH' + setRow);
            cell.value = GetTranslation(step3Data.RightSideOutput2[j], languageCode, dictionaryData);
            setRow++;
        }
    }

    let imagePaths = [];
    const imageIds = {};

    if (step2Data.LtdSticker != undefined &&
        step2Data.LtdSticker != '')
    {
        imagePaths.push("/assets/" + step2Data.LtdSticker);
    }

    if (step2Data.PackageImage != undefined &&
        step2Data.PackageImage != '')
    {
        imagePaths.push("/assets/PkgImg/" + step2Data.PackageImage);
    }

    if (step1Data.ProductSpecificationID == 4 ||
        step2Data.PackageMaterialID == 1)
    {
        imagePaths.push("/assets/Icon/Icon_paper.svg");    
    }
    else if (step2Data.PackageMaterialID == 2)
    {
        imagePaths.push("/assets/Icon/Icon_danboru.svg");
    }

    imagePaths.push("/assets/Icon/Icon_pura.svg");

    Promise.all(
        imagePaths.map(imagePath =>
            fetch(imagePath)
            .then(response => response.blob())
            .then(blob => {
                const fExt = imagePath.split('.').pop();
                let id = workbook.addImage({
                    buffer: blob,
                    extension: fExt.toLowerCase()//'png'
                });
                imageIds[imagePath] = id;
                // blob.text().then(text => {
                //     console.log("Fetched image " + imagePath + " ID: " + id + " BlobText: " + text);
                // });
                
            })
        )
    )
    .then(() =>
    {
        let imgID = "";
        if (step1Data.ProductSpecificationID == 4 ||
            step2Data.PackageMaterialID == 1)
        {
            imgID = imageIds["/assets/Icon/Icon_paper.svg"];    
        }
        else if (step2Data.PackageMaterialID == 2)
        {
            imgID = imageIds["/assets/Icon/Icon_danboru.svg"];
        }
        
        if (imgID != "")
        {
            worksheet.addImage(
                imgID,
                'BB28:BC30'
            );
        }
        
        imgID = imageIds["/assets/Icon/Icon_pura.svg"];
        
        worksheet.addImage(
            imgID,
            'BH29:BI31'
        );
                
        if (step2Data.PackageImage != undefined &&
            step2Data.PackageImage != '')
        {
            imgID = imageIds["/assets/PkgImg/" + step2Data.PackageImage];
            worksheet.addImage(
                imgID,
                'BC10:BG16'
            );
        }

        if (step2Data.LtdSticker != undefined &&
            step2Data.LtdSticker != '')
        {
            imgID = imageIds["/assets/" + step2Data.LtdSticker];
            worksheet.addImage(
                imgID,
                'BI10:BL15'
            );
        }

        callback();
    });

}

export async function ExportFile(workbook, productName, language)
{
    console.log("(ExportFile)");
    console.log("(ExportFile-D) Get worksheet for debugging");
    let worksheet = workbook.getWorksheet(1);
    console.log("(ExportFile-D) Go through all cells and print their content");
    console.log("workbook", workbook);
    console.log("worksheet",worksheet);
    for (const row of worksheet.getRows())
    {
        console.log("Test");
        for (const cell of row.eachCell())
        {
          console.log(`Cell value: ${cell.value}`);
        }
    }

    productName = CheckFilename(productName);
    console.log("[ExportFile] Save " + productName + "_仕様_" + language + ".xlsx");
     
    // Test
    // var worksheet = workbook.getWorksheet('仕様');
    // const images = worksheet.getImages();
    // console.log("[ExportFile] images check",images);
    // for (const image of images) {
    //     console.log("[ExportFile] image path check: ", image); // This will be the path to the image
    // }
    
    workbook.xlsx.writeBuffer().then(fileblob =>
    {
        console.log("[ExportAsExcel] Call file saver for " + productName + "_仕様_" + language + ".xlsx");
        FileSaver.saveAs(new Blob([fileblob]), productName + "_仕様_" + language + ".xlsx"); 
    });
}

export async function GenerateSpecSheet(worksheet, hasInner, languageCode, dictionaryData, step1Data)
{
    try
    {
        let cell;
        // cell content and layout
        cell = worksheet.getCell('A1');
        cell.value = GetTranslation("spec.productspecifications", languageCode, dictionaryData);

        cell = worksheet.getCell('A3');
        cell.value = GetTranslation("spec.productnumber", languageCode, dictionaryData);

        cell = worksheet.getCell('A5');
        cell.value = GetTranslation("spec.productname", languageCode, dictionaryData);

        cell = worksheet.getCell('A7');
        cell.value = GetTranslation("spec.bodycopyrightnotice", languageCode, dictionaryData);

        cell = worksheet.getCell('D7');
        cell.value = GetTranslation("spec.notation.position", languageCode, dictionaryData);

        cell = worksheet.getCell('D10');
        cell.value = GetTranslation("spec.notation.method", languageCode, dictionaryData);

        cell = worksheet.getCell('V3');
        cell.value = GetTranslation("spec.productspec", languageCode, dictionaryData);

        cell = worksheet.getCell('H7');
        if (step1Data.ProductSpecificationID != 4)
        {
            cell.value = GetTranslation("spec.body", languageCode, dictionaryData);
        }
        else
        {
            //版権表記内容
            cell.value = GetTranslation("spec.copyrightinformation", languageCode, dictionaryData);
        }

        cell = worksheet.getCell('V7');
        if (step1Data.ProductSpecificationID != 4)
        {
            cell.value = "PKG";
        }
        else
        {
            //短縮表記内容
            cell.value = GetTranslation("spec.abbreviatedcontent", languageCode, dictionaryData);
        }

        cell = worksheet.getCell('AH7');
        cell.value = GetTranslation("spec.instructions", languageCode, dictionaryData);

        cell = worksheet.getCell('AL3');
        cell.value = GetTranslation("spec.factoryshipment", languageCode, dictionaryData);

        cell = worksheet.getCell('AL5');
        cell.value = GetTranslation("spec.productionfactory", languageCode, dictionaryData);

        cell = worksheet.getCell('AL7');
        cell.value = GetTranslation("spec.releasedate", languageCode, dictionaryData);

        if (step1Data.ProductSpecificationID != 4)
        {
            cell = worksheet.getCell('AL9');
        }
        else
        {
            cell = worksheet.getCell('AT3');
        }
        cell.value = GetTranslation("spec.salesdestination", languageCode, dictionaryData);
        console.log("Insert translation hanbaisaki", GetTranslation("spec.salesdestination", languageCode, dictionaryData));

        cell = worksheet.getCell('AL11');
        cell.value = GetTranslation("spec.ingmoldnumber", languageCode, dictionaryData);

        if (step1Data.ProductSpecificationID != 4)
        {
            cell = worksheet.getCell('AT3');
            cell.value = GetTranslation("spec.powerspecifications", languageCode, dictionaryData);
    
            cell = worksheet.getCell('AX3');
            cell.value = GetTranslation("spec.pedestalspecifications", languageCode, dictionaryData);
    
            cell = worksheet.getCell('AT4');
            cell.value = GetTranslation("spec.kinds", languageCode, dictionaryData);
    
            cell = worksheet.getCell('AT5');
            cell.value = GetTranslation("spec.quantity", languageCode, dictionaryData);
    
            cell = worksheet.getCell('AX4');
            cell.value = GetTranslation("spec.kinds", languageCode, dictionaryData);
    
            cell = worksheet.getCell('AX5');
            cell.value = GetTranslation("spec.quantity", languageCode, dictionaryData);
        }

        cell = worksheet.getCell('AV1');
        cell.value = GetTranslation('spec.incharge', languageCode, dictionaryData);

        cell = worksheet.getCell('BB1');
        cell.value = GetTranslation("spec.createdat", languageCode, dictionaryData);

        cell = worksheet.getCell('BB2');
        cell.value = GetTranslation("spec.author", languageCode, dictionaryData);

        cell = worksheet.getCell('BH1');
        cell.value = GetTranslation("spec.updatedat", languageCode, dictionaryData);

        cell = worksheet.getCell('BH2');
        cell.value = GetTranslation("spec.changedby", languageCode, dictionaryData);

        cell = worksheet.getCell('BB3');
        cell.value = GetTranslation("spec.packingspecifications", languageCode, dictionaryData);

        cell = worksheet.getCell('BB5');
        cell.value = GetTranslation("spec.pkgform", languageCode, dictionaryData);

        cell = worksheet.getCell('BB6');
        cell.value = GetTranslation("spec.printing", languageCode, dictionaryData);

        cell = worksheet.getCell('BB7');
        cell.value = GetTranslation("spec.middlemount", languageCode, dictionaryData);

        cell = worksheet.getCell('BB8');
        if (step1Data.ProductSpecificationID != 4)
        {
            cell.value = GetTranslation("spec.tapestop", languageCode, dictionaryData);
        }
        else
        {
            cell.value = GetTranslation("spec.setcontent", languageCode, dictionaryData);
        }

        cell = worksheet.getCell('BH5');
        cell.value = GetTranslation("spec.pastecertstamp", languageCode, dictionaryData);

        cell = worksheet.getCell('BH6');
        cell.value = GetTranslation("spec.pkgsize", languageCode, dictionaryData);

        cell = worksheet.getCell('BB18');
        cell.value = GetTranslation("spec.setcontent", languageCode, dictionaryData);

        cell = worksheet.getCell('BH18');
        cell.value = GetTranslation("spec.productmaterial", languageCode, dictionaryData);

        cell = worksheet.getCell('BB27');
        cell.value = GetTranslation("spec.pkgmaterial", languageCode, dictionaryData);

        cell = worksheet.getCell('BB34');
        cell.value = GetTranslation("spec.cartonspecifications", languageCode, dictionaryData);

        cell = worksheet.getCell('BB35');
        cell.value = GetTranslation("spec.qtypercarton", languageCode, dictionaryData);
        
        cell = worksheet.getCell('BB36');
        cell.value = GetTranslation("spec.outer", languageCode, dictionaryData);

        cell = worksheet.getCell('BB37');
        cell.value = GetTranslation("spec.inner", languageCode, dictionaryData);
        
        cell = worksheet.getCell('BB38');
        cell.value = GetTranslation("spec.cartonsize", languageCode, dictionaryData);

        cell = worksheet.getCell('B81');
        cell.value = GetTranslation("spec.finalnote", languageCode, dictionaryData);

        cell = worksheet.getCell('BC82');
        cell.value = GetTranslation("spec.tapenote", languageCode, dictionaryData);
        
        cell = worksheet.getCell('BB42');
        cell.value = GetTranslation("spec.outercarton1", languageCode, dictionaryData);
        
        cell = worksheet.getCell('BI42');
        cell.value = GetTranslation("spec.outercase", languageCode, dictionaryData);

        cell = worksheet.getCell('BB55');
        cell.value = GetTranslation("spec.outercarton2", languageCode, dictionaryData);
        
        cell = worksheet.getCell('BI55');
        cell.value = GetTranslation("spec.outercase", languageCode, dictionaryData);

        cell = worksheet.getCell('BI34');
        cell.value = GetTranslation("spec.printingnote", languageCode, dictionaryData);

        if(hasInner === true)
        {
            cell = worksheet.getCell('BB69');
            cell.value = GetTranslation("spec.innercartonmarkinpos", languageCode, dictionaryData);
            
            cell = worksheet.getCell('BI69');
            cell.value = GetTranslation("spec.innerbox", languageCode, dictionaryData);
        }
    }
    catch (error)
    {
        console.log("[JP]:: " + error);
    }
}