import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

//createApp(App).use(router).use(store).mount('#app');

// Create the Vue app instance
const app = createApp(App);

function setDateFormat(value)
{
    if (!value) return '';
    const date = new Date(value);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
}

function HandleBadRequest(status, data)
{
    if (status == 401)
    {
        if (data == 1)
        {
            const router = app.config.globalProperties.$router;
            router.push('/');
        }
    }
}

// Provide the utility function globally
app.config.globalProperties.$setDateFormat = setDateFormat;
app.config.globalProperties.$HandleBadRequest = HandleBadRequest;

// Use plugins and mount the app
app.use(router).use(store).mount('#app');