export function ShowLoading(state, msg)
{
    // Check if we already have a loading animation
    var loadingAnimationContainer = document.getElementById("LoadingAnimationContainer");
    if (loadingAnimationContainer != null && state == true)
    {
        return;
    }
    
    if (loadingAnimationContainer != null && state == false)
    {
        loadingAnimationContainer.remove();
        return;
    }

    let scrollY = 0;
    
    if (document.documentElement.scrollTop != 0 ||
        document.body.scrollTop != 0)
    {
        scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    }

    loadingAnimationContainer = document.createElement("div");
    loadingAnimationContainer.id = "LoadingAnimationContainer";
    loadingAnimationContainer.style.top = scrollY + 'px';

    var loadingAnimation = document.createElement("div");
    loadingAnimation.classList.add("loader");
    loadingAnimationContainer.append(loadingAnimation);

    var loadingMsg = document.createElement("div");
    loadingMsg.id = "LoadingMessage";
    loadingMsg.innerHTML = msg;
    loadingAnimationContainer.append(loadingMsg);

    document.body.append(loadingAnimationContainer);
}