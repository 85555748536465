<template>
    <div id="SpecSheetList">
        <div id="SpecSheetListHeader" class="ThemeElement" Darkmode="DM_SheetEntry">
          <div class="EntryInfo localize" data-key="text.specsheetname">Sheet name</div>
          <div class="EntryInfo localize" data-key="text.author.title">Author</div>
          <div class="EntryInfo localize" data-key="text.createdat">Created at</div>
          <div class="EntryInfo" style="flex: 0.5;"></div>
          <div class="EntryInfo" style="flex: 0.5;"></div>
          <div class="EntryInfo" style="flex: 0.5;"></div>
        </div>
        <SpecSheetEntry class="ThemeElement" Darkmode="DM_SheetEntry" v-for="entry in SpecSheets" :key="entry.Name" :ContainerID="entry.ID" :SheetEntryName="entry.Name" :SheetAuthor="entry.Username" :SheetCreatedOn="$setDateFormat(entry.CreatedOn)"></SpecSheetEntry>
    </div>
</template>

<style>
#SpecSheetList
{
    padding: 0px 50px;
}

#SpecSheetList .SheetEntryContainer + .SheetEntryContainer
{
    margin-top: 16px;
}

.DM_SheetEntry
{
  background-color: #5f5f5f;
  border: 1px #c8c8c8 solid;
  border-radius: 5px;
  color: #ccc;
}

#SpecSheetListHeader
{
  display: flex;
  height: 30px;
  margin-bottom: 8px;
}

#SpecSheetListHeader div
{
  font-size: 130%;
  font-weight: bold;
}

.SheetEntryButton
{
  border: none;
  min-width: 50px;
  height: 30px;
  border-radius: 20px;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 95%;
  display: grid;
  justify-content: center;
  align-items: center;
}

</style>

<script>
import axios from 'axios';
import { InitDarkmode, ApplyDarkmode } from './Darkmode.js';
import SpecSheetEntry from './components/SpecSheetEntry.vue';
import { mapActions } from 'vuex';
import { LocalizationManager } from './Localization.js';
import { ShowLoading } from './FullScreenLoading.js';

export default
{
  name: 'App',
  mounted(){
    document.title = "EZ-SPEC-Specifications";

    if (localStorage.getItem('session_token') == null)
    {
        this.$router.push('/');
        return;
    }
    else
    {
        this.saveToken(localStorage.getItem('session_token'));
    }

    if (LocalizationManager.IsLocaInitialized == false)
    {
        LocalizationManager.Initialize();
    }

    this.RetrieveSpecificationSheets();

    this.$nextTick(()=>{
        InitDarkmode();
    });
  },
  components:{
    SpecSheetEntry
  },
  methods:{
    RetrieveSpecificationSheets()
    {
        ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

        var sheetEntries = document.getElementsByClassName("SheetEntryContainer");
        for(var i = sheetEntries.length - 1; i >= 0; i--)
        {
            sheetEntries[i].remove();
        }

        let data = 
        {
            SessionToken: localStorage.getItem('session_token')
        };
        
        axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "sheets", data)
        .then(response => 
        {
            console.log("Received sheets data.", response);
            ShowLoading(false);

            this.SpecSheets = response.data;
            
            // It might take a bit longer than just one tick
            // so we can't use this.$nextTick here
            setTimeout(()=>
            {
              ApplyDarkmode();
            }, 50);
        })
        .catch(error =>
        {
            console.log("Received error.", error);
            ShowLoading(false);
            this.$HandleBadRequest(error.response.status, error.response.data);
        })
    },
    ...mapActions(['saveToken']),
  },
  data(){
    return {
      SpecSheets: [],
    }
  }
}
</script>