import { createRouter, createWebHashHistory/*, createWebHistory*/} from 'vue-router';
import Step_1 from '../Step_1.vue';
import Step_2 from '../Step_2.vue';
import Step_3 from '../Step_3.vue';
import Step_4 from '../Step_4.vue';
import SpecSheets from '../SpecSheetList.vue';
import MasterData from '../pages/MasterData.vue';
import ScaleData from '../pages/ScaleData.vue';
import SeriesData from '../pages/SeriesData.vue';
import LicensorData from '../pages/LicensorData.vue';
import FactoryData from '../pages/FactoryData.vue';
import ProductTypeData from '../pages/ProductTypeData.vue';
import SeriesTable from '../pages/SeriesTable.vue';
import FactoriesTable from '../pages/FactoriesTable.vue';
import TermTranslation from '../TermTranslationList.vue'
import UserLogin from '../UserLogin.vue';
import UserRegister from '../UserRegister.vue';
import UserForgotPassword from '../UserForgotPassword.vue';

const routes = [
    { path: '/', component: UserLogin, name: "EZ-SPEC Login" },
    { path: '/register', component: UserRegister, name: "EZ-SPEC Register" },
    { path: '/forgotpassword', component: UserForgotPassword, name: "EZ-SPEC Forgot password" },
    { path: '/step1', component: Step_1, name: "EZ-SPEC-Step 1" },
    { path: '/step2', component: Step_2, name: "EZ-SPEC-Step 2" },
    { path: '/step3', component: Step_3, name: "EZ-SPEC-Step 3" },
    { path: '/step4', component: Step_4, name: "EZ-SPEC-Step 4" },
    { path: '/specsheets', component: SpecSheets, name: "EZ-SPEC-Sheets" },
    { path: '/masterdata', component: MasterData, name: "EZ-SPEC-Master data"},
    { path: '/masterdata/scales', component: ScaleData, name: "EZ-SPEC-Scales"},
    { path: '/masterdata/producttypes', component: ProductTypeData, name: "EZ-SPEC-Product types"},
    { path: '/masterdata/series', component: SeriesData, name: "EZ-SPEC-Series"},
    { path: '/masterdata/licensors', component: LicensorData, name: "EZ-SPEC-Licensors"},
    { path: '/masterdata/factories', component: FactoryData, name: "EZ-SPEC-Factories"},
    { path: '/masterdata/seriestable', component: SeriesTable, name: "EZ-SPEC-Series table"},
    { path: '/masterdata/factoriestable', component: FactoriesTable, name: "EZ-SPEC-Factories table"},
    { path: '/masterdata/termtranslation', component: TermTranslation, name: 'EZ-SPEC-Term Translation' },
];

const router = createRouter({
    //history: createWebHistory(),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

export default router;