<template>
    <div id="Wrapper">
        <div id="ForgotPasswordContainer">
            <div class="ForgotPasswordContentContainer">
                <div class="InputLabel localize" data-key="text.email">Email</div>
                <input id="Email" type="text" />
                <div id="Feedback" class="localize" data-key="text.mailsent">
                    Email has been sent. Please check your mailbox.
                </div>
            </div>
            <div class="ForgotPasswordContentContainer">
                <div id="ForgotPasswordButton" class="Button ButtonGreen ForgotPasswordRegPageButton localize" data-key="text.request" @click="RequestPassword">Request</div>
                <div id="RegisterButton" style="margin-top: 25px;" class="Button ButtonGreen ForgotPasswordRegPageButton localize" data-key="text.register" @click="OpenRegistration">Register</div>
                <div id="LoginButton" class="Button ButtonGreen ForgotPasswordRegPageButton localize" data-key="text.login" @click="OpenLogin">Login</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { InitDarkmode } from './Darkmode.js';
import { LocalizationManager } from './Localization.js';
import { ShowLoading } from './FullScreenLoading';

export default {
    mounted(){
        InitDarkmode();

        if (LocalizationManager.IsLocaInitialized == false)
        {
            LocalizationManager.Initialize();
        }
        
        LocalizationManager.TranslatePage();

        let self = this;

        let inp = document.getElementById('Email');
        inp.addEventListener('keypress', function(event)
        {
            if (event.key === 'Enter')
            {
                self.Request();
            }
        });
    },
    methods:{
        RequestPassword() 
        {
            ShowLoading(true, LocalizationManager.TranslateKey("text.loading"));

            let data = {
                Email: document.getElementById("Email").value,
            };
            
            axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + "forgotpassword", data)
            .then(async response =>
            {
                console.log(response.data);
                var msg = document.getElementById("Feedback");
                if (msg != null)
                {
                    msg.style.opacity = "1.0";
                }
                ShowLoading(false);
                //this.$router.push('/step1');
            })
            .catch(error =>
            {
                ShowLoading(false);
                console.log("Received error", error);
                if (error.response.status == 400){
                    alert(error.response.data);
                }
            });
        },
        OpenRegistration()
        {
            this.$router.push('/register');
        },
        OpenLogin()
        {
            this.$router.push('/');
        },
    }
}
</script>

<style>
input
{
    height: 30px;
    border-radius: 10px;
}

#Wrapper
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    justify-content: center;
    align-content: center;
}

#Feedback
{
    opacity: 0;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2;
    font-weight: bold;
}

#ForgotPasswordContainer
{
    padding: 30px 25px;
    background-color: rgb(90, 196, 189);
    border-radius: 20px;
    width: 400px;
    color: #fff;
}

.ForgotPasswordContentContainer
{

}

#ForgotPasswordButton
{

}

.ForgotPasswordRegPageButton
{
    display: grid;
    align-items: center;
    justify-content: center;
}

.LinkText
{
    cursor: pointer;
    color: #00f;
}

.InputLabel
{
    font-weight: bold;;
    margin-bottom: 8px;
}

#ForgotPasswordContainer .ForgotPasswordContentContainer + .ForgotPasswordContentContainer
{
    margin-top: 20px;
}

.ForgotPasswordContentContainer div + div
{
    margin-top: 10px;
}
</style>