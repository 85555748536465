<template>
  <div id="TopRibbon">
    <div v-if="GetToken != ''" id="MenuButton" class="DarkColorBackground">
      <div style="display: grid; align-items: center; justify-items: center; width: 50px; height: 100%;">
        <div id="menu-container">
          <div id="menu-button" @click="ToggleMenu" style="cursor: pointer"><img style="height: 32px; width: 32px;" src="/assets/Icon/Icon_Menu.svg" /></div>
          <div id="menu">
            <div class="Sidemenubutton localize" data-key="text.specsheet.new" @click="NewSpecSheet" v-on:mouseenter="DisableSubmenus">New spec/新しい仕様書</div>
            <div class="Sidemenubutton" submenu="ExportMenu" @click='ToggleSubmenu' v-on:mouseenter="DisableSubmenus"><span class="localize" style="pointer-events: none;" data-key="text.export">Export</span>
              <span class="FoldoutIconContainer" style="pointer-events: none;"><img class="FoldoutIcon" style="pointer-events: none;" src="/assets/Icon_Triangle.svg"></span>
              <div id="ExportMenu" class="Submenu">
                <div class="Sidemenubutton localize" data-key="text.blank" @click="Export_Blank">Blank</div>
                <div class="Sidemenubutton localize" data-key="text.blank_jp" @click="Export_BlankJP">Blank JP</div>
                <div class="Sidemenubutton localize" data-key="text.blank_en" @click="Export_BlankEN">Blank EN</div>
                <div class="Sidemenubutton localize" data-key="text.blank_zhcn" @click="Export_BlankZHCN">Blank ZH_CN</div>
              </div>
            </div>
            <div class="Sidemenubutton localize" data-key="text.specsheets" @click="LoadSpecSheetsMenu" v-on:mouseenter="DisableSubmenus">Spec sheets</div>
            <div class="Sidemenubutton localize" data-key="text.masterdata" @click="OpenMasterdata" v-on:mouseenter="DisableSubmenus">Master data</div>
            <!-- <div class="Sidemenubutton localize" data-key="text.termtranslation" @click="OpenTermsTranslations" v-on:mouseenter="DisableSubmenus">Translations</div> -->
            <div class="Sidemenubutton localize" data-key="text.logout" @click="Logout" v-on:mouseenter="DisableSubmenus">Logout</div>
          </div>
          <div id="overlay" style="display: none;" @click="ToggleMenu"></div>
        </div>
      </div>
    </div>
    <div id="TopRibbonContent" class="BrightColorBackground">
      <div id="AppLogoBigContainer">
        <img style="height: 85%; cursor: pointer;" src="/assets/logo/EZspec_Logo_Big_Var_04_T.png" @click="GoToHome"/>
      </div>
      <div id="ProjectTitle" class="BrightTextColor">
        <span>３部・生産管理</span><span id="Version">v1.0 {{ Environment }}</span>
      </div>
      <div style="height: 100%; width: 50px; display: flex; align-items: center;">
        <div class="LanguageSelector" @click="SwitchLanguage" id="en">EN</div>/<div class="LanguageSelector" @click="SwitchLanguage" id="jp">JP</div>
      </div>
      <div style="height: 100%; width: 30px; display: grid; align-items: center;">
        <img style="height: 50%; width: 100%; cursor: pointer;" src="/assets/Icon_Brightness.svg" @click="ToggleDarkmode">
      </div>
      <div style="height: 100%; width: 50px;">
        <img class="AppLogoSmall" src="/assets/logo/EZspec_Logo_Small.png"/>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script>
// import ExportAsExcelButton from './components/ExportAsExcelButton.vue';
import { DarkmodeSwitch } from './Darkmode.js';
import { ExportBlank, ExportBlank_JP, ExportBlank_EN, ExportBlank_ZH_CN } from './ExportAsExcel.js';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { LocalizationManager } from './Localization.js';
import axios from 'axios';

export default {
  name: 'App',
  components:{
    // ExportAsExcelButton,
  },
  mounted()
  {
    if (LocalizationManager.IsLocaInitialized == false)
    {
        LocalizationManager.Initialize();
    }

    var recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://x-plus.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9b445e7f')
    document.head.appendChild(recaptchaScript)
  },
  methods:
  {
    ...mapActions(['saveStep1Data','saveStep2Data','saveStep3Data','saveStep4Data','saveToken']),
    ToggleDarkmode()
    {
        DarkmodeSwitch();
    },
    ToggleMenu()
    {
      var men = document.getElementById("menu");
      var ove = document.getElementById("overlay");
      
      this.IsMenuOn = !this.IsMenuOn;

      ove.style.display = this.IsMenuOn ? "block" : "none";
      if (this.IsMenuOn)
      {
        men.classList.add("menuOpen");
      }
      else
      {
        var subMenus = document.getElementsByClassName("Submenu");
        for(let i = 0; i < subMenus.length; i++)
        {
          subMenus[i].classList.remove("SubmenuOpen");
        }

        men.classList.remove("menuOpen");
        this.DisableSubmenus(null);
      }
    },
    ToggleSubmenu(event)
    {
      var submenuID = event.target.getAttribute("submenu");
      var subMen = document.getElementById(submenuID);
      if (subMen != null)
      {
        if (subMen.classList.contains("SubmenuOpen") == true)
        {
          subMen.classList.remove("SubmenuOpen");
          event.target.classList.remove("SidemenubuttonOpen");
        }
        else
        {
          subMen.classList.add("SubmenuOpen");
          subMen.style.left = event.target.offsetWidth + "px";
          event.target.classList.add("SidemenubuttonOpen");
        }
      }
    },
    DisableSubmenus(event)
    {
      if (event != null &&
          event.target.classList.contains("SidemenubuttonOpen") == true)
      {
        return;
      }
      var targets = document.getElementsByClassName("Submenu");
      for(let i = 0; i < targets.length; i++)
      {
        targets[i].classList.remove("SubmenuOpen");
      }
      targets = document.getElementsByClassName("SidemenubuttonOpen");
      for(let i = 0; i < targets.length; i++)
      {
        targets[i].classList.remove("SidemenubuttonOpen");
      }
    },
    Export_Blank()
    {
      ExportBlank();
      this.ToggleMenu();
    },
    Export_BlankJP()
    {
      this.ToggleMenu();

      var data = {
          SessionToken: localStorage.getItem('session_token')
      };

      axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'termtranslations/excel', data)
      .then(response =>
      {
          console.log(response);
          ExportBlank_JP(response.data);
      })
      .catch(error=>
      {
          console.log("Received error", error);
          if (error.response.status != 500)
          {
              this.$HandleBadRequest(error.response.status, error.response.data);
          }
      });
    },
    Export_BlankEN()
    {
      this.ToggleMenu();

      var data = {
          SessionToken: localStorage.getItem('session_token')
      };

      axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'termtranslations/excel', data)
      .then(response =>
      {
          console.log("Got dictionary for Excel",response);
          ExportBlank_EN(response.data);
      })
      .catch(error=>
      {
          console.log("Received error", error);
          if (error.response.status != 500)
          {
              this.$HandleBadRequest(error.response.status, error.response.data);
          }
      });
    },
    Export_BlankZHCN()
    {
      this.ToggleMenu();
      
      var data = {
          SessionToken: localStorage.getItem('session_token')
      };

      axios.post(process.env.VUE_APP_PROJ_SPEC_API_URL + 'termtranslations/excel', data)
      .then(response =>
      {
          console.log(response);
          ExportBlank_ZH_CN(response.data);
      })
      .catch(error=>
      {
          console.log("Received error", error);
          if (error.response.status != 500)
          {
              this.$HandleBadRequest(error.response.status, error.response.data);
          }
      });
    },
    LoadSpecSheetsMenu()
    {
      //alert("Not yet implemented.\nまだ実装していません。");
      this.$router.push('/specsheets');
      this.ToggleMenu();
    },
    SwitchLanguage(event)
    {
      LocalizationManager.ChangeLanguage(event);
    },
    NewSpecSheet()
    {
      this.saveStep1Data(null);
      this.saveStep2Data(null);
      this.saveStep3Data(null);
      this.saveStep4Data(null);
      this.$router.push('/step1');

      if (this.$router.currentRoute._value.path == "/step1")
      {
        window.location.reload();
      }
      else
      {
        this.ToggleMenu();
      }
    },
    GoToHome()
    {
      this.$router.push('/step1');
    },
    OpenMasterdata()
    {
      this.ToggleMenu();
      this.$router.push('/masterdata');
    },
    Logout()
    {
      this.ToggleMenu();
      this.saveToken('');
      localStorage.removeItem('session_token');

      this.$router.push('/');
    },
  },
  computed:{
      ...mapGetters(['getStep1Data','getStep2Data','getStep3Data','getStep4Data','getToken']),
      Step1Data(){
          return this.getStep1Data;
      },
      Step2Data(){
          return this.getStep2Data;
      },
      Step3Data(){
          return this.getStep3Data;
      },
      Step4Data(){
          return this.getStep4Data;
      },
      GetToken(){
          return this.getToken;
      }
  },
  data(){
    return {
      IsMenuOn: false,
      Environment: process.env.VUE_APP_ENVIRONMENT,
    }
  }
};
</script>

<style>
body,html{
  padding: 0px;
  margin: 0px;
  background-color: rgb(255,255,255);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #222;
}

.NoSelect
{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

select{
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

#menu-button
{
  display: flex;
  justify-content: center;
  align-content: center;
}

#MainContent
{
  padding: 0px 25px;
  display: inline-block;
  line-height: 1.6;
}

#MainContent .FormEntry + .FormEntry
{
  margin-top: 8px;
}

#TopRibbon
{
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 20px;
}

#Version
{
  align-content: end !important;
  position: relative;
  bottom: 5px;
  font-size:50%;
}

#ProjectTitle
{
  flex: 1;
  height: 100%;
  align-content: center;
  display: flex;
  padding-left: 8px;
  font-size: 1.5rem;
}

#ProjectTitle span
{
  align-content: center;
  display: grid;
}

.Button
{
  border: none;
  min-width: 80px;
  height: 30px;
  border-radius: 20px;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 95%;
}

@media only screen and (max-width: 768px)
{
  .Button
  {
    height: 45px;
    min-width: 125px;
    font-size: 120%;
  }
}

.ButtonWhite
{
  background-color: #fff;
  color: rgb(0, 169, 157);
}

.ButtonGreen
{
  background-color: rgb(0, 169, 157);
  color: #fff;
}

.NextPrevBtn
{
  margin-top: 20px;
  margin-bottom: 20px;
}

.NextPrevBtn + .NextPrevBtn
{
  margin-left: 20px;
}

.BrightTextColor
{
  color: #fff;
}

.DarkTextColor
{
  color: #222;
}

.BrightColorText
{
  color: rgb(90, 196, 189);
}

.BrightColorBackground
{
  background-color: rgb(90, 196, 189);
}

.DarkColorText
{
  color: rgb(0, 169, 157);
}

.DarkColorBackground
{
  background-color: rgb(0, 169, 157);
}

.DisabledButton
{
  background-color: #888 !important;
  cursor: default;
}

.LanguageSelector
{
  color: #444;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  cursor: pointer;
}

.LanguageSelector:hover
{
  color: #eee;
}

.ActiveLanguage
{
  color: #eee;
}

.HintText
{
    line-height: 1.2;
    font-style: italic;
    font-size: 0.9rem;
}

#MenuButton
{
  
}

#AppLogoBigContainer
{
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppLogoBig{
  height: 100%;
}

.AppLogoSmall{
  height: 100%;
  width: 50px;
}

#TopRibbonContent
{
  flex: 1;
  display: flex;
}

.ContentSubline
{
  border-bottom: 2px rgb(194, 194, 194) solid;
}

.ContentContainer
{
  background-color: #fff;
  border: 1px #c8c8c8 solid;
  box-shadow: 2px 2px 0px 0px #c8c8c8;
  padding: 25px;
  font-size: 1.3rem;
}

.ContentHeadline
{
  display: flex;
}

.ContentHeadlineContent
{
  color: rgb(90, 196, 189);
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  margin-bottom: 12px;
}

.ContentHeadlineContent::before
{
  content: "";
  display: block;
  width: 8px;
  height: 25px;
  margin-right: 8px;
  background-color: rgb(0, 169, 157);
}

#SummaryContainer
{
  display: inline-block;
  border-radius: 10px;
  border: #ddd 2px solid;
  padding: 15px;
  margin-left: 25px;
  min-width: 400px;
}

#SummaryContainer .SummaryEntry + .SummaryEntry
{
  margin-top: 8px;
}

.SummaryEntry
{
  display: flex;
}

.SummaryEntryLabel
{
  flex: 1;
}

.SummaryEntryValue
{
  flex: 1;
}

.FormEntry
{
  display: flex;
}

.FormEntry div
{
  width: 320px;
}

.FormEntry div select
{
  width: 100%;
  font-size: 90%;
}

input
{
  width: 100%;
  border: none;
  font-size: 90%;
}

.FormEntry span
{
  width: 220px;
}

.FormEntry .spacer
{
  width: 60px;
}

#BlankExportBtn
{
  filter: invert(1);
}

.DisabledTextLabel{
  color: #bbb;
}

/* MENU */
#menu-container {
  /* Position the menu and overlay as needed */
  
}

#menu {
  position: absolute;
  overflow: hidden;
  width: 0px;
  top: 50px;
  left: 0px;
  background-color: rgb(0, 169, 157);
  z-index: 2;
  transition: width 0.2s;
  -moz-transition: width 0.2s;
  -webkit-transition: width 0.2s;
  color: #fff;
}

.menuOpen
{
  width: 300px !important;
  overflow: visible !important;
}

.Sidemenubutton
{
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  background-color: rgb(0, 169, 157);
  white-space: nowrap;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Sidemenubutton:hover
{
  background-color: rgb(127, 209, 204);
}

.SidemenubuttonOpen .FoldoutIconContainer
{
  transform: rotateX(180deg);
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  z-index: 1; /* Ensure it's behind the menu */
}

.Submenu
{
  overflow: hidden;
  top: 0px;
  position: absolute;
  height: 0px;
  width: 200px;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
}

.Submenu .Sidemenubutton
{
  height: 0px;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
}

.SubmenuOpen
{
  overflow: visible !important;
  height: auto;
}

.SubmenuOpen .Sidemenubutton
{
  height: 60px !important;
}

.FoldoutIconContainer
{
  position: absolute;
  right: 10px;
  transform: rotateX(0deg);
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
}

.FoldoutIcon
{
  width: 14px;
}

/* DARK MODE SETTINGS */
.DM_MainBackground
{
  background-color: #222;
}

.DM_ContentContainer
{
  background-color: #222;
  border: 1px #c8c8c8 solid;
  box-shadow: 2px 2px 0px 0px #c8c8c8;
  color: #ccc;
}

.DM_HelpContainer
{
  background-color: #222;
  color: #ccc;
  padding-bottom: 105px;
}

.DM_SummaryContainer
{
  color: #ccc;
}

.DM_Select Select
{
  background-color: #444;
  color: #ccc;
  border: 1px solid #444 !important;
}

.DM_Input Input
{
  background-color: #444;
  color: #ccc;
}

.DM_Text
{
  color: #ccc;
}

/* LOADING ANIMATION */
#LoadingAnimationContainer
{
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  justify-items: center;
  align-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0.3,0.3,0.3,0.8);
  z-index: 99;
}

#LoadingMessage
{
  color: #5ac4bd;
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.3rem;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.loader:before , .loader:after{
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.loader:after {
  box-shadow: 0 2px 0 #5ac4bd inset;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {  transform: rotate(0)}
  100% { transform: rotate(360deg)}
}

/*

## General Dialog

*/
#popup
{
  width: 400px;
  border-radius: 15px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  font: #272727;
  display: flex;
  flex-direction: column;
  padding: 20px 5px;
}

.DM_PopupBackground
{
  background-color: #333 !important;
  color: #cacaca !important;
  border: solid 1px #ccc !important;
}

#PopupMessage
{
  flex: 1;
  padding: 10px 15px;
  display: grid;
  align-content: center;
  justify-content: center;
}

#PopupButtonContainer
{
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.PopupButton
{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopupButton + .PopupButton
{
  margin-left: 20px;
}
</style>