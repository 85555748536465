import { createStore } from 'vuex';

const store = createStore({
    state:{
        step1Data: null,
        step2Data: null,
        step3Data: null,
        step4Data: null,
        Token: '',
    },
    mutations:{
        setStep1Data(state, data){
            state.step1Data = data;
        },
        setStep2Data(state, data){
            state.step2Data = data;
        },
        setStep3Data(state, data){
            state.step3Data = data;
        },
        setStep4Data(state, data){
            state.step4Data = data;
        },
        setToken(state, data){
            state.Token = data;
        },
    },
    actions:{
        saveStep1Data({commit},data){
            commit('setStep1Data', data);
        },
        saveStep2Data({commit}, data){
            commit('setStep2Data', data);
        },
        saveStep3Data({commit}, data){
            commit('setStep3Data', data);
        },
        saveStep4Data({commit}, data){
            commit('setStep4Data', data);
        },
        saveToken({commit}, data){
            commit('setToken', data);
        }
    },
    getters:{
        getStep1Data: state => state.step1Data,
        getStep2Data: state => state.step2Data,
        getStep3Data: state => state.step3Data,
        getStep4Data: state => state.step4Data,
        getToken: state => state.Token,
    }
});

export default store;