export default class Localization {
    constructor()
    {
        this.LocaFiles = {
            en: 'en.json',
            jp: 'jp.json'
        };

        this.LanguageFonts = {
            en: 'Arial',
            jp: 'Arial'
        };

        this.CheckedDefaultLang = false;
        this.CurrentLanguage = 'jp';
        this.CurrentFont = this.LanguageFonts.jp;
        this.Localization = null;
        this.IsLocaInitialized = false;
        this.IsInitializing = false;
        this.Debug = false;
        this.OnLanguageChanged = [];
    }

    Initialize()
    {
        if (this.IsInitializing == true)
        {
            return;
        }

        this.IsInitializing = true;

        if (this.CheckedDefaultLang == false)
        {
            if (localStorage.getItem('language') == false ||
                localStorage.getItem('language') == undefined)
            {
                this.CurrentLanguage = navigator.language || navigator.userLanguage;
                if (Object.hasOwn(this.LocaFiles, this.CurrentLanguage) == false)
                {
                    this.CurrentLanguage = "jp";
                }

                localStorage.setItem('language', this.CurrentLanguage);
            }
            else
            {
                if (this.Debug == true)
                {
                    console.log("Load previously selected language: " + localStorage.getItem('language'));
                }
                this.CurrentLanguage = localStorage.getItem('language');
            }

            var langSelectors = document.getElementsByClassName("LanguageSelector");
            //console.log(langSelectors.length + " Language selectors");
            for (var i = 0; i < langSelectors.length; i++)
            {
                //console.log(langSelectors[i].id);
                if (langSelectors[i].id == this.CurrentLanguage)
                {
                    langSelectors[i].classList.add("ActiveLanguage");
                }
            }

            this.CheckedDefaultLang = true;
            this.IsLocaInitialized = true;
            this.IsInitializing = false;
        }

        this.LoadTranslation((success) =>
        {
            if (this.Debug == true)
            {
                console.log("(Initialize) Loaded translation", success);
            }

            if (success == true)
            {
                this.TranslatePage();
            }
        });
    }

    GetLanguageIndex(languageCode)
    {
        languageCode = languageCode.toLowerCase();

        switch(languageCode)
        {
            default:
            case 'en': return 1;
            case 'jp': return 2;
            case 'ch': return 3;
        }
    }

    GetLanguageCode(languageIndex)
    {
        switch(languageIndex)
        {
            default:
            case 1: return 'en';
            case 2: return 'jp';
            case 3: return 'ch';
        }
    }

    ChangeLanguage(event)
    {
        var langSelectors = document.getElementsByClassName("LanguageSelector");
        for (var i = 0; i < langSelectors.length; i++)
        {
            langSelectors[i].classList.remove("ActiveLanguage");
        }

        let prevLanguage = this.CurrentLanguage;
        this.CurrentLanguage = event.target.id;
        localStorage.setItem('language', this.CurrentLanguage);
        //console.log("Changed language to: " + CurrentLanguage);

        event.target.classList.add("ActiveLanguage");

        if (this.IsLocaInitialized == false &&
            this.IsInitializing == false)
        {
            this.Initialize();
            return;
        }

        this.LoadTranslation(() =>
        {
            this.TranslatePage();

            this.DispatchOnLanguageChanged(prevLanguage, this.CurrentLanguage);
        });
    }

    TranslatePage()
    {
        if (this.IsLocaInitialized == false &&
            this.IsInitializing == false)
        {
            this.Initialize();
            return;
        }

        var elements = document.getElementsByClassName("localize");
        for (var i = 0; i < elements.length; i++)
        {
            var key = elements[i].getAttribute("data-key");
            if (key != null)
            {
                if (Object.hasOwn(this.Localization, key) == true)
                {
                    //console.log("Localize " + key + " -> " + Localization[key], elements[i]);
                    elements[i].innerHTML = this.ConvertLinkTag(this.Localization[key]);
                    //console.log("Change font to " + GetLanguageFont());
                    elements[i].style.fontFamily = this.GetLanguageFont();
                }
                else
                {
                    if (this.Debug == true)
                    {
                        console.log("Missing translation for " + key);
                    }
                }
            }

            var link = elements[i].getAttribute("data-link");
            if (link != null)
            {
                if (Object.hasOwn(this.Localization, link) == true)
                {
                    elements[i].href = this.Localization[link];
                }
                else
                {
                    if (this.Debug == true)
                    {
                        console.log("Missing translation for " + link);
                    }
                }
            }
        }

        //console.log("translated " + elements.length + " elements");
    }

    TranslateElement(target)
    {
        if (target == null ||
            target == undefined)
        {
            return "NO_ELEMENT_PASSED";
        }

        if (this.IsLocaInitialized == false &&
            this.IsInitializing == false)
        {
            this.Initialize();
            return;
        }

        var translatable = target.querySelectorAll("[data-key]");

        for(let i = 0; i < translatable.length; i++)
        {
            let element = translatable[i];
            var key = element.getAttribute("data-key");
            if (key != null && key != undefined)
            {
                if (Object.hasOwn(this.Localization, key) == true)
                {
                    element.innerHTML = this.ConvertLinkTag(this.Localization[key]);
                    element.style.fontFamily = this.GetLanguageFont();
                }
                else
                {
                    if (this.Debug == true)
                    {
                        console.log("Missing translation for " + key);
                    }
                }
            }
    
            var link = element.getAttribute("data-link");
            if (link != null && link != undefined)
            {
                if (Object.hasOwn(this.Localization, link) == true)
                {
                    element.href = this.Localization[link];
                }
                else
                {
                    if (this.Debug == true)
                    {
                        console.log("Missing translation for " + link);
                    }
                }
            }
        }
    }

    TranslateKey(key)
    {
        if (this.IsLocaInitialized == false &&
            this.IsInitializing == false)
        {
            this.Initialize();
            return;
        }

        if (key != null && key != undefined)
        {
            if (Object.hasOwn(this.Localization, key) == true)
            {
                return this.Localization[key];
            }
            else
            {
                if (this.Debug == true)
                {
                    console.log("Missing translation for " + key);
                }
            }
        }
    }

    LoadTranslation(callback)
    {
        try
        {
            this.Localization = require("@/assets/" + this.GetLocalizationFileName());
            callback(true);
        }
        catch(error)
        {
            callback(false);
        }
    }

    ConvertLinkTag(inputString)
    {
        let regex = /\{link-(.*?)\|(.*?)\}/; // Matches anything between {link- and | and anything between | and }
        let match = inputString.match(regex);

        if (match)
        {
            let linkText = match[1];
            let linkURL = match[2];

            let outputString = inputString.replace(regex, `<a class="TextLink" href="${linkURL}" target="_blank">${linkText}</a>`);

            //console.log(outputString);
            return outputString;
        } else
        {
            return inputString;
        }
    }

    GetLanguageFont(language)
    {
        if (language == undefined)
        {
            language = this.CurrentLanguage;
        }

        switch (language)
        {
            case "en": return this.LanguageFonts.en;
            case "jp": return this.LanguageFonts.jp;
            default: return this.LanguageFonts.en;
        }
    }

    GetTranslation(translationKey)
    {
        if (translationKey == undefined ||
            translationKey == null)
        {
            return "NO_KEY_PASSED";
        }
     
        if (this.IsLocaInitialized == false &&
            this.IsInitializing == false)
        {
            this.Initialize();
            return;
        }

        if (Object.hasOwn(this.Localization, translationKey) == true)
        {
            return this.Localization[translationKey];
        }
        else
        {
            if (this.Debug == true)
            {
                console.log("Missing translation for " + translationKey);
            }
            return translationKey;
        }
    }

    GetLocalizationFileName(language)
    {
        if (language == undefined)
        {
            language = this.CurrentLanguage;
        }

        //console.log("[Localization](GetLocalizationFileName) language: " + language);
        switch (language)
        {
            case "en": return this.LocaFiles.en;
            case "jp": return this.LocaFiles.jp;
            default: return this.LocaFiles.en;
        }
    }

    RegisterOnLanguageChangedListener(listener)
    {
        this.OnLanguageChanged.push(listener);
        console.log('registered on-language-changed-listener');
    }

    UnregisterOnLanguageChangedListener(listener)
    {
        this.OnLanguageChanged = this.OnLanguageChanged.filter(l => l !== listener);
    }

    DispatchOnLanguageChanged(oldLanguage, newLanguage)
    {
        console.log('Fire on-language-changed-event for ' + this.OnLanguageChanged.length + ' listener');
        for(let i = 0; i < this.OnLanguageChanged.length; i++)
        {
            this.OnLanguageChanged[i](oldLanguage, newLanguage);
        }
    }

    GetCurrentLanguage()
    {
        return this.CurrentLanguage;
    }
}

// Create a global instance
export const LocalizationManager = new Localization();